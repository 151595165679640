import React, { useState, useEffect } from "react";
// import * as actions from '../../actions/';
import axios from 'axios';
import { BASE_URL } from '../../../../config/config';
import { connect } from 'react-redux';
import { Link, withRouter } from 'react-router-dom';
import { bindActionCreators } from 'redux';
import { List, Search } from 'semantic-ui-react';
import SvgIcon from "../../svg-icon/svg-icon";
import { replaceSpaceInName } from '../../../../common/helperMethods';
import { userActions, productActions, getUserInfo, authActions } from '../../../../actions/';

import './MyProductsList.scss';

const MyProductsList = (props) => {
    const { auth, getFollowedProducts, followedProducts } = props;
    const [searchString, setsearchString] = useState("")
    const [productList, setproductList] = useState([])
    useEffect(() => {
        if (auth) {
            if (!followedProducts) {
                getFollowedProducts('', 'mobileprodlist')
            }
        }
    }, []);



    const renderFollowedProducts = () => {
        let prlist = [];
        if (searchString != "") {
            prlist = productList
        } else {
            prlist = (followedProducts.Items && followedProducts.Items.length > 0) ? followedProducts.Items : [];
        }

        if (prlist && prlist.length > 0) {
            return prlist.map((item, index) => (
                <li>
                    <Link to={`/product/${replaceSpaceInName(item.product_name)}/${item.product_id}`}>
                        <span>{item.product_name}</span>
                    </Link>
                </li>
            ));
        } else {
            if (searchString!= ''){
                
                return (
                    <div>
                    <h4>No Technologies Found </h4>
                </div>
            );
        } else {
                return (
                    <div>
                        <h4>You are not following any products</h4>
                    </div>
                );
        }
        }
    }

    const filterProduct = (event) => {
        const value = event.target.value;
         const { followedProducts } = props;
        if (value != '') {
            let prlist = followedProducts.Items.filter(obj => {
                return (obj.product_name.toLowerCase().indexOf(value) !== -1)
            })

            setsearchString(value);
            setproductList(prlist);
        } else {
            setsearchString("");
            setproductList([]);
        }
    }

    return (
        <span>
            <div className={props.visible ? "productlist-mobile opened" : "productlist-mobile"}>
                <div className="productlist-mobile-wrapper">
                    <div className="bar-header" onClick={() => props.toogleSidebar()}>
                        <SvgIcon name='chevron-left' viewbox="0 0 8 14" />
                        Technologies I Follow
                    </div>
                    <div className="bar-body">
                        <div className="list-header">
                           {/*  <Search
                                placeholder="Search for products. Eg: “UV Curing”"
                                onSearchChange={filterProduct}
                                value={searchString}
                                showNoResults={false}
                            /> */}
                            <p>You’re following <span>
                                {followedProducts && followedProducts.Items && followedProducts.Items.length > 0 && followedProducts.Items.length}
                            </span> products</p>
                        </div>
                        <ul className="product-list">
                            {followedProducts && renderFollowedProducts()}
                        </ul>


                    </div>
                </div>
            </div>
        </span>
    )
}

function mapStateToProps({ auth, user }) {
    return {
        auth,
        userId: user.id,
        followedProducts: user.followedProducts,
    };
}

function mapDispatchToProps(dispatch) {
    return { ...bindActionCreators({ ...productActions, ...userActions, ...authActions, getUserInfo }, dispatch) }
}

export default connect(
    mapStateToProps,
    mapDispatchToProps,
)(withRouter(MyProductsList));
