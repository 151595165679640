import axios from 'axios';
import { apiStatus } from '../../common/constants';
import { BASE_URL } from '../../config/config';

import {
    POST_STATUS, FEED_UPDATE_STATUS, NETWORK_USER_FEED, NETWORK_PRODUCT_FEED, GLOBAL_USER_FEED, NETWORK_COMPANY_FEED, UPLOAD_PROGRESS, NETWORK_USER_LIKED_POSTS, SET_POST_COMMENTS, POST_LIKES_COUNT,
    ADD_POST_COMMENT_STATUS, POST_DETAILS, NETWORK_PRODUCT_FEED_SUMMARY, NETWORK_COMPANY_FEED_SUMMARY, POST_SHARED, SET_NETWORKFEED_PAGINATION, POST_TAGGED, NETWORK_FEED_LOADING_STATUS, NEWS, POST_TAGGED_FAILED
} from '../../config/types';
import { getUserInfo } from '../index';
import tus from 'tus-js-client';

export const getUserNetworkFeed = (loggedInUserId, userId) => async (dispatch, getState) => {
    try {
        const state = getState();
        const { size, start } = state.networkFeed.feedPagination;
        let res = await axios.get(`${BASE_URL}/api/v2/feed/user/${userId}?size=${size}&start=${start}`, { withCredentials: true });
        dispatch(updateUploadProgress(70));
        let userFeed = res.data.items;
        dispatch(updateTotalCountInPagination(res.data.total));
        //  dispatch(getUserInfoFromPosts(userFeed));
        userFeed = filterPostsByPrivacy(loggedInUserId, userFeed);
        dispatch({ type: NETWORK_USER_FEED, payload: userFeed })
        setTimeout(() => {
            dispatch(updateUploadProgress(100));
            dispatch({ type: FEED_UPDATE_STATUS, payload: 'fetched' });
        }, 400);
    }
    catch (err) {
    }
};

export const getProductNetworkFeed = (loggedInUserId, productId) => async (dispatch, getState) => {
    try {
        const state = getState();
        const { size, start } = state.networkFeed.feedPagination;
        let res = await axios.get(`${BASE_URL}/api/v2/feed/product/${productId}?size=${size}&start=${start}`, { withCredentials: true })
        dispatch(updateUploadProgress(70));
        let productFeed = updateFeedCollection("productFeed", state, res.data.items);
        // dispatch(updateFeedCollection("productFeed", state, productFeed))
        dispatch(updateTotalCountInPagination(res.data.total));
        /*  dispatch(getUserInfoFromPosts(productFeed)); */
        productFeed = filterPostsByPrivacy(loggedInUserId, productFeed);
        dispatch({ type: NETWORK_PRODUCT_FEED, payload: productFeed })
        setTimeout(() => {
            dispatch(updateUploadProgress(100));
            dispatch({ type: FEED_UPDATE_STATUS, payload: 'fetched' });
        }, 400);
    }
    catch (err) {
    }
};

export const getProductFeedUnauthenticated = (productId) => async (dispatch, getState) => {
    try {
        const state = getState();
        const { size, start } = state.networkFeed.feedPagination;
        await axios.get(`${BASE_URL}/api/v2/feed/product/${productId}?size=${size}&start=${start}`).then(res => {
            dispatch(updateUploadProgress(70));
            let productFeed = updateFeedCollection("productFeed", state, res.data.items);
            dispatch(updateTotalCountInPagination(productFeed.total));
            /*  dispatch(getUserInfoFromPosts(productFeed)); */
            dispatch(updateUploadProgress(100));
            setTimeout(() => {
                dispatch({ type: NETWORK_PRODUCT_FEED_SUMMARY, payload: productFeed })
                dispatch({ type: FEED_UPDATE_STATUS, payload: 'fetched' });
            }, 400);
        }).catch(error => console.log(error));
    } catch (err) {
        console.log('err ', err)
    }
};

export const getCompanyFeedUnauthenticated = (companyId) => async (dispatch) => {
    try {
        const res = await axios.get(`${BASE_URL}/api/v2/feed/company/${companyId}/latest`)
        dispatch(updateUploadProgress(70));
        const companyFeed = res.data.filter(item => item !== null);
        dispatch(updateUploadProgress(100));
        setTimeout(() => {
            dispatch({ type: NETWORK_COMPANY_FEED_SUMMARY, payload: companyFeed })
            dispatch({ type: FEED_UPDATE_STATUS, payload: 'fetched' });
        }, 400);
    } catch (err) {
        console.log('err ', err)
    }
};

export const getCompanyFeed = (loggedInUserId, companyId) => async (dispatch, getState) => {
    try {
        const { size, start } = getState().networkFeed.feedPagination;
        let res = await axios.get(`${BASE_URL}/api/v2/feed/company/${companyId}?size=${size}&start=${start}`, { withCredentials: true });
        dispatch(updateUploadProgress(70));
        let companyFeed = res.data.items;
        dispatch(updateTotalCountInPagination(res.data.total));
        dispatch(getUserInfoFromPosts(companyFeed));
        companyFeed = filterPostsByPrivacy(loggedInUserId, companyFeed);
        dispatch(updateUploadProgress(100));
        setTimeout(() => {
            dispatch({ type: NETWORK_COMPANY_FEED, payload: companyFeed });
            dispatch({ type: FEED_UPDATE_STATUS, payload: 'fetched' });
        }, 400);
    } catch (err) {
        console.log('err ', err)
    }
};

export const getUserGlobalFeed = () => async (dispatch, getState) => {
    try {
        dispatch({ type: FEED_UPDATE_STATUS, payload: 'fetching' });
        dispatch(updateUploadProgress(10));
        setTimeout(() => {
            dispatch(updateUploadProgress(30));
        }, 200);

        dispatch({ type: NETWORK_FEED_LOADING_STATUS, payload: apiStatus.IN_PROGRESS });
        const { size, start } = getState().networkFeed.feedPagination;
        let res = await axios.get(`${BASE_URL}/api/v2/feed/global?size=${size}&start=${start}`, { withCredentials: true });
        let globalFeed = res.data.items;
        dispatch(updateUploadProgress(70));
        dispatch(updateTotalCountInPagination(res.data.total));
        // dispatch(getUserInfoFromPosts(globalFeed));
        dispatch({ type: GLOBAL_USER_FEED, payload: globalFeed });
        dispatch({ type: NETWORK_FEED_LOADING_STATUS, payload: apiStatus.COMPLETED });
        dispatch(updateUploadProgress(100));
        setTimeout(() => {
            dispatch({ type: FEED_UPDATE_STATUS, payload: 'fetched' });
        }, 400);
    } catch (err) {
        console.log('err ', err)
    }
};

const headerPost = {
    Accept: 'application/vnd.vimeo.*+json;version=3.4',
    Authorization: `bearer b2d410b356ab9ff0902fd02c030a4335`,
    'Content-Type': 'application/json'
};

export const createNewPost = (formData, feedType, entityId, auth) => async (dispatch) => {
    if (formData.video != undefined && formData.video != '') {
        const vimeo_video_upload_url = `https://api.vimeo.com/me/videos`;
        const file = formData.video;
        const fileName = file.name;
        const fileSize = file.size.toString();
        const timestamp = new Date().getTime()
        let description = '';
        if (formData.text != undefined && formData.text != '') {
            description = formData.text.replace(/<\/?[^>]+(>|$)/g, "").trim();
            description = description.replaceAll('&nbsp;', '');
            if (description.length > 165) {
                description = description.substr(0, 165)
            }
        }

        dispatch(setPostStatus('uploading'));
        const response = await axios({
            method: 'post',
            url: vimeo_video_upload_url,
            headers: headerPost,
            data: {
                upload: {
                    approach: 'tus',
                    size: fileSize
                },
                "name": auth.firstName + "_" + timestamp,
                "description": description,
            }
        });
        console.log("response ", response);

        // Create a new tus upload
        const upload = new tus.Upload(file, {
            endPoint: vimeo_video_upload_url,
            uploadUrl: response.data.upload.upload_link,
            retryDelays: [0, 3000, 5000, 10000, 20000],
            metadata: {
                filename: file.name,
                filetype: file.type
            },
            headers: {},
            onError: (error) => {
                console.log('Failed because: ' + error);
                dispatch(setPostStatus('uploaded'));

            },
            onProgress: (bytesUploaded, bytesTotal) => {
                dispatch(setPostStatus('uploading'));
                let percentage = Math.round(((bytesUploaded / bytesTotal) * 100).toFixed(2));
                console.log(bytesUploaded, bytesTotal, percentage + '%');
                dispatch(updateUploadProgress(percentage));
            },
            onSuccess: () => {
                if (formData.text == undefined) {
                    formData.text = '';
                }
                formData.text = formData.text + ' ' + response.data.link;
                dispatch(setPostStatus('uploaded'));
                dispatch(createPostCallback(formData, feedType, entityId));
            }
        });
        // Start the upload
        upload.start()
    } else {
        dispatch(createPostCallback(formData, feedType, entityId));
    }


};

const createPostCallback = (formData, feedType, entityId) => async (dispatch) => {
    const bodyFormData = new FormData();
    let value;

    Object.keys(formData).forEach(field => {

        if (Array.isArray(formData[field])) {
            value = JSON.stringify(formData[field]);
        }
        else {
            value = formData[field];
        }
        bodyFormData.append(field, value);
    });

    dispatch(setPostStatus('creating'));
    dispatch(updateUploadProgress(30));

    let res = await axios.post(`${BASE_URL}/api/v1/feed/post`, bodyFormData,
        {
            headers: {
                'content-type': 'multipart/form-data'
            },
            withCredentials: true,
            onUploadProgress: function (progressEvent) {
                var percent = (progressEvent.loaded / progressEvent.total) * 100;
                console.log('percent ', percent)
                // dispatch(updateUploadProgress(percent));
                dispatch(updateUploadProgress(50));
            },
        }).then(res => {
            console.log('res ', res )
            dispatch(setPostStatus('created'));
            dispatch(updateUploadProgress(100));
            dispatch({ type: FEED_UPDATE_STATUS, payload: 'fetching' });
            dispatch(resetFeedPagination());
            setTimeout(() => {

                dispatch(refreshPosts(feedType, formData.userId, entityId))
                dispatch(setPostStatus(undefined));

            }, 2000);
        })
};

export const updateFeedPostDetail = (formData, feedType, articleId, auth, entityId) => async (dispatch) => {
    if (formData.video != undefined && formData.video != '') {
        const vimeo_video_upload_url = `https://api.vimeo.com/me/videos`;
        const file = formData.video;
        const fileName = file.name;
        const fileSize = file.size.toString();
        const timestamp = new Date().getTime()
        let description = '';
        if (formData.text != undefined && formData.text != '') {
            description = formData.text.replace(/<\/?[^>]+(>|$)/g, "").trim();
            description = description.replaceAll('&nbsp;', '');
            if (description.length > 165) {
                description = description.substr(0, 165)
            }
        }

        dispatch(setPostStatus('uploading'));
        const response = await axios({
            method: 'post',
            url: vimeo_video_upload_url,
            headers: headerPost,
            data: {
                upload: {
                    approach: 'tus',
                    size: fileSize
                },
                "name": auth.firstName + "_" + timestamp,
                "description": description,
            }
        });
        console.log("response ", response);

        // Create a new tus upload
        const upload = new tus.Upload(file, {
            endPoint: vimeo_video_upload_url,
            uploadUrl: response.data.upload.upload_link,
            retryDelays: [0, 3000, 5000, 10000, 20000],
            metadata: {
                filename: file.name,
                filetype: file.type
            },
            headers: {},
            onError: (error) => {
                dispatch(setPostStatus('uploaded'));
                console.log('Failed because: ' + error);
            },
            onProgress: (bytesUploaded, bytesTotal) => {
                dispatch(setPostStatus('uploading'));
                let percentage = Math.round(((bytesUploaded / bytesTotal) * 100).toFixed(2));
                console.log(bytesUploaded, bytesTotal, percentage + '%');
                dispatch(updateUploadProgress(percentage));
            },
            onSuccess: () => {
                dispatch(setPostStatus('uploaded'));
                if (formData.text == undefined) {
                    formData.text = '';
                }
                formData.text = formData.text + ' ' + response.data.link;

                dispatch(updateFeedPostDetailCallback(formData, feedType, articleId, entityId));
            }
        });
        // Start the upload
        upload.start()
    } else {
        dispatch(updateFeedPostDetailCallback(formData, feedType, articleId, entityId));
    }
};

const updateFeedPostDetailCallback = (formData, feedType, articleId, entityId) => async (dispatch) => {
    const bodyFormData = new FormData();
    let value;
    Object.keys(formData).forEach(field => {

        if (Array.isArray(formData[field])) {
            value = JSON.stringify(formData[field]);
        }
        else {
            value = formData[field];
        }
        bodyFormData.append(field, value);
    });

    dispatch(setPostStatus('creating'));
    dispatch(updateUploadProgress(30));
    let res = await axios.put(`${BASE_URL}/api/v1/feed/post/` + articleId + '/content', bodyFormData,
        {
            headers: {
                'content-type': 'multipart/form-data'
            },
            withCredentials: true,
            onUploadProgress: function (progressEvent) {
                var percent = (progressEvent.loaded / progressEvent.total) * 100;
                //dispatch(updateUploadProgress(percent));
                dispatch(updateUploadProgress(50));
            },
        }).then(res => {
            dispatch(setPostStatus('created'));
            dispatch(updateUploadProgress(100));
            dispatch({ type: FEED_UPDATE_STATUS, payload: 'fetching' });
            dispatch(resetFeedPagination());
            setTimeout(() => {
                dispatch(refreshPosts(feedType, formData.userId, entityId))

                dispatch(setPostStatus(undefined));

            }, 2000);
        })
}

export const removePost = (postId, feedType, userId, productId) => async (dispatch) => {
    try {
        dispatch({ type: FEED_UPDATE_STATUS, payload: 'fetching' });
        await axios.delete(`${BASE_URL}/api/v1/feed/post/${postId}`, { withCredentials: true });
        dispatch(resetFeedPagination());
        setTimeout(() => {
            dispatch(refreshPosts(feedType, userId, productId));
        }, 2000);
    } catch (err) {
        console.log('err ', err)
    }
}

export const getLikedPosts = (userId) => async (dispatch) => {
    try {
        let res = await axios.get(`${BASE_URL}/api/v1/feed/user/${userId}/likes`, { withCredentials: true })
        dispatch({ type: NETWORK_USER_LIKED_POSTS, payload: res.data })
    } catch (err) {
        console.log('err ', err)
    }
};

export const getPostLikes = (postId) => async (dispatch) => {
    try {
        let res = await axios.get(`${BASE_URL}/api/v1/feed/post/${postId}/like`, { withCredentials: true })
        dispatch({ type: POST_LIKES_COUNT, payload: { postId, count: res.data.length } })
    } catch (err) {
        console.log('err ', err)
    }
};

export const likePost = (postId, userId) => async (dispatch) => {
    try {
        let res = await axios.put(`${BASE_URL}/api/v1/feed/post/${postId}/like`, {}, { withCredentials: true })
        dispatch(getLikedPosts(userId));
        dispatch(getPostLikes(postId));
    } catch (err) {
        console.log('err ', err)
    }
};

export const unlikePost = (postId, userId) => async (dispatch) => {
    try {
        let res = await axios.put(`${BASE_URL}/api/v1/feed/post/${postId}/unlike`, {}, { withCredentials: true })
        dispatch(getLikedPosts(userId));
        dispatch(getPostLikes(postId));
    } catch (err) {
        console.log('err ', err)
    }
};

export const getPostComments = (postId) => async (dispatch, getState) => {
    try {
        let res = await axios.get(`${BASE_URL}/api/v1/feed/post/${postId}/comment`, { withCredentials: true })
        if (res.data.length > 0) {
            const postId = res.data[0].parent_id;
            const payload = { postId, comments: [...res.data] }

            const { userInfoCollection } = getState().user;
            let uniqueUserIds = [...new Set(res.data.map(item => item.user_id))];
            uniqueUserIds = uniqueUserIds.filter(userId => !userInfoCollection.find(item => item.id === userId));

            let promises = []
            let userInfo;
            uniqueUserIds.map(userId => {
                userInfo = dispatch(getUserInfo(userId));
                promises.push(userInfo);
            })
            Promise.all(promises).then(() => {
                dispatch({ type: SET_POST_COMMENTS, payload })
            })
        }
    } catch (err) {
        console.log('err ', err)
    }
};

export const addPostComment = (postId, text) => async (dispatch) => {
    try {
        dispatch(setAddPostCommentStatus("creating"));
        let res = await axios.post(`${BASE_URL}/api/v1/feed/post/${postId}/comment`, { text }, { withCredentials: true })
        dispatch(setAddPostCommentStatus("created"));
        dispatch(getPostComments(postId));
    } catch (err) {
        console.log('err ', err)
    }
};

export const deletePostComment = (postId, commentId) => async (dispatch) => {
    try {
        let res = await axios.delete(`${BASE_URL}/api/v1/feed/post/${postId}/comment/${commentId}`, { withCredentials: true })
        dispatch({ type: NETWORK_USER_FEED, payload: res.data });
    } catch (err) {
        console.log('err ', err)
    }
};

const removeDuplicatePosts = (feed) => {
    let filteredFeed = [];
    feed.forEach(item => { if (!filteredFeed.find(x => x.id === item.id)) { filteredFeed.push(item) } })
    return filteredFeed;
}

const filterPostsByPrivacy = (loggedInUserId, feed) => {
    return feed.filter(post => post.visibility === "public" || post.user_id === loggedInUserId)
}

const getUserInfoFromPosts = (data) => async (dispatch, getState) => {
    return new Promise((resolve, reject) => {

        const { userInfoCollection } = getState().user;
        let uniqueUserIds = [...new Set(data.map(item => item.user_id))];
        let promises = []
        let userInfo;
        uniqueUserIds.forEach(userId => {
            // if (!userInfoCollection.find(item => item.id === userId)) {
            userInfo = axios.get(`${BASE_URL}/api/v2/users/${userId}`, { withCredentials: true })
            promises.push(userInfo);
            // }
        })
        Promise.allSettled(promises).then((response) => {
            let userInfoCollection = [];
            response.forEach(item => {
                if (item.status === "fulfilled" && item.value.data) {
                    userInfoCollection.push(item.value.data)
                }
            });
            // dispatch({ type: UPDATE_USERINFO_COLLECTION, payload: userInfoCollection })
            dispatch({ type: "NETWORKFEED_USERS", payload: userInfoCollection })
            resolve();
        })
    })
}

export const getPostDetails = (postId) => async (dispatch) => {
    try {
        let res = await axios.get(`${BASE_URL}/api/v1/feed/post/${postId}`);
        //  await dispatch(getUserInfoFromPosts([res.data]));
        dispatch({ type: POST_DETAILS, payload: res.data });
    } catch (err) {
        console.log('err ', err)
    }
}

export const sharePost = (emailFormData) => async (dispatch) => {
    try {
        dispatch(setSharePostStatus("sharing"));
        let res = await axios.post(`${BASE_URL}/api/v1/emails`, emailFormData, { withCredentials: true })
        dispatch(setSharePostStatus("shared"));
    } catch (err) {
        console.log('err ', err)
    }
};

export const tagProductsAndCompaniesInPost = (postId, data, feedType, userId, entityId) => async (dispatch) => {
    try {
        await axios.post(`${BASE_URL}/api/v1/feed/post/${postId}/tags`, { ...data }, { withCredentials: true });
        dispatch(setPostTaggedStatus(true));
        dispatch(resetFeedPagination());
        setTimeout(() => {
            dispatch(refreshPosts(feedType, userId, entityId));
            dispatch(setPostTaggedStatus(false));
        }, 2000);
    } catch (err) {
        dispatch({ type: POST_TAGGED_FAILED, payload: err.response.data.error });
        dispatch(setPostTaggedStatus(false));
        setTimeout(() => {
            dispatch({ type: POST_TAGGED_FAILED, payload: undefined });

        }, 3500);
    }
}

export const setPostStatus = (status) => {
    return {
        type: POST_STATUS,
        payload: status
    }
}

export const setFeedUpdateStatus = (status) => {
    return {
        type: FEED_UPDATE_STATUS,
        payload: status
    }
}

const setAddPostCommentStatus = (status) => {
    return {
        type: ADD_POST_COMMENT_STATUS,
        payload: status
    }
}

const refreshPosts = (feedType, userId, entityId) => async (dispatch) => {
    switch (feedType) {
        case "user":
            dispatch(getUserNetworkFeed(userId, userId));
            break;
        case "product":
            dispatch(getProductNetworkFeed(userId, entityId));
            break;
        case "company":
            dispatch(getCompanyFeed(userId, entityId));
            break;
        case "global":
            dispatch(getUserGlobalFeed());
            break;
        default:
            break;
    }
}

const updateUploadProgress = (percent) => {
    return {
        type: UPLOAD_PROGRESS,
        payload: percent
    }
}

export const setSharePostStatus = (status) => {
    return {
        type: POST_SHARED,
        payload: status
    }
}

const updateTotalCountInPagination = (totalCount) => async (dispatch, getState) => {
    const state = getState();
    const { feedPagination } = state.networkFeed;

    const feedPaginationUpdated = {
        size: feedPagination.size,
        start: feedPagination.start,
        total: totalCount
    }
    dispatch(setNetworkFeedPagination(feedPaginationUpdated));
}

export const setNetworkFeedPagination = (feedPagination) => async (dispatch) => {
    dispatch({ type: SET_NETWORKFEED_PAGINATION, payload: feedPagination });
}

const updateFeedCollection = (feedType, state, payload) => {
    let feed = state[feedType] ? [...state[feedType]] : [];
    if (feed.length > 0) {
        const postIds = feed.map(item => item.id);
        payload.forEach(item => {
            if (postIds.indexOf(item.id) === -1) {
                feed.push(item)
            }
        })
        return feed;
    }
    else {
        return payload;
    }
}

const resetFeedPagination = () => async (dispatch) => {
    const feedPagination = {
        start: 0,
        size: 10
    }

    dispatch({ type: SET_NETWORKFEED_PAGINATION, payload: feedPagination });
}

export const setPostTaggedStatus = (status) => {
    return {
        type: POST_TAGGED,
        payload: status
    }
}


export const GetNews = (page) => async (dispatch) => {
    const news = await axios.get(`${BASE_URL}/api/v2/feed/news/global?start=${page}&size=10`, { withCredentials: true });
    console.log(news);
    dispatch({ type: NEWS, payload: news.data.data.items });
}