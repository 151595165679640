import React, { Component } from 'react';
import moment from 'moment';
import Avatar from '../common/Avatar';
import { Link } from 'react-router-dom';
import { Loader } from 'semantic-ui-react';
import * as actions from '../../actions';
import './messagenew.scss';
import { connect } from 'react-redux';
import axios from 'axios';
import { BASE_URL } from '../../config/config';

class Conversation extends Component {
    constructor(props) {
        super(props);
        this.state = {
            messages: []
        }
    }

    messagesEndRef = React.createRef();

    componentDidMount() {
        this.props.getMessages(this.props.currentChat);
    }

    componentDidUpdate(previousProps) {
        if (this.props.currentChat !== previousProps.currentChat) {
            this.props.getMessages(this.props.currentChat);
        }
        if (this.props.messages.length !== previousProps.messages.length) {
            this.scrollToBottom();
        }
    }

    getMessages = async userId => {
        this.setState({ showLoader: true, messages: [] });
        let res = await axios.get(`${BASE_URL}/api/v1/chats/${userId}/`, { withCredentials: true });
        if (res.data && res.data.Items && res.data.Items.length > 0) {
            this.setState({ messages: res.data.Items, LastEvaluatedKey: res.data.LastEvaluatedKey });
            if (!this.props.currentChatRoom.lastRead || res.data.Items[0].added > this.props.currentChatRoom.lastRead) {
                axios.put(`${BASE_URL}/api/v1/chats/${this.state.currentChat}/`, { lastRead: res.data.Items[0].added }, { withCredentials: true });
            }
        } else {
            this.setState({ messages: [], LastEvaluatedKey: null });
        }
        this.setState({ showLoader: false });
        this.scrollToBottom();
    };

    scrollToBottom = () => {
        this.messagesEndRef.current.scrollIntoView({ behavior: 'smooth' });
    };

    validURL = (str) => {
        var pattern = new RegExp('^(https?:\\/\\/)?' + '((([a-z\\d]([a-z\\d-]*[a-z\\d])*)\\.)+[a-z]{2,}|' + '((\\d{1,3}\\.){3}\\d{1,3}))' + '(\\:\\d+)?(\\/[-a-z\\d%_.~+]*)*' + '(\\?[;&a-z\\d%_.~+=-]*)?' + '(\\#[-a-z\\d_]*)?$', 'i');
        return !!pattern.test(str);
    }

    rendermes = () => {
        return this.props.messages.map((msg, i, arr) => {
            let m = arr[arr.length - 1 - i];

            let previous_date = (arr[arr.length - 1 - (i - 1)] != undefined) ? moment(arr[arr.length - 1 - (i - 1)]['added']).format('MMM DD') : "";
            let current_date = moment(m.added).format('MMM DD');

            if (m.sender !== this.props.auth.id) {
                return (
                    <span>
                        {previous_date != current_date ? <time className="time-heading in_message"> {current_date} </time> : ""}
                        <li key={i} className="thread-in">

                            <Link className="mt-12px" to={`/networkFeed/user/${m.sender}`}>
                                <div className="thread-pic">
                                    <Avatar id={m.sender} />
                                </div>
                            </Link>
                            <div className="thread-body">
                                <div className="thread-text">
                                    <div className="thread-name">
                                        <Link to={`/networkFeed/user/${m.sender}`}>{this.props.currentChatRoom.name}</Link>
                                        <time className="message_timestamp">{moment(m.added).format('h:mm a')}</time>
                                    </div>
                                    {this.validURL(m.message) ? <p><a href={m.message} target="_blank">{m.message}</a></p> : <p> {m.message} </p>}
                                </div>
                            </div>
                        </li>
                    </span>
                );
            } else {
                return (
                    <span>
                        {previous_date != current_date ? <time className="time-heading out_message"> {current_date} </time> : ""}
                        <li key={i} className="thread-out">
                            <Link className="mt-12px" to={`/networkFeed/user/${this.props.auth.id}`}>
                                <div className="thread-pic">
                                    <Avatar id={this.props.auth.id} />
                                </div>
                            </Link>
                            <div className="thread-body">
                                <div className="thread-text">
                                    <div className="thread-name">
                                        <Link to={`/networkFeed/user/${this.props.auth.id}`}>
                                            {this.props.auth.displayName}
                                        </Link>
                                        <time className="message_timestamp">{moment(m.added).format('h:mm a')}</time>
                                    </div>
                                    {this.validURL(m.message) ? <p><a href={m.message} target="_blank">{m.message}</a></p> : <p> {m.message} </p>}
                                </div>
                            </div>
                        </li>
                    </span>
                );
            }
        });
    };

    render() {
        return (
            <ul ref="messageList">
                {
                    this.state.showLoader && <Loader active inline />
                }
                {this.rendermes()}
                <div ref={this.messagesEndRef} />
            </ul>
        )
    }
}

function mapStateToProps({ auth, user, chatUser }) {
    return { auth, user, chatUser };
}

export default connect(mapStateToProps, actions)(Conversation);