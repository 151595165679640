import React, { useState, useEffect } from "react";
import { Menu, Button, Image, Label } from 'semantic-ui-react';
import SvgIcon from "../../svg-icon/svg-icon";
import { Link, withRouter } from "react-router-dom";
import { bindActionCreators } from 'redux';
import './mobilesidebar.scss';
import { replaceSpaceInName } from '../../../../common/helperMethods';
import { userActions, productActions, getUserInfo, authActions } from '../../../../actions'
import { BASE_URL } from '../../../../config/config';
import AddNewProduct from "../../AddProduct/AddNewProduct";
import AddNewCompany from "../../../productpage_wip/suppliers/AddNewCompany";
import SweetAlerts from '../../sweetalerts/sweetalert';
import { CircularProgressbar, buildStyles } from "react-circular-progressbar";
import { connect } from 'react-redux';

import defaultAvatar from '../../../../assets/images/user-avatar.png';
import mappes from '../../../../assets/images/logo.png'

const MobileSideBar = (props) => {

    const { userProfileInfo, auth, getVendorsCount, getConnectionsCount, getCustomersCount, vendorsCount = 0, connectionsCount, customersCount = 0, getFollowedProducts, followedProducts, getUserProfileDetails, fetchUser } = props
    const [visible, setVisible] = useState(false);
    const [openAddProduct, setOpenAddProduct] = useState(false);
    const [openAddCompany, setOpenAddCompany] = useState(false);
    const [company_id, setCompanyId] = useState('');
    const [company_name, setCompanyName] = useState('');
    const [company_submit, setCompanySubmit] = useState(false);
    const [product_submit, setProductSubmit] = useState(false);

    useEffect(() => {
        getUserProfileDetails();
        fetchUser();

        getVendorsCount();
        getConnectionsCount();
        getCustomersCount();
        getFollowedProducts()
    }, [])


    const toogleSidebar = () => {
        setVisible(!visible);
    }

    const renderProfileImage = () => {
        if (auth) {
            if (userProfileInfo) {
                return (
                    <Image className="avatar-36" avatar src={userProfileInfo.image} />
                )
            } else if (auth.image) {
                return (
                    <Image className="avatar-36" avatar src={auth.image} />
                )
            } else {
                if (auth != undefined) {
                    return (
                        <Label circular color="blue" className="notification-initials">
                            {auth.displayName.substring(0, 1)}
                        </Label>
                    )
                } else {
                    return (
                        <Image className="avatar-36" avatar src={defaultAvatar} />
                    )
                }
            }
        }
        else {
            return (
                <Image className="avatar-36" avatar src={defaultAvatar} />
            )
        }
    }

    const renderName = () => {
        if (props.auth) {
            return (
                <span className="hide-menu l-h-20">
                    <Link id="user-name" name="user-name" to={`/networkFeed/user/${props.auth.id}`} >
                        {props.auth.displayName.split(' ')[0]} {props.auth.displayName.split(' ')[1]}
                    </Link>
                    <span className="fa arrow"></span>
                </span>
            );
        } else {
            return (
                <span className="hide-menu">
                    Loading...<span className="fa arrow"></span>
                </span>
            );
        }
    }

    const render_company = () => {
        if (props.auth) {
            return (<Link to={`/company/${replaceSpaceInName(props.auth.company)}/${props.auth.company_id}`}>{props.auth.company}</Link>)
        } else {
            return (<Link to="/profile">Add Company</Link>)
        }
    }

    const gotoLogin = () => {
        const { history } = props;
        history.push('/otp-login');
    }

    const handleProductClose = () => {
        setOpenAddProduct(false);
    }

    const handleProductOpen = () => {
        setOpenAddProduct(true);
    }

    const handleProductSucess = () => {
        setProductSubmit(true)
    }

    const handleProductCloseAlert = () => {
        // setOpenAddProduct(false);
        setProductSubmit(false)
    }

    const handleCompanyClose = () => {
        setOpenAddCompany(false);
    }

    const handleCompanyOpen = () => {
        setOpenAddCompany(true);
    }

    const handleCompanySucess = () => {
        // setOpenAddCompany(true);
        setCompanySubmit(true)
    }

    const handleCompanyCloseAlert = () => {
        const { history } = props;
        setCompanySubmit(false)
        setTimeout(() => {
            history.push(`/company/${replaceSpaceInName(company_name)}/${company_id}`);
            setCompanyId('');
            setCompanyName('')
        }, 500);
    }

    const get_company_id = (company_id, company_name) => {
        setCompanyId(company_id);
        setCompanyName(company_name)
    }

    const triggerproductList = () => {
        document.getElementById("myproductlistmobile").click();
        toogleSidebar();
    }
    
    const goto_connection  = () =>{
        const { history } = props;
        history.push('/connections');
    }

    const getProfileCompletionPercentage = () => {
        const { userProfileDetails, auth } = props;

        if (auth && userProfileDetails) {
            const totalItems = 8;
            let completedItems = 0;
            if (userProfileDetails.image) completedItems++;
            if (userProfileDetails.firstName) completedItems++;
            if (userProfileDetails.lastName) completedItems++;
            if (userProfileDetails.company) completedItems++;
            if (userProfileDetails.country) completedItems++;
            if (userProfileDetails.designation) completedItems++;
            if (userProfileDetails.primaryEmail) completedItems++;
            if (userProfileDetails.phone) completedItems++;
            return (completedItems / totalItems) * 100;
        } else {
            return 0;
        }
    }


    const renderProfileProgress = () => {
        const percentage = Math.round(getProfileCompletionPercentage());
        if (percentage < 100) {

            return (
                <div className='profile-progress'>
                    <div className='progreesbar'>
                        <CircularProgressbar
                            styles={buildStyles({
                                rotation: 0.25,
                                width: '44px',
                                textSize: '20px',
                                fontWeight: '600',
                                pathTransitionDuration: 0.5,
                                pathColor: `#2166EE`,
                                textColor: '#243B53',
                                trailColor: '#DBE2EB',
                            })}
                            value={percentage} text={`${percentage}%`}
                        />
                    </div>
                    <div className='right-content'>
                        <h3 className='completeprofile-title'>Complete Your Profile</h3>
                        Build quality network to explore opportunities by providing <Link to='/profile'>additional details</Link>.
                    </div>
                </div>
            )
        }
    }

    return (
        <span>
            {/* <img onClick={toogleSidebar} src={defaultAvatar} alt="" /> */}
            <div className="trigger_right_side_menu" onClick={toogleSidebar}>
                {renderProfileImage()}
            </div>
            {visible ? <div className="overlay-bar"></div> : null}
            <div className={visible ? "sidebar-mobile opened" : "sidebar-mobile"}>
                <div className="sidebar-mobile-wrapper">
                    <div className="bar-header" onClick={toogleSidebar}>
                        <SvgIcon name='chevron-left' viewbox="0 0 8 14" />
                        My Profile
                    </div>
                    <div className="bar-body">
                        <div className="user-card">
                            <div className="upper-row">
                                <div className="left-image">
                                    {renderProfileImage()}
                                </div>
                                {props.auth ? (
                                    <div className="header">
                                        <div className='names'>
                                            {renderName()}
                                            <Link to="/profile">
                                                <SvgIcon className='edit-link' name='edit' viewbox='0 0 31.5 31.5' />
                                            </Link>
                                        </div>
                                        <div className="meta">
                                            <p className="mb-0">
                                                {render_company()}
                                            </p>
                                            <a href="/profile" className="viewprofile-link">View your profile</a>
                                        </div>
                                    </div>
                                )
                                    : (
                                        <Button primary basic onClick={() => { gotoLogin() }}>Log in / Sign up</Button>
                                    )
                                }
                            </div>
                            {!props.auth && (
                                <span>
                                    <div className="bottom-row">
                                        <h2>You are now “Guest user”</h2>
                                        <p>Please, Log in/Sign up to the account to get full access</p>
                                    </div>
                                </span>
                            )}
                            {props.auth && (

                                <div className="userprofile-sidebar">
                                    {renderProfileProgress()}
                                    <div className="extra pr-0">
                                        <Link id="connections" to="/connections">
                                            Network Connections
                                        </Link>
                                        <span className="rightnum-arrow" onClick={() => goto_connection()}><b>  
                                            {connectionsCount} 
                                        </b></span>
                                    </div>
                                    <div className="extra">
                                        <a id="connections" onClick={() => triggerproductList()}>
                                            Technologies I Follow
                                        </a>
                                        <span onClick={() => triggerproductList()}><b>{followedProducts && followedProducts.Items && followedProducts.Items.length > 0 && followedProducts.Items.length}</b></span>
                                    </div>
                                </div>
                            )}
                        </div>
                        {props.auth && (
                            <div className="user-card pb-0">
                                <h3 className="titles">My Connections</h3>
                                <div className="extra-small border-0">
                                    <Link id="vendors" to="/vendors">
                                        Vendors
                                        <b>{vendorsCount}</b>
                                    </Link>
                                </div>
                                <div className="extra-small">
                                    <Link id="customers" to="/customers">
                                        Customers
                                        <b>{customersCount}</b>
                                    </Link>
                                </div>
                            </div>
                        )}

                        <div className="links-card">
                            <div className="company_product_wrapper" onClick={toogleSidebar}>
                                {openAddProduct &&
                                    <AddNewProduct
                                        open={openAddProduct}
                                        handleClose={handleProductClose}
                                        type="Product"
                                        handlePSucess={handleProductSucess}
                                    />
                                }

                                {openAddCompany &&
                                    <AddNewCompany
                                        open={openAddCompany}
                                        handleClose={handleCompanyClose}
                                        productId={''}
                                        productName={''}
                                        addAsSupplier={false}
                                        handleCSucess={handleCompanySucess}
                                        history={props.history}
                                        company_id={get_company_id}
                                    />
                                }
                            </div>
                            {
                                company_submit == true && <SweetAlerts type={'S'} title={'Company has been added successfully'} message={'The company you submitted is undergoing a verification process and will be accessible to everyone once it receives approval'} button_text={'Okay'} closeAlert={handleCompanyCloseAlert}> </SweetAlerts>
                            }
                            {
                                product_submit && <SweetAlerts type={'S'} title={'Product has been added successfully'} message={'The product you submitted is undergoing a verification process and will be accessible to everyone once it receives approval'} closeAlert={handleProductCloseAlert}> </SweetAlerts>
                            }

                            {props.auth && (
                                <span>
                                    <a onClick={handleProductOpen}>
                                        <div className='left-icon'>
                                            <SvgIcon name='technology-icon' viewbox="0 0 24 24" />
                                        </div>
                                        <div className='right-dtl'>
                                            <b>Create a technology page</b>
                                            <p>Add new technology or products</p>
                                        </div>
                                    </a>
                                    <a onClick={handleCompanyOpen}>
                                        <div className='left-icon'>
                                            <SvgIcon name='company-icon' viewbox="0 0 24 24" />
                                        </div>
                                        <div className='right-dtl'>
                                            <b>Create a Company page</b>
                                            <p>Add a new company to the Mappes</p>
                                        </div>
                                    </a>
                                    <Link to="/request-quote" >
                                        <div className='left-icon'>
                                            <SvgIcon name='quotation-icon' viewbox="0 0 24 24" />
                                        </div>
                                        <div className='right-dtl'>
                                            <b>Get Quotation</b>
                                            <p>Suprised to get your best deals</p>
                                        </div>
                                    </Link>
                                </span>
                            )}
                            {/* <a href='https://blog.mappes.io'>
                                <div className='left-icon'>
                                    <SvgIcon name='blog-icon' viewbox="0 0 24 24" />
                                </div>
                                <div className='right-dtl'>
                                    <b>Blog</b>
                                    <p>Know more about mappes.io</p>
                                </div>
                            </a> */}
                            <Link to='/aboutus'>
                                <div className='left-icon'>
                                    <SvgIcon name='about-icon' viewbox="0 0 24 24" />
                                </div>
                                <div className='right-dtl'>
                                    <b>About us</b>
                                    <p>Know more about mappes.io</p>
                                </div>
                            </Link>
                            <Link to='/contact'>
                                <div className='left-icon'>
                                    <SvgIcon name='contact-icon' viewbox="0 0 24 24" />
                                </div>
                                <div className='right-dtl'>
                                    <b>Contact us</b>
                                    <p>Need any help on something</p>
                                </div>
                            </Link>
                        </div>
                        {auth && (
                            <div className="links-card">
                                <a href={`${BASE_URL}/auth/logout`}
                                    onClick={() => sessionStorage.setItem('login', 0)} className='align-items-center' >
                                    <div className='left-icon'>
                                        <SvgIcon name='logout-icon' viewbox="0 0 24 24" />
                                    </div>
                                    <div className='right-dtl'>
                                        <b>Logout</b>
                                    </div>
                                </a>
                            </div>
                        )}
                        <div className="bar-footer">
                            <img src={mappes} alt="mappes" />
                            <p>
                                Terms & conditions, and Privacy policies
                            </p>
                        </div>
                    </div>
                </div>
            </div>
        </span>
    )
}


function mapStateToProps({ auth, chatUser, user }) {
    return {
        auth,
        userProfileInfo: user.userInfoCollection.find(item => item.id === auth.id),
        chatUser,
        userProfileDetails: user.userProfileDetails,
        notificationClicked: user.notificationClicked,
        vendorsCount: user.vendorsCount,
        connectionsCount: user.connectionsCount,
        customersCount: user.customersCount,
        followedProducts: user.followedProducts,
    };
}

function mapDispatchToProps(dispatch) {
    return { ...bindActionCreators({ ...productActions, ...userActions, ...authActions, getUserInfo }, dispatch) }
}


export default connect(
    mapStateToProps,
    mapDispatchToProps,
)(withRouter(MobileSideBar));
