import React from 'react';
import { Link } from 'react-router-dom';
import { Button, Modal, Feed, Header, Form, Image, Transition, List, ListItem } from 'semantic-ui-react';
import { formatIsoMiliseconds, replaceSpaceInName, feedTimeAgo } from '../../../common/helperMethods';
import TagProductAndCompany from './TagProductAndCompany';
import { SvgIcon, Row, Col } from '../../common';
import CustomRickEditor from '../../networkFeed/AddNewPost/NewsRichEditor';
import FileUploadControl from '../../common/FileUploadControl';
import Previewer from '../../networkFeed/AddNewPost/Previewer';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import MappesDropdown from '../../common/MappesDropdown';


const Name = props => {
    return props.auth ? <div className="profile-name">{props.auth.displayName}</div> : <span className="hide-menu">Loading...</span>;
};

class ArticleHeaderNew extends React.Component {
    tag_article_data = []
    constructor(props) {
        super(props);
        this.state = {
            remove_media: false,
            updated_media: false,
            tagged_product: [],
            tagged_companies: [],
            modalOpen: false,
            addRawButtonLoading: false,
            resultsRendered: 4,
            showLoadMore: true,
            description: "",
            description: "",
            displayName: '',
            image: '',
            taggingInProgress: false,
            text: '',
            postMediaType: '',
            postMediaUrl: '',
            postStatus: '',
            filaname: '',
            filevalue: '',
            article_id: '',
            fileReadCompleted: false,
            showFileUploadControl: false,
            post: {
                "userId": props.auth.id,
                "visibility": "public",
                "taggedProduct": [],
                "taggedCompanies": [],
                "remove_media": false,
            }
        }


    }

    handleClose = (event) => {
        if (event !== undefined) {
            event.stopPropagation();
        } // This will stop the event from bubbling up
        this.setState({ modalOpen: false,/*  tagged_product: [], tagged_companies: [] */ })
    };

    setTaggedCompProd = (article, feedType, auth_id) => {
        this.tag_article_data = JSON.parse(JSON.stringify(article));
    }

    setTaggedProducts = (tagged_product) => {
        this.setState({ tagged_product })
    }

    setTaggedCompanies = (tagged_companies) => {
        this.setState({ tagged_companies })
    }

    tagProductsAndCompaniesInPost = (e) => {
        if (e !== undefined) {
            e.stopPropagation();
        }
        const { feedType, auth, entityId } = this.props;
        let { tagged_product, tagged_companies } = this.state;
        
        const data = { tagged_product, tagged_companies };
        const { tagProductsAndCompaniesInPost, article } = this.props;

        tagProductsAndCompaniesInPost(article.id, data, feedType, auth.id, entityId);
        this.setState({ taggingInProgress: true })
    }

    componentWillReceiveProps(nextProps) {
        if (nextProps.postTagged && this.state.taggingInProgress) {
            this.setState({ taggingInProgress: false })
            setTimeout(() => {
                this.setState({ modalOpen: false })
            }, 3000)
        }

        if (this.props.postTaggedFailed != undefined && this.props.postTaggedFailed != '') {
            this.setState({ taggingInProgress: false })

        }
    }

    editArticle = (article) => {
        const { auth, history, feedType, entityId } = this.props;
        if (auth) {
            var new_route = `/edit-article/${article.id}`;
            if (feedType == 'product' || feedType == 'company') {
                new_route = new_route + '/' + feedType + '/' + entityId
            }
            history.push(new_route)
        }
    }

    editPost = (article, feedType, auth_id) => {
        // console.log('article ', article)
        let mediaType = '';
        let media_link = '';
        let media_name = '';
        
        if (article.media != undefined) {
            if ((Object.keys(article.media)).length > 0) {
                mediaType = Object.keys(article.media)[0]
                media_link = article.media[mediaType]['data'];
                media_name = article.media[mediaType]['name'];
                this.setState({ showFileUploadControl: false, fileReadCompleted: true, });
            } else {
                this.setState({ showFileUploadControl: false, fileReadCompleted: false, });
                this.previewPostMedia('', '');
                this.setState({
                    filaname: '',
                    filevalue: ''
                });
            }
        }

        var tagged_companies = (article.tagged_companies !== undefined && article.tagged_companies.length > 0) ? article.tagged_companies : [];
        var tagged_product = (article.tagged_product !== undefined && article.tagged_product.length > 0) ? article.tagged_product : [];

        this.setState({
            updated_media: false,
            text: article.text,
            postMediaType: mediaType,
            postMediaUrl: media_link,
            filaname: media_name,
            postStatus: '',
            tagged_product: tagged_product,
            article_id: article.id,
            tagged_companies: tagged_companies,
            post: {
                "postMediaType": mediaType,
                "postMediaUrl": media_link,
                "filaname": media_name,
                "text": article.text,
                "userId": article.user.user_id,
                "visibility": "public",
                "taggedProduct": tagged_product,
                "taggedCompanies": tagged_companies,
                "remove_media": false,
            }
        })
       
        setTimeout(() => {
            this.defaultPostState = JSON.parse(JSON.stringify(this.state.post));
        }, 500);
    }

    assemblePost(name, value) {
        this.setState({ post: this.defaultPostState })

        const post = this.state.post;
        post[name] = value;
        this.setState({ post })
    }

    handleVisibility = (e) => {

        this.setState({ showFileUploadControl: !this.state.showFileUploadControl });
        this.setState({ label: e.target.name === "pdfOrPpt" ? "Upload your pdf" : "Upload your photo or video" });
    }

    handleEditorText = (value) => {
        // console.log('handle value ', value)
        // console.log('handle trim value ', this.trim_blank_tag(value) )
        this.setState({ post: { text: value } });
        let isYouTubeVideo = value.indexOf('https://www.youtube.com') > -1;
        if (isYouTubeVideo) {
            this.previewPostMedia('youtube', value);
        } else {
            //hide Previewer
            // this.previewPostMedia('', '');
        }
        this.assemblePost('text', value);
    }


    renderProfileImage() {
        const { auth, userProfileInfo, article } = this.props;
        var userInitials = auth.displayName != '' && auth.displayName != null ? auth.displayName.split(" ").map((n) => n[0]).join("") : '';  // auth.displayName.split(' ')[0].substring(0, 1)
        if (userProfileInfo) {
            if (userProfileInfo.image && userProfileInfo.linkedinImage) {
                return (
                    <React.Fragment>
                        <Image
                            className="avatar-48"
                            avatar
                            src={userProfileInfo.image}
                            srcChanged={false}
                            onError={e => {
                                if (!e.target.srcChanged) {
                                    e.target.src = userProfileInfo.linkedinImage;
                                }
                                e.target.srcChanged = true;
                            }}
                        />
                    </React.Fragment>
                );
            } else if (userProfileInfo.image) {
                return (
                    <React.Fragment>
                        <Image className="avatar-42" avatar src={userProfileInfo.image} />
                    </React.Fragment>
                );
            } else if (userProfileInfo.linkedinImage) {
                return (
                    <React.Fragment>
                        <Image className="avatar-42" avatar src={userProfileInfo.linkedinImage} />
                    </React.Fragment>
                );
            }
        } else if (article.user) {
            return (
                <React.Fragment>
                    <Image className="avatar-42" avatar src={article.user.image} />
                </React.Fragment>
            );
        }
        return <div className='art-header-img'> <Feed.Label> {userInitials} </Feed.Label> </div>;
    }

    handleFileUpload = (event) => {
        let file = event.target.files[0];

        if (file) {

            let fileType = file.type;
            let name;

            if (fileType.indexOf("image") > -1) name = "image";
            else if (fileType.indexOf("video") > -1) name = "video";
            else if (fileType.indexOf("pdf") > -1) name = "pdf";
            else if (fileType.indexOf("powerpoint") > -1) name = "ppt";
            else if (fileType.indexOf("presentation") > -1) name = "ppt";

            let reader = new FileReader();
            this.assemblePost(name, file);
            this.setState({
                filaname: name,
                filevalue: file
            });
            let self = this;
            reader.onload = function (e) {
                self.previewPostMedia(name, e.target.result);

                self.setState({ fileReadCompleted: true, showFileUploadControl: false })
            };
            reader.readAsDataURL(file);

            // const post_media_remove = this.state.post;
            // this.state.post.remove_media = false;
            // this.setState({ post_media_remove });

            const post = this.state.post;
            post['remove_media'] = false;
            this.setState({ post })

            this.setState({ updated_media: true });
        }
    }

    handleOpen(status) {

        this.setState({ popupOpen: status });

    }

    handleTageOpenClose(status) {

        this.setState({ modalOpen: status });

    }

    previewPostMedia(postMediaType, postMediaUrl) {
        this.setState({
            postMediaType: postMediaType,
            postMediaUrl: postMediaUrl
        })
    }

    changeSelectedFile = () => {
        //show file upload control
        this.setState({ showFileUploadControl: true, fileReadCompleted: false, });

        //hide previewer
        // this.props.previewPostMedia('', '');
        // const post_media_remove = this.state.post;
        // post_media_remove.remove_media = true;
        //     this.setState({ post_media_remove })
        const post = this.state.post;
        post['remove_media'] = true;
        this.setState({ post })
        this.previewPostMedia('', '');
    }

    useTaggedProduct(value) {
        if (value.length > 0) {
            const post = this.state.post;
            

            let taggedProducts = [];
            for (let item of value) {
                taggedProducts.push(item.key);
            }

            if (post.taggedProduct.length > 0) {
                //post.taggedProduct.push(taggedProducts);
                post.taggedProduct = [...post.taggedProduct, ...taggedProducts]

            } else {
                post.taggedProduct = taggedProducts;
            }
            post.taggedProduct = [...new Set(post.taggedProduct)]
            
        }
    }

    useTaggedCompany(value) {
        if (value.length > 0) {
            console.log('c value ', value )
            const post = this.state.post;

            let taggedCompanies = [];
            for (let item of value) {
                taggedCompanies.push(item.key);
            }

            if (post.taggedCompanies.length > 0) {
                //post.taggedCompanies.push(taggedCompanies);
                post.taggedCompanies = [...post.taggedCompanies, ...taggedCompanies]

            } else {
                post.taggedCompanies = taggedCompanies;
            }

            post.taggedCompanies = [...new Set(post.taggedCompanies)]
            
        }
    }

    popup_tagged_products = (value) => {
        this.useTaggedProduct(value);
    }

    popup_tagged_company = (value) => {
        this.useTaggedCompany(value);
    }

    getUnique(array) {
        var unique_arr = array.filter(function (item, pos) {
            return array.indexOf(item) == pos;
        });

        return unique_arr;
    }

    updatePost() {
        if (this.state.post.text != '<br>' || this.state.postMediaType != '' || this.state.postMediaUrl != '') {
            const { feedType, auth, updateFeedPostDetail } = this.props;
            const posttp = this.state.post;
            const taggedProducts = this.state.post.taggedProduct.slice();

           // taggedProducts.push(this.state.article_id);

            posttp.taggedProduct = this.getUnique(taggedProducts);

            this.setState({ posttp })

            const posttc = this.state.post;
            const taggedCompanies = this.state.post.taggedCompanies.slice();

           // taggedCompanies.push(this.state.article_id)
            posttc.taggedCompanies = this.getUnique(taggedCompanies);
            
            this.setState({ posttc })

            if (this.state.postMediaType == '') {
                this.empty_post_values();
            } else {
                if (this.state.updated_media == true) {
                    this.assemblePost(this.state.filaname, this.state.filevalue);
                    this.previewPostMedia(this.state.postMediaType, this.state.postMediaUrl);
                } else {
                    this.empty_post_values();

                }
            }
            updateFeedPostDetail(this.state.post, feedType, this.state.article_id, auth, this.props.entityId);

            this.setState({
                post: {
                    "userId": auth.id,
                    "visibility": "public",
                    "taggedProduct": [],
                    "taggedCompanies": [],
                    "postMediaType": '',
                    "postMediaUrl": '',
                    "filaname": '',
                    "filevalue": '',
                    "text": '',
                    "remove_media": false,
                }
            })

            this.child.clearInput();
            setTimeout(() => {
                this.handleOpen(false);
                this.empty_post_values();
            }, 400);
        }
    }

    empty_post_values() {
        this.assemblePost('image', '');
        this.assemblePost('video', '');
        this.assemblePost('pdf', '');
        this.assemblePost('ppt', '');
    }

    navigateToUserFeed = () => {
        const { auth, history, article, showLoginModal } = this.props;
        if (auth) {
            history.push(`/networkFeed/user/${article.user_id}`)
        } else {
            showLoginModal(true)
        }
    }

    render() {

        const { feedType, removePost, networkFeedUsers, article, auth, entityId, postTagged, postTaggedFailed } = this.props;

        var postDate = feedTimeAgo(article.added);
        var userInfo = article.user;
        var displayName = userInfo ? userInfo.user_name : "";
        var image = userInfo ? userInfo.image : '';
        var linkedinImage = userInfo ? userInfo.linkedinImage : image;
        var companyName = userInfo ? userInfo.user_company_name : '';
        var companyId = userInfo ? userInfo.user_company_id : '';
        var userInitials = displayName ? displayName.split(" ").map((n) => n[0]).join("").substring(0, 2) : '';
        var articleType = article.type;
        return (
            <React.Fragment>
                <div className="event-head">
                    <div className="event-head-left" id="post-image">
                        {
                            image || linkedinImage ?
                                <Feed.Label  image={image} linkedinImage={linkedinImage} />
                                :
                                displayName ? <Feed.Label> {userInitials} </Feed.Label> : ''
                        }
                        <div className="header-dtl">
                            <b> <Link id="post-name" className="text-name" to={`/networkFeed/user/${article.user_id}`}>{displayName}</Link></b>
                            <p>{postDate}    <Link id="post-company" to={`/company/${replaceSpaceInName(companyName)}/${companyId}`} className="text-secondary " title={companyName}> {/* <SvgIcon name="building" viewbox="0 0 31.5 36" title={companyName} /> */} &nbsp; |   {companyName}  </Link> </p>
                        </div>
                    </div>
                    <div className="event-head-right">
                        {auth && /* article.user_id === auth.id && */
                            <MappesDropdown overlayClass='feed-editdelete-action' width={240} buttonLabel={<div className='toogle-icon'><SvgIcon id="post-actions" className="ellipsis-action" viewbox='0 0 10.125 38.875' name='ellipsis-vertical' /></div>}>
                                    <List>
                                    {auth && article.user_id === auth.id && articleType == 'article' &&
                                        <List.Item as={'a'} className="dropDownThreeDots" onClick={() => this.editArticle(article, feedType, auth.id)}>
                                            <SvgIcon name='edit' viewbox='0 0 31.5 31.5' /> Edit
                                        </List.Item>
                                    }
                                    {auth && article.user_id === auth.id && articleType == 'post' &&
                                        <>
                                            <Modal
                                                className="add-post-modal-new"
                                                onClose={() => this.handleOpen(false)}
                                                onOpen={() => this.handleOpen(true)}
                                                open={this.state.popupOpen}
                                                style={{ maxWidth: '660px' }}
                                                size='small'
                                                trigger={<List.Item className="dropDownThreeDots" onClick={() => this.editPost(article, feedType, auth.id)}><SvgIcon name='edit' viewbox='0 0 31.5 31.5' /> Edit</List.Item>}
                                            >
                                                <Header>Update post <SvgIcon onClick={(e) => e.stopPropagation() || this.handleOpen(false)} className='close-icon' name='close-alt' viewbox='0 0 15 15.001' /></Header>
                                                <Modal.Content>
                                                    <Modal.Description>
                                                        <Form>
                                                            <div className='modal-upper'>
                                                                <div className="d-flex mb-4 align-items-center">
                                                                    <div className="user-avtar-image mr-2">
                                                                        {this.renderProfileImage()}
                                                                    </div>
                                                                    <div className="profile-name"> <Name auth={this.props.auth} /></div>
                                                                </div>
                                                                <Form.Field>
                                                                <CustomRickEditor value={this.state.text} onEditorChange={this.handleEditorText} onRef={ref => (this.child = ref)} gettagged_products={this.popup_tagged_products} gettagged_company={this.popup_tagged_company} auth={this.props.auth} stripPastedStyles={true} toolbar={false} article={false} />
                                                                </Form.Field>
                                                                <p className='bottom-text'>Use # to tag products, and use @ to tag companies</p>
                                                                <div className='fileupdated-view'>
                                                                    {this.state.fileReadCompleted &&
                                                                        <Button className="remove-btn" circular size="tiny" floated='right' onClick={this.changeSelectedFile}>
                                                                            <SvgIcon name='close-alt' viewbox='0 0 15 15.001' />
                                                                        </Button>
                                                                    }

                                                                    {this.state.showFileUploadControl &&
                                                                        <Transition.Group animation={'slide down'} duration={300} >
                                                                            <div className="mT-5 mB-20">
                                                                                <label>{this.state.label}</label>
                                                                                <FileUploadControl handleFileUpload={this.handleFileUpload} />
                                                                            </div>
                                                                        </Transition.Group>
                                                                    }

                                                                    <div className='imgviewer-uploaded'>
                                                                        <Previewer type={this.state.postMediaType} url={this.state.postMediaUrl} postStatus={this.state.postStatus} />
                                                                    </div>
                                                                </div>
                                                            </div>

                                                            <div className="footer">
                                                                {/* <Button className="ml-1" type='submit' onClick={() => this.handleOpen(false)}>Cancel</Button> */}
                                                                <div className='left-footer'>
                                                                    {/* <div className='smile-icon'>
                                                    <Button>
                                                        <SvgIcon name='smile-icon' viewbox='0 0 22 22' />
                                                    </Button>
                                                </div> */}
                                                                    <div>

                                                                        {/* <Button><SvgIcon name='link-icon' viewbox='0 0 15.258 15.245' /></Button> */}
                                                                        <Button className="file_upload_op" name="pdfOrPpt" onClick={this.handleVisibility} ><SvgIcon name='new-pdf-icon' viewbox='0 0 25 25' /></Button>
                                                                        <Button className="file_upload_op" name="imageOrVideo" onClick={this.handleVisibility} ><SvgIcon name='addphoto-icon' viewbox='0 0 22 22' /></Button>
                                                                        <Button className="file_upload_op" name="imageOrVideo" onClick={this.handleVisibility} ><SvgIcon name='addvideo-icon' viewbox='0 0 24 21' /></Button>
                                                                    </div>
                                                                </div>

                                                                <Button className="ml-1 post-btn" type='submit' primary onClick={(e) => e.stopPropagation() || this.updatePost()} >Post</Button>
                                                            </div>
                                                        </Form>
                                                    </Modal.Description>
                                                </Modal.Content>
                                            </Modal>
                                            {/* <Modal
                                            className="createa-supplier add-post-modal"
                                            onClose={() => this.handleOpen(false)}
                                            onOpen={() => this.handleOpen(true)}
                                            open={this.state.popupOpen}
                                            size="mini"
                                            trigger={
                                                <DropdownItem className="dropDownThreeDots" text={<span><SvgIcon name='edit' viewbox='0 0 31.5 31.5' /> Edit</span>} onClick={() => this.editPost(article, feedType, auth.id)} />
                                            }
                                        >
                                            <Modal.Content>
                                                <Modal.Description>
                                                    <div className="mb-4"><b>Edit post</b></div>
                                                    <Form>
                                                        <div className="d-flex mb-4 align-items-center">
                                                            <div className="user-avtar-image mr-2">
                                                                {this.renderProfileImage()}
                                                            </div>
                                                            <div>
                                                                <div className="profile-name mb-1"> <Name auth={this.props.auth} /></div>
                                                            </div>
                                                        </div>
                                                        <Form.Field>
                                                            <CustomRickEditor value={this.state.text} onEditorChange={this.handleEditorText} onRef={ref => (this.child = ref)} gettagged_products={this.popup_tagged_products} gettagged_company={this.popup_tagged_company} toolbar={false} article={false} />
                                                        </Form.Field>

                                                        <Row className="pb-4 post-elements-row">
                                                            <Col>
                                                                <a size='mini' basic className="text-black file_upload_op" name="imageOrVideo" onClick={this.handleVisibility}>
                                                                    <SvgIcon name='photo-icon' viewbox='0 0 17 15.3' />
                                                                    Add a photo
                                                                </a>
                                                            </Col>
                                                            <Col>
                                                                <a size='mini' basic className="mr-10 text-black file_upload_op " name="imageOrVideo" onClick={this.handleVisibility}>
                                                                    <SvgIcon name='video-icon' viewbox='0 0 18 12' />
                                                                    Add a video
                                                                </a>
                                                            </Col>
                                                            <Col>
                                                                <a size='mini' basic className="text-black file_upload_op" name="pdfOrPpt" onClick={this.handleVisibility} >
                                                                    <SvgIcon name='document-icon' viewbox='0 0 10.286 13.813' />
                                                                    Add a document
                                                                </a>
                                                            </Col>
                                                        </Row>
                                                        <div className='fileupdated-view'>
                                                            {this.state.fileReadCompleted &&
                                                                <Button icon="delete" className="remove-btn" circular size="tiny" floated='right' onClick={this.changeSelectedFile} />
                                                            }

                                                            {this.state.showFileUploadControl &&
                                                                <Transition.Group animation={'slide down'} duration={300} >
                                                                    <div className="mT-5 mB-20">
                                                                        <label>{this.state.label}</label>
                                                                        <FileUploadControl handleFileUpload={this.handleFileUpload} />
                                                                    </div>
                                                                </Transition.Group>
                                                            }

                                                            <div className='imgviewer-uploaded'>
                                                                <Previewer type={this.state.postMediaType} url={this.state.postMediaUrl} postStatus={this.state.postStatus} />
                                                            </div>
                                                        </div>
                                                        <div className="text-right mt-5">
                                                            <Button className="ml-1" type='button' onClick={() => this.handleOpen(false)}>Cancel</Button>
                                                            <Button className="ml-1" type='submit' primary onClick={() => this.updatePost()}>Update</Button> 
                                                        </div>
                                                    </Form>
                                                </Modal.Description>
                                            </Modal.Content>
                                        </Modal> */}
                                        </>
                                    }
                                    {
                                        auth /* && article.user_id === auth.id */ &&
                                        <Modal
                                            className="tagscp-modal"
                                            onClose={() => this.handleTageOpenClose(false)}
                                            onOpen={() => this.handleTageOpenClose(true)}
                                            open={this.state.modalOpen}
                                            size={'small'}
                                            trigger={
                                                <List.Item as={'a'} className="dropDownThreeDots" onClick={() => this.setTaggedCompProd(article, feedType, auth.id)}>
                                                    <SvgIcon name='tag' viewbox='0 0 31.995 32' /> Tag Products / Companies
                                                </List.Item>
                                            }>
                                            <Modal.Header><h3>Tag Products / Companies</h3> <div className='closeicon-header' onClick={this.handleClose}><SvgIcon name='close-alt' viewbox="0 0 15 15.001" /></div></Modal.Header>
                                            <Modal.Content>
                                                <Modal.Description>
                                                    <Form>
                                                    <Row>
                                                        <Col sm='12' className='mb-2'>
                                                            <Form.Field>
                                                                <label>Products</label>
                                                                <TagProductAndCompany
                                                                    searchType="product"
                                                                    setTaggedProducts={this.setTaggedProducts}
                                                                    setTaggedCompanies={this.setTaggedCompanies}
                                                                />
                                                            </Form.Field>
                                                        </Col>
                                                        <Col sm='12'>
                                                            <Form.Field>
                                                                <label>Companies</label>
                                                                <TagProductAndCompany
                                                                    searchType="company"
                                                                    setTaggedProducts={this.setTaggedProducts}
                                                                    setTaggedCompanies={this.setTaggedCompanies}
                                                                />
                                                            </Form.Field>
                                                        </Col>
                                                    </Row>
                                                    
                                                    <div className="text-center pt-4 mb-2 mt-auto">
                                                        <Button className='mr-2' primary loading={this.state.taggingInProgress} onClick={this.tagProductsAndCompaniesInPost} >
                                                            Submit
                                                        </Button>
                                                        <Button onClick={this.handleClose} >
                                                            Cancel
                                                        </Button>
                                                    </div>
                                                    </Form>
                                                    {
                                                        postTagged &&
                                                        <span>Post successfully tagged, closing this window 3 secs.... </span>
                                                    }
                                                    {
                                                        postTaggedFailed &&
                                                        <span>{postTaggedFailed} </span>
                                                    }
                                                </Modal.Description>
                                            </Modal.Content>
                                        </Modal>
                                    }
                                    {
                                        auth && article.user_id === auth.id &&
                                        <List.Item as={'a'} className="dropDownThreeDots" onClick={() => removePost(article.id, feedType, auth.id, entityId)}>
                                            <SvgIcon name='delete' viewbox='0 0 30 33' /> Remove
                                        </List.Item>
                                    }
                                    </List>
                            </MappesDropdown>
                            
                        }

                    </div>
                </div>

            </React.Fragment>
        )
    }
}

export default ArticleHeaderNew;