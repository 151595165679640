import React from 'react';
import { Button, Modal, Form, Message } from 'semantic-ui-react';
import { commonActions, productActions } from '../../../actions';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { apiStatus } from '../../../common/constants';
import SvgIcon from '../svg-icon/svg-icon';
import MediaQuery from 'react-responsive';

class AddNewProduct extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            open: false,
            name: '',
            desc: '',
        }

    }
    
    componentDidMount(){
           this.props.setSaveStatus(apiStatus.UNDEFINED);
    }

    componentDidUpdate() {
            if (this.props.saveStatus === apiStatus.COMPLETED) {
            if (this.props.type === "RawMaterial") {
                if (this.props.productId) {
                    this.props.getProductRawMaterial(this.props.productId);
                }
            }
            else {
                if (this.props.productId) {
                    this.props.getProductApplication(this.props.productId);
                }
            }

            setTimeout(() => {
                this.props.handlePSucess();
                this.handleClose();
            }, 1200);
        }
    }

    handleCountrySelect = (e, { value }) => {
        this.setState({ country: value })
    }

    handleSave = (e) => {
        e.preventDefault();
        const { elements } = e.target;
        const data = {
            name: elements.name.value.trim(),
            desc: elements.desc.value,
        };

        this.props.addProduct(data);

    }


    handleClose = (e) => {
        this.props.setSaveStatus(apiStatus.UNDEFINED);
        this.props.handleClose();
        if (e !== undefined){
            e.stopPropagation();
        }
    }

    formatInput = (event) => {
        const attribute = event.target.getAttribute('name')
        this.setState({ [attribute]: event.target.value.trim() })
    }


    handleChange = (event) => {
        const { id, value } = event.target
        this.setState({
            [id]: value
        })
    }

    render() {
        const { saveStatus, type } = this.props;

        return (
            <Modal
                className="creataproduct-modal"
                open={this.props.open}
                size="small"
            >
                <MediaQuery minWidth={768}>
                    <Modal.Header>
                        <h3>Create a product</h3>
                        <div className='closeicon-header' onClick={this.handleClose}>
                            <SvgIcon name='close-alt' viewbox="0 0 15 15.001" />
                        </div>
                    </Modal.Header>
                </MediaQuery>
                <MediaQuery maxWidth={767}>
                    <Modal.Header>
                        <div className='backicon-header' onClick={this.handleClose}>
                            <SvgIcon name='chevron-left' viewbox="0 0 10 16" />
                        </div>
                        <h3>Create a product</h3>
                    </Modal.Header>
                </MediaQuery>
                <Modal.Content scrolling>
                    <Modal.Description>
                        <p>
                            Create a new product or technology page
                        </p>
                        <Form onSubmit={this.handleSave}>
                            <Form.Field>
                                <label>Product Name</label>
                                <input id="name" onBlur={this.formatInput} placeholder='Enter Product or Technology name' onChange={this.handleChange} />
                            </Form.Field>
                            <Form.Field>
                                <label>Description <small>(Optional)</small></label>
                                <textarea id="desc" rows={3} placeholder='Enter product description' onChange={this.handleChange} />
                            </Form.Field>
                            {
                                saveStatus === apiStatus.ALREADY_EXISTS ?
                                    <span>
                                        <div className="text-left mt-2 mb-2">
                                            <Message className="reduce-msg-padding" compact visible error content={`Product Already Exists `} />
                                        </div>
                                    </span>
                                    :
                                    <span></span>
                            }
                            <div className='text-center pt-4 mb-2 mt-auto'>
                                {
                                    saveStatus === apiStatus.COMPLETED ?
                                        <span>
                                            {/* <Message compact visible success content={`${type} added successfully. Please close this dialog box`} /> */}
                                            <Button type='button' className="" onClick={this.handleClose}>Close</Button>
                                        </span>
                                        :
                                        <span>
                                            <Button className="ml-1 addproduct-btn" type='submit' disabled={!this.state.name.length} primary loading={saveStatus === apiStatus.IN_PROGRESS}>Add Product</Button>
                                            <Button type='button' className="addproduct-btn" 
                                                onClick={(event) => {event.stopPropagation();  this.props.handleClose();}}>Cancel</Button>
                                        </span>
                                }
                            </div>
                        </Form>
                    </Modal.Description>
                </Modal.Content>
            </Modal >
        )

    }
}

const mapStateToProps = ({ common }) => {
    return {
        saveStatus: common.saveStatus
    };
}

const mapDispatchToProps = (dispatch) => {
    return {
        dispatch,
        ...bindActionCreators({ ...productActions, ...commonActions }, dispatch),
    }
}
export default connect(mapStateToProps, mapDispatchToProps)(AddNewProduct);