import React, { useEffect, useState } from 'react';
import { BreadcrumbSection, Button, BreadcrumbDivider, Breadcrumb, Search, Checkbox, Input, Loader } from 'semantic-ui-react';
import Navbar from '../common/layout/navbar/Navbar';
import { bindActionCreators } from 'redux';
import { commonActions, NewsListAction, getUserInfo, getUserProfileInfo } from '../../actions/';
import { connect } from 'react-redux';
import SEO from '../seo/SEO';
import InfiniteScroll from 'react-infinite-scroll-component';
import { BASE_URL } from '../../config/config';
import { articleTimeAgo } from '../../common/helperMethods';
import "./index.scss";
import { Col, Row, SvgIcon } from '../common';
import { helperMethods } from '../../common'
import axios from 'axios';
import { Link } from 'react-router-dom';
import MediaQuery from 'react-responsive';
import Microlink from '@microlink/react'
import { showLoginModal } from '../../actions/common/common';
import LoginButton from '../common/LoginButton';


import { NEWS_LIST, NEWS_PREFERED_COMPANY_LIST, NEWS_PREFERED_PRODUCT_LIST } from '../../config/types';


const options = [
    {
        key: 1,
        text: 'Automatic solar cells tabber stringer',
        value: 1
    },
    {
        key: 2,
        text: 'Automatic solar cells tabber stringer',
        value: 2
    },
    {
        key: 3,
        text: 'Automatic solar cells tabber stringer',
        value: 3
    },
]

const NewsList = (props) => {
    const { auth, saveStatus, getNewsList, get_prefered_product_company, news_list_data, news_prod_data, news_company_data, setNewsPagination, newsPagination, resetNewsagination, news } = props;
    const [visible, setVisible] = useState(false)
    const [loader, setLoader] = useState(false)
    const [searchProduct, setSearchProduct] = useState('')
    const [searchCompany, setSearchCompany] = useState('')
    const [newsData, setNewsData] = useState([])
    const [productData, setProductData] = useState([])
    const [companyData, setCompanyData] = useState([])
    const [searchproductData, setSearchProductData] = useState([])
    const [searchcompanyData, setSearchCompanyData] = useState([])
    const [FilterData, setFilterData] = useState([])

    useEffect(() => {
        console.log('in here ')

        setLoader(true)

        const newsPagination = { start: 0, size: 30 };
        setNewsPagination(newsPagination);
        get_prefered_product_company()
        getNewsList([], []);

    }, [])

    useEffect(() => {
        if (news_list_data) {
            setNewsData(news_list_data);
            setTimeout(() => {
                setLoader(false)
            }, 200);
        }

        if (news_prod_data) {
            setProductData(news_prod_data);
        }

        if (news_company_data) {
            setCompanyData(news_company_data);
        }
    }, [news_list_data, news_prod_data, news_company_data])

    const go_back = () => {
        const { dispatch, history } = props;
        // clear everything - start
        dispatch({ type: NEWS_LIST, payload: [] });
        dispatch({ type: NEWS_PREFERED_PRODUCT_LIST, payload: [] });
        dispatch({ type: NEWS_PREFERED_COMPANY_LIST, payload: [] });
        // clear everything - end
        history.push('/')
    }

    const setSelectedProduct = (data, item, index) => {
        let c_id = '';
        if (searchProduct != '') {
            searchproductData[index]['_source']['checked'] = data.checked
            c_id = item['_source']['id']

        } else {
            productData[index]['checked'] = data.checked
            c_id = item.id
        }

        filter_news_data('product', data.checked, c_id);
    }

    const setSelectedCompany = (data, item, index) => {
        let c_id = '';
        if (searchCompany != '') {
            searchcompanyData[index]['_source']['checked'] = data.checked
            c_id = item['_source']['id']
        } else {
            companyData[index]['checked'] = data.checked
            c_id = item.id
        }
        newsPagination.start = 0;
        setNewsPagination(newsPagination);
        filter_news_data('company', data.checked, c_id);
    }

    const render_product_list = () => {
        if (productData != undefined) {
            return productData.length > 0 && productData.map((item, index) => {
                return (
                    <li>
                        <Checkbox label={item.name} onClick={(e, data) => setSelectedProduct(data, item, index)} checked={item.checked} />
                    </li>
                )
            })
        }
    }

    const render_company_list = () => {
        if (companyData != undefined) {
            return companyData.length > 0 && companyData.map((item, index) => {
                return (
                    <li>
                        <Checkbox label={item.name} onClick={(e, data) => setSelectedCompany(data, item, index)} checked={item.checked} />
                    </li>
                )
            })
        }
    }

    const render_search_product_list = () => {
        if (searchproductData != undefined) {

            return searchproductData.length > 0 && searchproductData.map((item, index) => {
                return (
                    <li>
                        <Checkbox label={item._source.name} onClick={(e, data) => setSelectedProduct(data, item, index)} checked={item.checked} />
                    </li>
                )
            })
        }
    }

    const render_search_company_list = () => {
        if (companyData != undefined) {
            return searchcompanyData.length > 0 && searchcompanyData.map((item, index) => {
                return (
                    <li>
                        <Checkbox label={item._source.name} onClick={(e, data) => setSelectedCompany(data, item, index)} checked={item.checked} />
                    </li>
                )
            })
        }

    }

    const render_news_list = () => {
        if (news_list_data != undefined) {
            return news_list_data.length > 0 && (
                <InfiniteScroll
                    dataLength={news_list_data.length}
                    next={handleLoadMoreClick}
                    hasMore={news_list_data.length < newsPagination.total}
                    scrollableTarget="sidebar-pushable"
                    loader={auth && <Loader active inline center />}
                    endMessage={
                        <p style={{ textAlign: 'center' }}>
                            {
                                !auth && (<LoginButton />)
                            }
                        </p>
                    }
                >
                    <span>
                        {
                            news_list_data.length > 0 && news_list_data.map((item, index) => {
                                let youtubeVideoId = helperMethods.getYouTubeVideoId(item.text);
                                let articleUrl = '';
                                if (!youtubeVideoId) {
                                    let getTextWithoutAnchor = item.text.replace(/<a[^>]*>|<\/a>/g, "");
                                    articleUrl = helperMethods.getUrlFromHtml(getTextWithoutAnchor);
                                }
                                return (
                                    <Link to={"/feed/post/" + item.id}>
                                        <div className='news-list-item text-black'>
                                            <div className='left-img'>
                                                {articleUrl &&
                                                    <Microlink
                                                        url={articleUrl}
                                                        size="large"
                                                        style={{ width: '100%' }}
                                                    />
                                                }
                                            </div>
                                            <div className='right-dtls'>
                                                <div dangerouslySetInnerHTML={{ __html: item.text }}></div>
                                                <div>
                                                    <p>Published by {item.updatedByUserName}</p>
                                                    <small>{articleTimeAgo(item.added)}</small>
                                                </div>
                                            </div>
                                        </div>
                                    </Link>
                                )
                            })
                        }
                    </span>
                </InfiniteScroll>
            )
        }
    }

    const handleLoadMoreClick = () => {

        if (auth) {
            newsPagination.start += 1;
            setNewsPagination(newsPagination);
            getFilteredNewsData()

        } else {
            showLoginModal(true)
        }
    }

    const searchProducts = (e) => {
        let value = e.target.value;
        setSearchProduct(value)

        if (value != '') {
            if (value.length > 2) {
                let svalue = value.trim()
                axios.get(`${BASE_URL}/api/v1/product/search/${svalue}`, { withCredentials: true }).then(response => {
                    setSearchProductData(response.data);
                });
            } else {
                setSearchProductData([]);
            }
        } else {
            setSearchProductData([]);
        }
    }

    const searchCompanies = (e) => {
        let value = e.target.value;
        setSearchCompany(value)

        if (value != '') {
            if (value.length > 2) {
                let svalue = value.trim()
                axios.get(`${BASE_URL}/api/v1/company/search/${svalue}`, { withCredentials: true }).then(response => {
                    setSearchCompanyData(response.data);

                });
            } else {
                setSearchCompanyData([]);
            }
        } else {
            setSearchCompanyData([]);
        }
    }

    const render_search_input = (type) => {
        if (type == 'product') {
            return (
                <div className='filter-head'>
                    <h4>Product or Technology</h4>
                    <Input className='header-search' icon='search' placeholder='Search “Hydrogen”' value={searchProduct} onChange={searchProducts} />
                </div>
            )
        }

        if (type == 'company') {
            return (
                <div className='filter-head'>
                    <h4>Companies</h4>
                    <Input className='header-search' icon='search' placeholder='Search “Company”' value={searchCompany} onChange={searchCompanies} />
                </div>
            )
        }
    }

    const filter_news_data = (type, flag, id) => {
        let filterState = FilterData;
        let prod_list;
        let comp_list;
        if (type == 'product') {
            if (searchProduct != '') {
                prod_list = searchproductData.filter(obj => { if (obj['_source']['checked'] == true) { return obj } })
            } else {
                prod_list = productData.filter(obj => obj['checked'] == true)
            }
            console.log('prod_list ', prod_list)
            if (flag == true) {
                for (let data of prod_list) {
                    let name = '';
                    let id = '';
                    let checked = '';
                    if (data['_source'] != undefined) {
                        id = data['_source']['id']
                        name = data['_source']['name']
                        checked = data['_source']['checked']
                    } else {
                        id = data['id']
                        name = data['name']
                        checked = data['checked']
                    }

                    filterState.push({
                        'id': id,
                        "name": name,
                        "type": 'product',
                        "checked": checked
                    })
                }
            } else {
                let index = filterState.findIndex(obj => obj.id == id);
                if (index !== -1) {
                    filterState.splice(index, 1)
                }
            }
        }

        if (type == 'company') {
            if (searchCompany != '') {
                comp_list = searchcompanyData.filter(obj => { if (obj['_source']['checked'] == true) { return obj } })
            } else {
                comp_list = companyData.filter(obj => obj['checked'] == true)
            }
            if (flag == true) {
                for (let data of comp_list) {
                    let name = '';
                    let id = '';
                    let checked = '';
                    if (data['_source'] != undefined) {
                        id = data['_source']['id']
                        name = data['_source']['name']
                        checked = data['_source']['checked']
                    } else {
                        id = data['id']
                        name = data['name']
                        checked = data['checked']
                    }

                    filterState.push({
                        'id': id,
                        "name": name,
                        "type": 'company',
                        "checked": checked
                    })
                }
            }
        }

        filterState = filterState.reduce((unique, o) => {
            if (!unique.some(obj => obj.id === o.id && obj.name === o.name)) { unique.push(o); }
            return unique;
        }, []);

        if (flag != true) {
            let index = filterState.findIndex(obj => obj.id == id);
            if (index !== -1) {
                filterState.splice(index, 1)
            }
        }

        setFilterData(filterState);
        setTimeout(() => {
            newsPagination.start = 0;
            setNewsPagination(newsPagination);
            getFilteredNewsData()
        }, 400);
    }

    const getFilteredNewsData = () => {
        setLoader(true)
        let filterState = FilterData;
        console.log('filterState ', filterState)
        let selected_prod = filterState.filter(obj => { if (obj.type == 'product') { return (obj['checked'] == true) } }).map(function (obj) { return obj['id'] });;
        let selected_comp = filterState.filter(obj => { if (obj.type == 'company') { return (obj['checked'] == true) } }).map(function (obj) { return obj['id'] });;
        getNewsList(selected_prod, selected_comp);

    }

    const render_selected_items = () => {
        let filterState = FilterData;
        return filterState.length > 0 && filterState.map((item, index) => {
            let name = '';
            let id = '';
            if (item.name != undefined) {
                name = item.name
                id = item.id
            } else {
                if (item['_source'] != undefined) {
                    name = item['_source']['name']
                    id = item._id
                }
            }

            return (
                <li>{name} <span onClick={() => clearSearch(item.type, id)}><SvgIcon name='close-alt' viewbox="0 0 15 15.001" /></span></li>
            )
        });
    }

    const clearSearch = (type, id) => {
        let filterState = FilterData;
        let index = filterState.findIndex(obj => obj.id == id);
        if (index !== -1) {
            filterState.splice(index, 1)
        }

        if (type == 'product') {
            let ind = searchproductData.findIndex(obj => obj['_source'].id == id);
            if (ind !== -1) {
                searchproductData[ind]['checked'] = false;
            }
            let ind1 = productData.findIndex(obj => obj.id == id);
            if (ind1 !== -1) {
                productData[ind1]['checked'] = false;
            }
        }

        if (type == 'company') {
            let ind = searchcompanyData.findIndex(obj => obj['_source'].id == id);
            if (ind !== -1) {
                searchcompanyData[ind]['checked'] = false;
            }
            let ind1 = companyData.findIndex(obj => obj.id == id);
            if (ind1 !== -1) {
                companyData[ind1]['checked'] = false;
            }
        }
        newsPagination.start = 0;
        setNewsPagination(newsPagination);
        getFilteredNewsData();
    }

    const clear_all = () => {
        let filterState = FilterData;
        filterState.length = 0;
        setFilterData(filterState);
        setSearchProduct('');
        setSearchCompany('');
        if (searchproductData.length > 0) {
            for (let index in searchproductData) {
                searchproductData[index]['checked'] = false;
            }
        }

        if (productData.length > 0) {
            for (let index in productData) {
                productData[index]['checked'] = false;
            }
        }

        if (searchcompanyData.length > 0) {
            for (let index in searchcompanyData) {
                searchcompanyData[index]['checked'] = false;
            }
        }

        if (companyData.length > 0) {
            for (let index in companyData) {
                companyData[index]['checked'] = false;
            }
        }

        setTimeout(() => {
            newsPagination.start = 0;
            setNewsPagination(newsPagination);
            getFilteredNewsData();
        }, 500);

    }

    return (
        <>
            <SEO robots="noindex, nofollow"
                canonicalUrl="/news"
            />
            <Navbar>
                <div className="newslist-mainwrapper">
                    <div className="container">
                        <div className='news-list-bottom'>
                            <div className='news-list-left'>
                                <div className='news-list-upper'>
                                    <MediaQuery minWidth={991}>
                                        <Row className='newslistupper-inner'>
                                            <Col>
                                                {/*  <h2>News</h2>
                                                <Breadcrumb>
                                                    <BreadcrumbSection link>Home</BreadcrumbSection>
                                                    <BreadcrumbDivider />
                                                    <BreadcrumbSection>News</BreadcrumbSection>
                                                </Breadcrumb> */}
                                                <div className='news-list-upper'>
                                                    <a className='back-link' onClick={() => go_back()}><SvgIcon name='arrow-left' viewbox='0 0 23 17' /> Back</a>
                                                </div>
                                            </Col>
                                            {FilterData.length > 0 && (<Col className='text-right'>
                                                <button className='clear-btn' onClick={() => clear_all()}>Clear all</button>
                                            </Col>)}
                                        </Row>
                                    </MediaQuery>
                                    <MediaQuery maxWidth={991}>
                                        <Row className='newslistupper-inner'>
                                            <Col>
                                                <h2><SvgIcon name='chevron-left' viewbox="0 0 10 16" /> News</h2>
                                            </Col>
                                            <Col>
                                                <div className='search-small'>
                                                    <button onClick={() => setVisible(true)} className='filter-btn'><SvgIcon name='filter' viewbox="0 0 24 24" /></button>
                                                </div>
                                            </Col>
                                            <div className={visible ? "filer-modal show" : "filer-modal"}>
                                                <div className='filter-modal-content'>
                                                    <div className='filter-modal-header'>
                                                        <div className='left-header' onClick={() => setVisible(false)}><SvgIcon name='chevron-left' viewbox="0 0 10 16" /> Filters</div>
                                                        <button className='clear-btn'>Clear all</button>
                                                    </div>
                                                    <div className='filter-modal-body'>
                                                        <div className='second-label'>Are you looking for:</div>
                                                        {render_search_input('product')}
                                                        <ul className='filter-search-drop-mobile'>
                                                            {searchProduct == '' && productData && render_product_list()}
                                                            {searchProduct != '' && searchproductData && render_search_product_list()}

                                                        </ul>
                                                        {render_search_input('company')}
                                                        <ul className='filter-search-drop-mobile'>
                                                            {searchCompany == '' && companyData && render_company_list()}
                                                            {searchCompany != '' && searchcompanyData && render_search_company_list()}

                                                        </ul>
                                                        <Button primary onClick={() => setVisible(false)}>Apply</Button>
                                                    </div>
                                                </div>
                                            </div>
                                        </Row>
                                    </MediaQuery>
                                </div>
                                <Row className='filterlist-row'>
                                    <Col className='filterlist-col'>
                                        <div className='filterlist'>
                                            <ul>
                                                {render_selected_items()}
                                            </ul>
                                        </div>
                                    </Col>
                                </Row>

                                {loader && <Loader className={"product-filter-loader mt-2"} active inline center />}
                                <div className='news-list-items'>
                                    {news_list_data && render_news_list()}
                                    {
                                        FilterData.length > 0 && news_list_data.length == 0 && (
                                            <p> <b> No News Found </b> </p>
                                        )}
                                </div>
                            </div>
                            <MediaQuery minWidth={991}>
                                <div className='news-list-right'>
                                    {/* <h3>Filter title heading line here</h3> */}
                                    <div className='second-label'>Are you looking for:</div>
                                    {render_search_input('product')}
                                    <ul className='filter-search-drop'>
                                        {productData && searchProduct == '' && render_product_list()}
                                        {productData && searchProduct != '' && render_search_product_list()}

                                    </ul>
                                    {render_search_input('company')}
                                    <ul className='filter-search-drop'>
                                        {companyData && searchCompany == '' && render_company_list()}
                                        {companyData && searchCompany != '' && render_search_company_list()}

                                    </ul>
                                </div>
                            </MediaQuery>
                        </div>
                    </div>
                </div>
            </Navbar>
        </>
    );
}

function mapStateToProps({ auth, user, common, newsList }) {
    return {
        ...newsList,
        news_prod_data: newsList.new_prefered_prod_list_data,
        news_company_data: newsList.new_prefered_comp_list_data,
        userName: user.userProfileInfo && `${user.userProfileInfo.firstName} ${user.userProfileInfo.lastName}`,
        userId: user.userProfileInfo && user.userProfileInfo.user_id,
        auth,
        common,
    };
}

function mapDispatchToProps(dispatch) {
    return {
        dispatch,
        ...bindActionCreators({ getUserProfileInfo, getUserInfo, ...NewsListAction, ...commonActions }, dispatch)

    }

}

export default connect(mapStateToProps, mapDispatchToProps)(NewsList);