import { DOMAINS } from './open-email-list.js'

const isFreeEmail = (email) => {
   const domain = email.split('@')[1] ?? ''
   return DOMAINS.has(domain)
}

const isCompanyEmail = (email) => {
   const domain = email.split('@')[1] ?? ''
   return !DOMAINS.has(domain)
}

export { isFreeEmail, isCompanyEmail, DOMAINS }