import React, { useEffect, useState } from 'react';
import './index.scss'
import { emailRegex } from '../../../common/constants';
import { isFreeEmail, isCompanyEmail } from '../../../common/emailValidator';
import axios from 'axios';
import { BASE_URL } from '../../../config/config';
import { OTP } from './OTP';
import { Email } from './Email';
import { Password } from './Password';
import { connect } from 'react-redux'

const SCREEN_CONSTANTS = {
    EMAIL: 'EMAIL',
    OTP: 'OTP',
    PASSWORD: 'PASSWORD',
    BASIC_DETAILS: 'BASIC_DETAILS',
    SET_PASSWORD: 'SET_PASSWORD',
}

const LoginWithOtp = (props) => {
    // Login screen states
    const [screen, setScreen] = useState(SCREEN_CONSTANTS.EMAIL);
    const [error, setError] = useState('')
    const [newUser, setNewUser] = useState(false)
    const [emailLoading, setEmailLoading] = useState(false)
    const [formSubmitLoading, setFormSubmitLoading] = useState(false)

    const failure = localStorage.getItem('loginFailure') // to check if re-directed from failure screen

    // Payload states
    const [otp, setOtp] = useState('');
    const [email, setEmail] = useState(() => localStorage.getItem('email') || '')
    const [password, setPassword] = useState('')

    useEffect(() => {
        const { auth, history } = props;
        localStorage.setItem('loginFailUrl', window.location.pathname)
        if (auth.firstName && auth.lastName) {
            // Used first name and last name here is there is no difference between new user with pending details (fn/ln) and old logged in user in terms of auth props
            history.push('/networkFeed/global')
        }

        if (failure) {
            localStorage.removeItem('loginFailure')
        }

        if (failure === "password_failed") { // Wrong Password for existing user
            setScreen(SCREEN_CONSTANTS.PASSWORD)
            setError('The password you have entered is incorrect')
        }
        else if (failure === "otp-failed") { // GET API HERE and show/clear error 
            const formData = {
                verification_token: sessionStorage.getItem('otp'),
                email_id: localStorage.getItem('email')
            }
            axios.post(`${BASE_URL}/api/v2/otp/verify`, formData).then((response) => {
                const { data, messages, success } = response.data
                if (!success) {
                    if (data.reason === 'INVALID') {
                        setScreen(SCREEN_CONSTANTS.OTP)
                    }
                    if (data.reason) {
                        setError(messages[0].message) // Don't need error message on same url re-load
                    }
                }
            })
        }
    }, [])

    const navigateToEmailScreen = () => {
        setOtp('')
        setNewUser(JSON.parse(localStorage.getItem('newUser')))
        setError('')
        setScreen(SCREEN_CONSTANTS.EMAIL)
    }

    const navigateToPasswordScreen = () => {
        setScreen(SCREEN_CONSTANTS.PASSWORD)
        setError('')
    }

    const navigateToOTPScreen = () => {
        setScreen(SCREEN_CONSTANTS.OTP)
        setError('')
    }

    useEffect(() => {
        // Used this as needed after error to call validate API for proper error message
        sessionStorage.setItem('otp', otp)
    }, [otp])

    const submitEmail = (event) => {
        event.preventDefault()
        setError('')

        setTimeout(() => {
            if (emailRegex.test(email)) {
                if (isCompanyEmail(email)) {
                    setEmailLoading(true)
                    axios.post(`${BASE_URL}/api/login/initiate`, {
                        email_id: email
                    }).then((response) => {
                        const { data } = response.data
                        setNewUser(data.newUser)
                        setScreen(SCREEN_CONSTANTS.OTP)
                        localStorage.setItem('userId', data.user_id)
                        localStorage.setItem('newUser', data.newUser)
                        localStorage.setItem('email', email)
                    }).finally(() => {
                        setEmailLoading(false)
                    })
                } else {
                    setError('Please use official email id to Login/Register ')
                }
            } else {
                setError('Please enter a valid email ')
            }
        }, 200);
    }

    const getActiveScreen = () => {
        switch (screen) {
            case SCREEN_CONSTANTS.EMAIL:
                return <Email
                    email={email}
                    handleEmailChange={(e) => setEmail(e.target.value)}
                    submitEmail={submitEmail}
                    error={error}
                    emailLoading={emailLoading}

                />
            case SCREEN_CONSTANTS.OTP:
                return <OTP
                    navigateToEmailScreen={navigateToEmailScreen}
                    otp={otp}
                    setOtp={setOtp}
                    email={email}
                    submitEmail={submitEmail}
                    newUser={newUser}
                    navigateToPasswordScreen={navigateToPasswordScreen}
                    error={error}
                    formSubmitLoading={formSubmitLoading}
                />
            case SCREEN_CONSTANTS.PASSWORD:
                return <Password
                    error={error}
                    handlePasswordChange={(e) => setPassword(e.target.value)}
                    password={password}
                    navigateToEmailScreen={navigateToEmailScreen}
                    email={email}
                    formSubmitLoading={formSubmitLoading}
                    navigateToOTPScreen={navigateToOTPScreen}
                    setError={setError}
                />
            default: return null
        }
    }

    const handleFormSubmit = (event) => {
        setError('')
        //  setFormSubmitLoading(true)
        if (!otp && screen === SCREEN_CONSTANTS.EMAIL) { // If user uses Enter key in email address
            event.preventDefault();
            submitEmail(event)
        }
    }

    const reDirectUrl = newUser ? `${window.location.origin}/otp-new-login` : `${window.location.origin}/networkFeed/global`


    if (localStorage.getItem('loginFailure')) {
        return null // This is to avoid the intermediate flicker screen of email
    }

    return (
        <div className='lwo-body'>
            <form name='login' onSubmit={handleFormSubmit}
                action={screen === SCREEN_CONSTANTS.PASSWORD ? `${BASE_URL}/auth/login` : `${BASE_URL}/registration/verify`}
                method={screen === SCREEN_CONSTANTS.PASSWORD ? 'POST' : 'GET'} // GET for login using password and POST for OTP login
            >
                <div className='lwo-card'>
                    {getActiveScreen()}
                </div>
                <input type="hidden" name="token" value={otp} disabled={screen === SCREEN_CONSTANTS.PASSWORD} />
                <input type="hidden" name="email" value={email} disabled={screen === SCREEN_CONSTANTS.PASSWORD} />
                <input type="hidden" name="redirect" value={reDirectUrl} disabled={screen === SCREEN_CONSTANTS.PASSWORD} />
                <input type="hidden" name="newUser" value={newUser} disabled={screen === SCREEN_CONSTANTS.PASSWORD} />

                <input type="hidden" name="username" value={email} disabled={screen !== SCREEN_CONSTANTS.PASSWORD} />
                <input type="hidden" name="password" value={password} disabled={screen !== SCREEN_CONSTANTS.PASSWORD} />
            </form>
        </div>
    );
}

const mapStateToProps = ({ auth }) => {
    return { auth };
}

export default connect(mapStateToProps)(LoginWithOtp);
