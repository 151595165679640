import React, { Component } from 'react'
import { Link } from 'react-router-dom'
import { connect } from 'react-redux'
import { bindActionCreators } from 'redux';
import * as actions from '../../actions/'
import { eventActions } from '../../actions';
import { List, Icon, Grid, Card, Button } from 'semantic-ui-react'
import MediaQuery from 'react-responsive'
import './style.css';
import { formatIsoMilisecondsWithoutTime, replaceSpaceInName } from '../../common/helperMethods';
import './rightbar.scss';
import default_image from '../../assets/images/add-event-default.png';


class RightSection extends Component {
    constructor(props) {
        super(props);
        this.state = {
            eventPage: 1
        }
    }

    componentDidMount() {
        this.props.getRecommendedProducts();
    }

    componentDidMount() {
        if (this.props.entityType && this.props.entityId) {
            this.props.getEventsList(this.props.entityType, this.props.entityId);
        }
    }

    componentDidUpdate(prevProps) {
        if (this.props.entityType !== prevProps.entityType || this.props.entityId !== prevProps.entityId) {
            this.props.getEventsList(this.props.entityType, this.props.entityId);
        }
    }

    followProducts(id, name) {
        this.props.followProduct(id, name, () => {
            this.props.getRecommendedProducts();
            this.props.getFollowedProducts('', 'rightsection')
        });
    }
    renderRecommended() {
        if (this.props.product.recommended) {
            return this.props.product.recommended.slice(0, 5).map((item, index) => {
                return (

                    <List.Item key={index} className="rightBarList">
                        <List.Content floated='right' onClick={() => this.followProducts(item.id, item.product)}>
                            <Icon name={'add'} className="icon-mappes-plus icon-mappes" />
                        </List.Content>
                        <List.Content>
                            <Link to={`/product/${replaceSpaceInName(item.product)}/${item.id}`} className="a rightBarListItem">
                                {item.product}
                            </Link>
                        </List.Content>
                    </List.Item>
                )
            })
        }
    }

    getHtmlSubString = (string) => {
        var length = 250;
        var openTag = 0, closeTag = 0, i = 0;
        for (i; i < length; i++) {
            if (string[i] == "<")
                openTag++;
            if (string[i] == ">")
                closeTag++;
        }
        if (openTag > closeTag) {
            while (string[i] != ">")
                i++;
        }

        var newString = string.substring(0, (i + 1));
        return newString + '...';
    }

    stripHtml = (html_string) => {
        if (html_string != undefined) {
            html_string = html_string.replace(/<[^>]*>?/gm, '');
        }
        return html_string;
    }

    substring = (string) => {
        string = this.stripHtml(string);
        string = string.substring(0, 100) + '...';;

        return string;
    }

    render_slider = (eventdata) => {
        const { image, eventName } = eventdata || {};
        if (image != undefined) {
            if (Array.isArray(image) && image.length > 0) {
                return (
                    <div>
                        <img src={image[0]} alt={eventName} title={eventName} onError={({ currentTarget }) => {
                            currentTarget.onerror = null; // prevents looping
                            currentTarget.src = default_image;
                        }} />
                    </div>
                )
            } else if (!Array.isArray(image)) {
                return (
                    <div>
                        <img src={image} alt={eventName} title={eventName} onError={({ currentTarget }) => {
                            currentTarget.onerror = null;
                            currentTarget.src = default_image;
                        }} />
                    </div>
                )
            } else {
                return (
                    <div>
                        <img src={default_image} alt={eventName} title={eventName} />
                    </div>
                )
            }
        } else {
            return (
                <div>
                    <img src={default_image} alt={eventName} title={eventName} />
                </div>
            )
        }
    }

    renderEventsList = () => {
        if (this.props.eventsList) {
            return this.props.eventsList.map((event, index) =>

                <List.Item key={index} className="upcomming-event-item">
                    <List.Content>
                        <Link to={`/events/${replaceSpaceInName(event.eventName)}/${event.id}`}>
                            <Card>
                                <div className='list-inner'>
                                    <div className='left-img'>
                                        {this.render_slider(event)}
                                        {/*  {event.image ? (<img src={event.image}   alt={event.eventName} />) : <img src={default_image}   alt={event.eventName} />} */}
                                    </div>
                                    <div className='dtl-right'>
                                        <div className='upper-dtl'>
                                            <p className='date'>
                                                {// formatIsoMilisecondsWithoutTime(+event.startDate)
                                                    isNaN(event.startDate) ? event.startDate : formatIsoMilisecondsWithoutTime(+event.startDate)
                                                }
                                            </p>
                                            <h3>{event.eventName}</h3>
                                            <p className='location'>{event.location}</p>
                                        </div>
                                        <div className='details-info'>
                                            {event.details != undefined && this.stripHtml(event.details).length > 100 ? this.substring(event.details) : this.stripHtml(event.details)}
                                            <span className='view-event-link'>View event</span>
                                        </div>
                                    </div>
                                </div>
                            </Card>
                        </Link>
                    </List.Content>
                </List.Item>
            )
        }
    }

    render() {
        return (
            <div className="column right-content mt-15px" >
                <Grid.Column className="right-content slim-scroll" >

                    {/* <MediaQuery minWidth={768}> */}
                    {/* <Label className="background-light-blue color-white" >Recommended</Label>
                        based on your network.
                        <List selection verticalAlign='middle'>
                            {this.renderRecommended()}
                        </List> */}
                    {/* </MediaQuery> */}
                    {/* <Divider section /> */}

                    <MediaQuery minWidth={768}>
                        {this.props.auth &&
                            <div className='addevent-card'>
                                <p>
                                    Increase your audience reach and bring your event to a wider audience
                                </p>
                                <Link to="/addevent" className="create-event-button-right">
                                    <Button id="create-event" size="mini" name="AddEvent">Create Event</Button>
                                </Link>
                            </div>
                        }
                        <div className="upcomming-events-list">
                            <div className="upcomming-events-head">
                                Related Events
                            </div>
                            <List selection verticalAlign='middle' className="mT-0">
                                {this.renderEventsList()}
                            </List>
                            {/* {
                                this.props.eventsList &&
                                this.props.eventsList.length > 2 &&
                                // this.props.eventsList.length > this.state.eventPage * 10 &&
                                < a onClick={() => this.setState({ eventPage: this.state.eventPage + 1 })}>See more</a>
                            } */}
                        </div>
                            <p className='viewallevent-bottom'>
                                Are you aware of all the events that are taking place around the world? 
                                <Link to="/event-list">
                                    View all events {">"}
                                </Link>
                            </p>
                    </MediaQuery>
                    {/* <div className='righteventlist-bottom'>
                        <p>
                            Are you aware of all the events that are taking place around the world?
                        </p>
                        <Link to="/">
                            View all events
                            <Icon name='chevron right' />
                        </Link>
                    </div> */}
                    {/* <Divider section className="mT-0" /> */}
                    {/* <List horizontal link className="right-side-footer"> */}
                    {/* <List.Item as='a'>Terms &amp; Conditions</List.Item>        */}
                    {/* <List.Item as='a' href="/aboutus">About Us</List.Item>
                        <List.Item as='a' href="/contact">Contact Us</List.Item> */}
                    {/* <List.Item as='a'/>Privacy Policy</List.Item> */}
                    {/* <List.Item as='a'>FAQ</List.Item>  */}
                    {/* </List> */}
                </Grid.Column>
            </div >
        )
    }
}
function mapStateToProps({ product, events, auth }) {
    return {
        product,
        eventsList: events.eventsList,
        auth
    }
}

function mapDispatchToProps(dispatch) {
    return { ...bindActionCreators({ ...eventActions, ...actions }, dispatch) }
}

export default connect(mapStateToProps, mapDispatchToProps)(RightSection);