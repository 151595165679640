import React from 'react';
import * as actions from '../../../actions/';
import { connect } from 'react-redux';
import { Grid, Header, Image, Form, TextArea } from 'semantic-ui-react';
import PrivacyToolbar from './PrivacyToolbar';
import CustomRickEditor from './NewsRichEditor';

const Name = props => {
    return props.auth ? <div className="profile-name">{props.auth.displayName}</div> : <span className="hide-menu">Loading...</span>;
};

class NewPostEditor extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            text: '',
        };
        this.setWrapperRef = this.setWrapperRef.bind(this);
        this.setPostText = this.setPostText.bind(this);
    }

    componentWillReceiveProps(newProps) {
        if ((newProps.postStatus !== this.props.postStatus && newProps.postStatus === 'created') || newProps.feedType !== this.props.feedType) {
            this.setState({ text: '' });
        }
    }

    setWrapperRef = node => {
        this.wrapperRef = node;
    };

    setPostText(event) {
        let postText = event.target.value;
        this.setState({ text: postText });
        let isYouTubeVideo = postText.indexOf('https://www.youtube.com') > -1;
        if (isYouTubeVideo) {
            this.props.previewPostMedia('youtube', postText);
        } else {
            //hide Previewer
            this.props.previewPostMedia('', '');
        }
        this.props.assemblePost('text', postText);
    }
    
    renderProfileImage() {
        console.log(`this.props`, this.props)
        const { auth, userProfileInfo } = this.props;
        if (userProfileInfo) {
            if (userProfileInfo.image && userProfileInfo.linkedinImage) {
                return (
                    <React.Fragment>
                        <Image
                            className="avatar-42"
                            avatar
                            src={userProfileInfo.image}
                            srcChanged={false}
                            onError={e => {
                                if (!e.target.srcChanged) {
                                    e.target.src = userProfileInfo.linkedinImage;
                                }
                                e.target.srcChanged = true;
                            }}
                        />
                    </React.Fragment>
                );
            } else if (userProfileInfo.image) {
                return (
                    <React.Fragment>
                        <Image className="avatar-42" avatar src={userProfileInfo.image} />
                    </React.Fragment>
                );
            } else if (userProfileInfo.linkedinImage) {
                return (
                    <React.Fragment>
                        <Image className="avatar-42" avatar src={userProfileInfo.linkedinImage} />
                    </React.Fragment>
                );
            }
        }
        return <span className="user-initials">{auth.displayName.split(' ')[0].substring(0, 1)}</span>;
    }
    
    handleEditorText = (value) => {
    /*    this.setState({ text: value });
       console.log(`this.state.text `, this.state.text ) */
        
        this.setState({ text: value });
        let isYouTubeVideo = value.indexOf('https://www.youtube.com') > -1;
        if (isYouTubeVideo) {
            this.props.previewPostMedia('youtube', value);
        } else {
            //hide Previewer
            this.props.previewPostMedia('', '');
        }
        this.props.assemblePost('text', value);
    }
     
    clearInput = () => {
        this.setState({ text: '' });
        this.child.clearInput();
    }
    
    componentDidMount() {
        this.props.onRef(this);
    }
    
    componentWillUnmount() {
        this.props.onRef(undefined);
    }
    
    render() {
        // const { userProfileInfo, displayName } = this.props.auth;
        return (
            <Grid.Column width={16} className="min-43">
                { console.log("props ", this.props) }
                <div className="article-toggle-edit" ref={this.setWrapperRef}>
                    <Header as={'h4'} onClick={this.onClick} className="toggleKey table-heading">
                        Share an article, Photo or Video of a product
                    </Header>
                    <div className="toggleKey mT-5">
                        <div className="d-flex">
                            <div className="user-avtar-image">{this.renderProfileImage()}</div>
                            <Name auth={this.props.auth} />
                        </div>
                        <Form>
                            <CustomRickEditor value={this.state.text} onEditorChange={this.handleEditorText} onRef={ref => (this.child = ref)} toolbar={false} article={false}  />
                        </Form>
                    </div>
                    <PrivacyToolbar assemblePost={this.props.assemblePost} postStatus={this.props.postStatus} />
                </div>
                <div className="border-b"></div>
            </Grid.Column>
        );
    }
}

function mapStateToProps({ auth, user }) {
    return {
        auth,
        userId: user.id,
        followedProducts: user && user.followedProducts,
        userProfileInfo: user.userInfoCollection.find(item => item.id === auth.id),
    };
}

export default connect(
    mapStateToProps,
    actions,
)(NewPostEditor);
