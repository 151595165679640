import React from 'react'
import { Link, withRouter } from 'react-router-dom';
import { Grid, Button, Divider, Card, Image, Icon, Popup, Transition, Feed } from 'semantic-ui-react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { getUserInfo, networkFeedActions, commonActions } from '../../../actions';
import { formatIsoMiliseconds, replaceSpaceInName, feedTimeAgo, articleTimeAgo } from '../../../common/helperMethods';
import { SvgIcon } from '../../common';
import './index.scss';

import MoreArticles from './more_articles';

import ArticleFooterNew from '../post/ArticleFooterNew';

class ArticleDetails extends React.Component {
   constructor(props) {
      super(props)
      this.state = {
         /*  heading: '6 crucial steps in semiconductor manufacturing',
          following: false, */
      }
   }

   renderProfileImage() {
      const { auth, article } = this.props;
       var userInitials = article.user.user_name != '' && article.user.user_name != null ? article.user.user_name.split(" ").map((n) => n[0]).join("") : '';   
      var id = '';
      console.log('this.props ', this.props )
      console.log('article ', article )
      if (article.user && article.user.image) {
         id = article.user.user_id;
         return (
            <React.Fragment>
               <Link to={`/networkFeed/user/${article.user.user_id}`}>
                  <Image className="avatar-42" avatar src={article.user.image} />
               </Link>
            </React.Fragment>
         );
      }
      else if (auth.id == article.user.user_id) {
         id = auth.id;
         if (auth.image){
            return (
               <React.Fragment>
                  <Link to={`/networkFeed/user/${auth.id}`}>
                     <Image className="avatar-42" avatar src={auth.image} />
                  </Link>
               </React.Fragment>
            );
         }
      }  

      return <div className='art-header-img'> <Feed.Label> <Link to={`/networkFeed/user/${id}`}>{userInitials} </Link>  </Feed.Label> </div>;
   }

   render() {
      const { postId, name } = this.props.match.params;
      const { article, history } = this.props;

      if (!name) {
         history.push(`/article/${replaceSpaceInName(article.title)}/${postId}`);
         return null;
      }
      var articleDate = articleTimeAgo(article.added);
      var postDate = feedTimeAgo(article.added);

      var articleHeading = (article.title != undefined && article.title != '') ? article.title : '';
      var articleshortheading = '';
      if (articleHeading == '') { // if no article heading found, extract article body into article text
         articleHeading = (articleHeading != undefined && articleHeading != '') ? article.text.replace(/<\/?[^>]+(>|$)/g, "").trim() : '';
         articleHeading = (articleHeading != undefined && articleHeading != '') ? articleHeading.replaceAll('&nbsp;', '') : '';

         if (articleHeading.length > 70) {
            articleHeading = articleHeading.substr(0, 70)
         }
      }

      if (articleHeading.length > 20) {
         articleshortheading = articleHeading.substr(0, 20)
      }

      var companyName = article.user ? article.user.user_company_name : '';
      var companyId = article.user ? article.user.user_company_id : '';
      const articleMediaType = article.media && Object.keys(article.media)[0];
     
      let imageUrl;
      switch (articleMediaType) {
         case "image":
            imageUrl = (article.media != undefined) ? article.media.image.data : '';
            break;
         default:
            break;
      }
      return (
         <span>
            <div className="article-title">
               <h2>{articleHeading}</h2>
            </div>
            <div className='article-head'>
               <div className='left-col'>
                  <div className='user-image'>
                     {this.renderProfileImage()}
                  </div>
                  <div>
                     <h4>
                        <Link className={'text-black'} to={`/networkFeed/user/${article.user.user_id}`}>
                           {article.user.user_name}
                        </Link>
                     </h4>
                     <p>{articleDate} <Link to={`/company/${replaceSpaceInName(companyName)}/${companyId}`} className="text-secondary link-color" title={companyName}>  |   {companyName}  </Link></p>
                  </div>
               </div>
               {/*  <div className='right-col'>
                  <Button primary size="mini" className="btn-following">
                     <SvgIcon name="following-icon" viewbox="0 0 16.599 16.595" />
                     {following == true ? 'Following' : 'Follow'}
                  </Button>
               </div> */}
            </div>
            <div className='article-information'>
               {imageUrl ? (<img className='article-image' src={imageUrl} alt={articleshortheading} />) : ''}

               <div className="art-body-text" dangerouslySetInnerHTML={{ __html: article.text }}>

               </div>
               <div className="bottom-actions">
                  <ArticleFooterNew {...this.props} />
               </div>
               {/* <MoreArticles {...this.props} /> */}
            </div>
         </span>
      )
   }
}

export default withRouter(ArticleDetails);