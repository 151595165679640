import React from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { Button, Modal, Form, Message, Dropdown } from 'semantic-ui-react';
import { SvgIcon } from '../common';


import { commonActions, productActions } from '../../actions';
import { apiStatus } from '../../common/constants';

class EditProduct extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            open: false,
            addRawButtonLoading: false,
            updatedName: '',
            updatedDescription: ''
        }
    }

    componentDidUpdate() {
        const { updatedName, updatedDescription } = this.state;
        const { name, description, prod_name, prod_description } = this.props;
        // if (name || description) {
        if (prod_name || prod_description) {
            if (updatedName != prod_name && updatedDescription != prod_description) {
                this.setState({
                    updatedName: prod_name,
                    updatedDescription: prod_description
                })
            }
        }

        if (this.props.saveStatus === apiStatus.COMPLETED) {
            setTimeout(() => {
                this.handleClose();
            }, 1200);
        }
    }

    updateProduct = () => {
        /*  this.setState({
             open: false
         }) */
        const { id } = this.props;
        const { updatedName, updatedDescription } = this.state;
        this.props.updateProduct(id, updatedName, updatedDescription);
    }


    formatInput = (event) => {
        const attribute = event.target.getAttribute('name')
        this.setState({ [attribute]: event.target.value.trim() })
    }


    updateDetails = (e) => {
        this.setState({
            [e.target.name]: e.target.value
        })
    }

    handleClose = (e) => {
        if (e !== undefined) {
            e.stopPropagation();
        }
        this.props.setSaveStatus(apiStatus.UNDEFINED);
        this.setState({
            open: false
        })
    }

    render() {
        const { updatedName, updatedDescription } = this.state;
        const { saveStatus, prod_name, prod_description } = this.props;
        var nameLength = (updatedName != '' && updatedName != undefined && updatedName != null) ? updatedName.length : 0;
        return (
            <Modal
                className="createa-supplier"
                onClose={() => this.setState({ open: false })}
                onOpen={() => this.setState({ open: true })}
                open={this.state.open}
                size="small"
                trigger={
                    /* <Button className="edit-btn" basic primary size="mini">{description ? 'Edit' : 'Add'}</Button> */

                    < Dropdown.Item icon={<SvgIcon name="edit" viewbox='0 0 31.5 31.5' />} text={prod_description || prod_name ? ' Edit info' : 'Add info'} />
                }
            >
                <Modal.Content>
                    <Modal.Description>
                        <div className="mb-3"><b>Edit Product</b></div>
                        <Form>
                            <Form.Field>
                                <label>Name</label>
                                <input name="updatedName" onBlur={this.formatInput} placeholder='Generic Product or Technology name' value={updatedName} onChange={this.updateDetails} />
                            </Form.Field>
                            <Form.Field>
                                <label> Description</label>
                                <textarea name="updatedDescription" rows="8" placeholder='Tell us about the product' value={updatedDescription} onChange={this.updateDetails} />
                            </Form.Field>
                            {
                                saveStatus === apiStatus.ALREADY_EXISTS ?
                                    <span>
                                        <div className="text-left mt-2 mb-2">
                                            <Message className="reduce-msg-padding" compact visible error content={`Product Already Exists `} />
                                        </div>
                                    </span>
                                    :
                                    <span></span>
                            }
                            <div className="text-right mt-5">
                                {
                                    saveStatus === apiStatus.COMPLETED ?
                                        <span>
                                            <Message compact visible success content={`Updated  Successfully.`} />
                                            <Button type='submit' onClick={this.handleClose}>Close</Button>
                                        </span>
                                        :
                                        <span>

                                            <Button type='submit' onClick={this.handleClose}>Cancel</Button>
                                            <Button className="ml-1" type='submit' disabled={!nameLength} primary onClick={this.updateProduct} loading={saveStatus === apiStatus.IN_PROGRESS}>Save</Button>
                                        </span>
                                }
                            </div>
                        </Form>
                    </Modal.Description>
                </Modal.Content>
            </Modal>
        )
    }
}

const mapStateToProps = ({ singleProduct, common }) => {
    let productName;
    let productDescription;
    if (singleProduct.info) {
        productName = singleProduct.info.name
        productDescription = singleProduct.info.description
    } else if (singleProduct.info != undefined && singleProduct.info.Item != undefined) {
        productName = singleProduct.info.Item.name;
        productDescription = singleProduct.info.Item.description;
    }
    return {
        name: productName,
        description: productDescription,
        saveStatus: common.saveStatus
    };
}

const mapDispatchToProps = (dispatch) => {
    return {
        dispatch,
        //...bindActionCreators(productActions, dispatch),
        ...bindActionCreators({ ...productActions, ...commonActions }, dispatch),
    }
}
export default connect(mapStateToProps, mapDispatchToProps)(EditProduct);