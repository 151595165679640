import axios from 'axios';
import { OTP_VERIFICATION_COMPLETED } from '../../config/types';
import { BASE_URL } from '../../config/config';

export const verifyOtp = (otp, email, userId) => async (dispatch) => {
    try {
        let res = await axios.get(`${BASE_URL}/registration/verify?email=${email}&token=${otp}&redirect=${window.location.origin}/networkfeed/new`);
        dispatch({ type: OTP_VERIFICATION_COMPLETED, payload: res });
    } catch (err) {
        console.log('err ', err)
    }
}