import React from 'react';
import { Button, Label, Search } from 'semantic-ui-react';
import axios from 'axios';
import { SvgIcon } from '../../common';


import { BASE_URL } from '../../../config/config';

import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { commonActions, productActions } from '../../../actions';
import { apiStatus } from '../../../common/constants';
import { Dropdown } from 'semantic-ui-react';
import AddNewCompany from './AddNewCompany';
import SweetAlerts from '../../common/sweetalerts/sweetalert';
import { toast, ToastContainer } from 'react-toastify';

const resultRenderer = ({ title }) => <Label content={title} />

class AddSuppliers extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            suppliers: [],
            modalOpen: false,
            addRawButtonLoading: false,
            showLoadMore: true,
            condition: false,
            LastEvaluatedKey: props.supplier && props.supplier.LastEvaluatedKey,
            suppliers: [],
            showSupplierLoader: true,
            product_submit: false,

        };
    }

    componentDidUpdate(prevProps) {
        if (prevProps.saveStatus === apiStatus.IN_PROGRESS
            && this.props.saveStatus === apiStatus.COMPLETED) {
            this.setState({ suppliers: [] })
        }
    }

    addSuppliers = () => {
        const { auth, addSuppliers, productId, productName, showLoginModal } = this.props;
        if (!auth) {
            showLoginModal(true);
        }
        else {
            if (this.state.suppliers.length > 0){
                addSuppliers(this.state.suppliers, productId, productName);
            } else {
                console.log('in here')
                toast.error('Please add a supplier to continue !', {
                    autoClose: 3000,
                });
            }
        }
    }

    clearSupplierList = () => {
        this.setState({ suppliers: [] })
    }

    removeSupplier = (supplier) => {
        const index = this.state.suppliers.findIndex(item => item.id === supplier.id);
        const suppliers = [...this.state.suppliers];
        suppliers.splice(index, 1);
        this.setState({ suppliers });
    }

    handleResultSelect = (e, data) => {
        let newSuppliers = this.state.suppliers;
        const selectedSupplier = data.options.find(item => item.value === data.value);
        if (selectedSupplier) {
            newSuppliers.push({
                id: selectedSupplier.value,
                name: selectedSupplier.name
                // country: result._source.country
            });
        }
        this.setState({
            suppliers: newSuppliers,
        });
    };

    handleSearchChange = (e, { searchQuery }) => {
        if (searchQuery) {
            this.setState({ isLoading: true });
            axios.get(`${BASE_URL}/api/v1/company/search/${searchQuery}`, { withCredentials: true }).then(response => {
                const result = response.data && response.data.map(k => {
                    return {
                        key: k._source.id,
                        value: k._source.id,
                        text: k._source.name + '(' + k._source.country + ')',
                        name: k._source.name
                    }
                })
                this.setState({
                    isLoading: false,
                    results: result,
                });
            });
        } else {
            this.setState({ results: [] })
        }
    };

    updateSearchResults = () => {
        let results = [...this.state.results];
        // results.push
        this.setState({
            results
        })
    }

    handleSucess = () => {
        this.setState({ product_submit: true });
    }

    handleCloseAlert = () => {
        this.setState({ product_submit: false })
    }

    render() {
        const { isLoading, results, suppliers, open, product_submit } = this.state;
        const { saveStatus, productId, productName } = this.props;
        console.log('this.props.history ', this.props.history )
        return (
            <span>
                <div>
                    <ToastContainer />
                </div>
                <div className="supplier-head-add mb-3">
                    <div className='supplier-head-add-inner'>
                        <div>
                            <h3>Want to add a supplier for this technology?</h3>
                            <p>Add a supplier you think is missing from the list.</p>
                        </div>
                        <div className="w-100 mt-3 d-flex justify-content-between">
                            <AddNewCompany
                                open={open}
                                handleClose={() => this.setState({ open: false })}
                                productId={productId}
                                productName={productName}
                                addAsSupplier={true}
                                handleCSucess={this.handleSucess}
                                history={this.props.history}
                            />
                             <Dropdown
                                className="selectsupplier-drop"
                                loading={isLoading}
                                selection
                                options={results}
                                fluid
                                search
                                icon='search'
                                placeholder='Search'
                                onSearchChange={this.handleSearchChange}
                                onChange={this.handleResultSelect}
                                allowAdditions
                                value=""
                                searchQuery={undefined}
                                selectOnBlur={false}
                                additionLabel={
                                    <span className="text-primary addnewcompany-link" onClick={() => this.setState({ open: true })}> + Create a New Company </span>
                                }
                                noResultsMessage={null}
                            /> 
                            
                            <Button type='submit' primary onClick={this.addSuppliers} loading={saveStatus === apiStatus.IN_PROGRESS}>Add To List</Button>
                        </div>
                        <div className="w-100 selectedsupplier">
                            {
                                suppliers.map(supplier =>
                                    <span className="badge"><span className="badge-inner">{supplier.name}<SvgIcon name="close" viewbox="0 0 23.126 23.126" onClick={() => this.removeSupplier(supplier)} /></span></span>
                                )
                            }
                        </div>

                        {
                            suppliers.length > 0 &&
                            <div className="text-right mt-3">
                                <Button type='submit' onClick={this.clearSupplierList}>Cancel</Button>
                            </div>
                        }
                    </div>
                    {
                        product_submit && <SweetAlerts type={'S'} title={'Technology info updated!'} message={null} closeAlert={this.handleCloseAlert}> </SweetAlerts>
                    }
                </div>

            </span>

        )
    }
}

const mapStateToProps = ({ singleProduct, common, auth }) => {
    return {
        productName: singleProduct.info && singleProduct.info.Item.name,
        saveStatus: common.saveStatus,
        auth
    };
}

const mapDispatchToProps = (dispatch) => {
    return {
        dispatch,
        ...bindActionCreators({ ...productActions, ...commonActions }, dispatch)
    }
}
export default connect(mapStateToProps, mapDispatchToProps)(AddSuppliers);