import React from 'react';
import axios from 'axios';
import querystring from 'querystring';
import { BASE_URL } from '../../../config/config'
import { Modal, Message, Loader } from 'semantic-ui-react';
import { Link } from 'react-router-dom';
import { SvgIcon } from '../../common';
import './signin.scss';
import {connect} from 'react-redux';

import loginLogo from '../../../assets/images/mappes-logo.png';

class SignInView extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      errors: []
    }
  }
  componentDidMount() {
    const search = (this.props.location!= undefined && this.props.location.search != undefined) ? this.props.location.search : '';
    const params = querystring.parse(search)
    if (params && params.loginerror) {
      this.setState({ open: true, message: { value: "Login failed", type: "error" } });
    }
    this.verifyotp();
  }

  componentDidUpdate() {
    if (this.props.common.showModal && !this.state.open) {
      this.setState({ open: true });
    }
  }

  handleInputChange = (e) => {
    const { name, value } = e.target;
    this.setState({ [name]: value });
    if(name==="username"){
      localStorage.setItem("email", value);
    }
    // this.updateErrors(name, value);
  }

  handleForgotPassword = () => {
    if (!this.state.username) {
      this.setState({ message: { value: 'Please enter your work email', type: 'error' }, showMessage: true, success: false });
      return;
    }
    this.setState({ loading: true });

    axios
      .post(`${BASE_URL}/api/v1/user/password/forgot`, { email: this.state.username }, { withCredentials: false })
      .then(res => {
        this.setState({
          loading: false,
          showMessage: true,
          success: true,
          message: {
            value: 'Please click on the link that has just been sent to your email account to reset your password.',
            type: 'info'
          },
        });
      })
      .catch(err => {
        console.log(err);
        this.setState({ loading: false });
        if (err.response && err.response.data && err.response.data.error) {
          this.setState({ message: { value: err.response.data.error, showMessage: true, success: false, type: 'error' } });
        }
      });
  };
  // login = async (e) => {
  //   e.preventDefault();
  //   const { username, password } = this.state;
  //   const errors = [...this.state.errors];

  //   if (!username) { errors.push("username") }
  //   if (!password) { errors.push("password") }
  //   if (errors.length > 0) {
  //     this.setState({ errors })
  //     return;
  //   }

  //   const data = new FormData();
  //   data.append("username", username);
  //   data.append("password", password);
  //   await axios.post(`${BASE_URL}/auth/login`, querystring.stringify({ username, password }), {
  //     headers: {
  //       'content-type': 'application/x-www-form-urlencoded'
  //     }
  //   });
  //   window.location.reload(true);
  // }

  // updateErrors(name, value) {
  //   let errors = [...this.state.errors];

  //   if (!value) {
  //     errors.push(name);
  //   }
  //   else {
  //     const index = errors.indexOf(name);
  //     if (index > -1) {
  //       errors.splice(index, 1);
  //     }
  //   }

  //   this.setState({ errors });
  // }

  handleClose = () => {
    this.props.showLoginModal(false);
    this.setState({ open: false })
  }

  verifyotp() {

    const { history } = this.props;

    const {userId, regenerateOtp} = this.props.common;
    const email = localStorage.getItem("email");
    if (regenerateOtp && email) {
      axios.post(`${BASE_URL}/api/v2/otp/generate`, { email_id: email, user_id: userId }).then(() => {
        localStorage.setItem("email", "");
        history.push("/verify-otp");
      });
    }
  }

  render() {
    const { message, open, loading } = this.state;
    return (
      <Modal
        closeIcon={< SvgIcon className="modal-close" name="close" viewbox="0 0 23.126 23.126" />}
        open={open}
        trigger={< a className="btn btn-signin" > Sign In</a >}
        onClose={this.handleClose}
        onOpen={() => this.setState({ open: true })}
        className="sign-modal"
        size="tiny"
      >
        <div className="header">
          <img alt={loginLogo} src={loginLogo} />
          <h1>Log in to access your product community</h1>
        </div>
        <Modal.Content>
          <div className="modal-content-inner">
            <form action={BASE_URL + '/auth/login'} method="post">
              <div className="ui input large w-100 form-input mb-2">
                <input
                  className="w-100"
                  type="text"
                  placeholder="Email Address"
                  name="username"
                  onChange={this.handleInputChange}
                  required
                />
              </div>
              <div className="ui input large w-100 form-input mb-2">
                <input className="w-100" type="password" placeholder="Password" name="password" onChange={this.handleInputChange} required />
              </div>
              <div className="mb-4">
                <a className="text-dark" onClick={this.handleForgotPassword}>Forgot your password?</a>
                <Loader active={loading} inline />
              </div>
              {
                this.state.message &&
                <Message info={message.type === "info"} error={message.type === "error"} >
                  {message.value}
                </Message>
              }

              <div className="text-center pt-3">
                {/* <button className="ui primary button w-100 btn-lg" type="submit" onClick={this.login}> */}
                <button className="ui primary button w-100 btn-lg custom-btn" type="submit" >
                  Log in
                </button>
              </div>
              <div className="text-center mt-3 fs-12">
                By continuing, you agree to Mappes<br /> Terms of Service, Privacy Policy
              </div>
              <div className="text-center mt-5">
                <b>Not on Mappes yet? <Link to="/signup">Sign Up</Link></b>
              </div>
            </form>
          </div>
        </Modal.Content>
      </Modal >
    )
  }
}
function mapStateToProps({ common }) {
  return { common };
}

export default connect(mapStateToProps)(SignInView);
