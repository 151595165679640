import axios from 'axios';
import { BASE_URL } from '../config/config';
import { USERINFO } from '../config/types';
import * as networkFeedActions from './networkFeed/networkFeed';
import * as companyActions from './company/company';
import * as productActions from './product/product';
import * as userActions from './user/user';
import * as eventActions from './events/events';
import * as requestQuoteActions from './requestQuote/requestQuote';
import * as NewsListAction from './news/news';
import * as commonActions from './common/common';
import * as authActions from './auth/auth';
import * as registrationActions from './registration/registration';
export * from './auth/auth';
export * from './product/product';
export * from './company/company';
export { networkFeedActions, companyActions, userActions, eventActions, commonActions, authActions, productActions, registrationActions, requestQuoteActions, NewsListAction };

// export const addCompany = (data) => async (dispatch) => {
//     let res = await axios.post(`${BASE_URL}/api/v1/company`,data,{withCredentials:true})
//     dispatch({type: DUMMY, payload : res.data});
// };

export const getUserInfo = (id, cb) => async dispatch => {
    try {

        if (id != undefined) {
            let res = await axios.get(`${BASE_URL}/api/v1/user/${id}`, {
                withCredentials: true
            })
            if (res.data) {
                dispatch({ type: USERINFO, payload: res.data.Item })
            }
        }
    } catch (err) {
        console.log(err);
    }
}

export const getUserProfileInfo = userId => async dispatch => {
    try{
        
        if (userId != undefined && userId!= ""){
            
        }
        let res = await axios.get(`${BASE_URL}/api/v1/user/profile/byId/${userId}`, {
            withCredentials: true
        })
        dispatch({ type: 'USER_PROFILE_INFO', payload: res.data.Item })
    } catch(err){
        console.log(err)
    }
}

export const setNotificationClicked = (dispatch) => {
    return { type: 'NOTIFICATION_CLICKED', payload: true };
}
