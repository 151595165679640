import React, { useEffect, useState } from 'react';

import { Menu } from 'semantic-ui-react';
import { Link } from 'react-router-dom';
import SvgIcon from '../../svg-icon/svg-icon';
import GuestModal from '../../GuestModal';
import MyProductsList from './MyProductsList';
import './BottomCreatePopup.scss'
import MediaQuery from 'react-responsive';

const BottomMenuBar = (props) => {
   const [open, setOpen] = useState(false)
   const [visible, setVisible] = useState(false);
   const [sidebarVisible, setSidebarVisible] = useState(false);

   const Handleclose = () => {
      setOpen(false);
   }

   const tooglePopup = () => {
      if (props.auth) {
         setVisible(!visible);
      } else {
         setOpen(true);

      }
   }

   const toogleSidebar = () => {
      if (props.auth) {
         if (visible) {
            tooglePopup()
         }
         setSidebarVisible(!sidebarVisible);
      } else {
         setOpen(true);
      }
   }

   const onClickNotification = () => {
      if (props.auth) {
         toggle_page('notification')
         setTimeout(() => {
            props.history.push('/notifications')
            props.onClickNotification()
         }, 200);
      } else {
         setOpen(true);
      }
   }

   const check_authentication = (page, link) => {
      if (page == 'event') {
         toggle_page(page)
         setTimeout(() => {
            props.history.push(link)
         }, 200);
      } else {
         if (props.auth) {
            if (page == 'create-post') {
               document.getElementById("create-post-button").click();
               setVisible(!visible);
            } else {
               setTimeout(() => {
                  toggle_page(page)
               }, 100);
               props.history.push(link)
            }
         } else {
            setOpen(true);
         }
      }
   }

   const toggle_page = (page_name) => {
      sessionStorage.setItem('current_page', page_name)
      console.log('sessionStorage ', sessionStorage.current_page)
   }

   return (
      <span>

         <MediaQuery maxWidth={767}>
            <Menu className='footer-menu'>
               <Menu.Item name='home' className={sessionStorage.current_page === 'home' || sessionStorage.current_page === '' ? 'active' : ''} onClick={() => check_authentication('home', '/networkFeed/global/')}>
                  <SvgIcon name='home-icon' viewbox='0 0 20 22' />
                  Home
               </Menu.Item>
               <Menu.Item name='event' className={sessionStorage.current_page === 'event' ? 'active' : ''} onClick={() => check_authentication('event', '/event-list')}>
                  <SvgIcon name='event-icon' viewbox="0 0 22 20" />
                  Events
               </Menu.Item>
               <Menu.Item name='create' onClick={tooglePopup}>
                  <SvgIcon name='create-icon' viewbox="0 0 20 20" />
                  Create
               </Menu.Item>
               {visible ? <div className="overlay-bar-create" onClick={tooglePopup}></div> : null}
               {visible ?
                  <div className="createpopup-bottom">
                     <div className="createpopup-bottom-inner">
                        <button onClick={() => check_authentication('event', '/addevent')}>
                           <SvgIcon name="calendar-icon" viewbox="0 0 17 18.667" />
                           Creat event
                        </button>
                        <button onClick={() => check_authentication('article', '/add-article')}>
                           <SvgIcon name='blog-icon' viewbox="0 0 24 24" />
                           Write article
                        </button>
                        <button onClick={() => check_authentication('create-post', '')}>
                           <SvgIcon name='noimage-alt' viewbox="0 0 35 35" />
                           Create Post
                        </button>
                     </div>
                  </div>
                  :
                  null
               }
               <Menu.Item name='products' className={'myproductlistmobile'} id={'myproductlistmobile'} onClick={toogleSidebar}>
                  <SvgIcon name='myproduct-icon' viewbox="0 0 24 24" />
                  My Products
               </Menu.Item>
               <MyProductsList visible={sidebarVisible} toogleSidebar={toogleSidebar} />
               <Menu.Item name='notification' className={sessionStorage.current_page === 'notification' ? 'active' : ''} onClick={() => onClickNotification()}>
                  <SvgIcon name='bell-icon' viewbox="0 0 20 20" />
                  {props.unreadNotifications && (
                     <div className="notify">
                        {/* <span className="heartbit"></span> */}
                        <span className="point"></span>
                     </div>
                  )}
                  Notifications
               </Menu.Item>
            </Menu>
         </MediaQuery>

         <GuestModal
            open={open}
            handleClose={() => Handleclose()}
         />

      </span>
   );
}

export default BottomMenuBar;