import React from 'react';
import { SvgIcon } from '../../../common';
import './ReactPlayer.scss';
import ReactPlayer from "react-player";
import Duration from './Duration'

class ReactVideoPlayer extends React.Component {

   player = null
   constructor(props) {
      super(props)
      this.state = {
         played: 0,
         duration: 0,
         playing: false,
         muted: false,
         url: props.vurl,
         fullscreen: false,
         seeking: false,
         volume: 0.7,
         last_volume: 0.5
      }
   }
 

   onStopClick() {
      var surl = this.state.url;
      this.setState({ url: null, playing: false })
      setTimeout(() => {
         this.setState({ url: surl })
      }, 10);
   }

   mutePlayer() {
      let mute = false;
      let volume = 0;
      if (this.state.muted == true) {
         mute = false;
         volume = this.state.last_volume;
      } else {
         mute = true;
         volume = 0;
      }
      this.setState({ muted: mute, volume: volume });
   }

   onVideoEnded = () => {
      this.onStopClick();
   }

   playPausePlayer() {
      let play_pause = false;
      if (this.state.playing == true) {
         play_pause = false;
      } else {
         play_pause = true;
      }
      this.setState({ playing: play_pause });
   }

   setFullscreen() {
      let full = false;
      if (this.state.fullscreen == true) {
         full = false;
      } else {
         full = true;
      }
      this.setState({ fullscreen: full });
      const elem = document.getElementById("react-feed-player");
      elem.requestFullscreen();
   }

   handleSeekMouseDown = e => {
      this.setState({ seeking: true })
   }

   handleSeekMouseUp = e => {
      this.setState({ seeking: false })

   }

   handleSeekChange = e => {
      console.log('this.state ', this.state)
      this.setState({ played: parseFloat(e.target.value), loaded: parseFloat(e.target.value) })
      this.player.seekTo(parseFloat(e.target.value));
   }

   handleVolumeChange = e => {
      this.setState({ volume: parseFloat(e.target.value), last_volume: parseFloat(e.target.value) })
   }

   handleProgress = state => {
      // We only want to update time slider if we are not currently seeking
      if (!this.state.seeking) {
         this.setState(state)
      }
   }

   handleDuration = (duration) => {
      this.setState({ duration })
   }

 

   ref = player => {
      this.player = player
   }


   render() {
      return (
         <span>
            <div className="react-feed-player-container">
               <ReactPlayer
                  id="react-feed-player"
                  url={this.state.url}
                  className="react-player"
                  ref={this.ref}
                  volume={this.state.volume}
                  controls={false}
                  playing={this.state.playing}
                  loop={false}
                  muted={this.state.muted}
                  fullscreen={this.state.fullscreen}
                  config={{ file: { attributes: { controlsList: 'nodownload' } }, youtube: { playerVars: { disablekb: 1, control: 0 } } }}
                  onContextMenu={e => e.preventDefault()}
                  onSeek={e => console.log('onSeek', e)}
                  onPlay={() => this.setState({ playing: true })}
                  onPause={() => this.setState({ playing: false })}
                  onProgress={this.handleProgress}
                  onDuration={this.handleDuration}
                  onEnded={() => this.onVideoEnded()}
                  width="100%"
                  height="100%"
               />
               <div className="react-feed-player-control">
                  <input
                     className='player-range'
                     type='range' min={0} max={0.999999} step='any'
                     value={this.state.played}
                     onMouseDown={this.handleSeekMouseDown}
                     onChange={this.handleSeekChange}
                     onMouseUp={this.handleSeekMouseUp}
                  />

                  <div className='action-row'>
                     <div className='action-row-left'>
                        <button className='playicon' onClick={() => this.playPausePlayer()}>{this.state.playing ? <svg width="24" height="30" viewBox="0 0 24 30"><path d="M-223.741-73a.754.754,0,0,1-.759-.75v-28.5a.754.754,0,0,1,.759-.75h5.981a.754.754,0,0,1,.76.75v28.5a.754.754,0,0,1-.76.75Zm-16.5,0a.754.754,0,0,1-.76-.75v-28.5a.754.754,0,0,1,.76-.75h5.981a.754.754,0,0,1,.76.75v28.5a.754.754,0,0,1-.76.75Z" transform="translate(241 103)" /></svg> : <SvgIcon name='play-icon' viewbox='0 0 14 18' />}</button>

                        {/* <button className='stopicon' onClick={() => this.onStopClick()}>
                           <SvgIcon name='stop-icon' viewbox='0 0 19 18' />
                        </button> */}

                        <div className='volume-player'>
                           <button onClick={() => this.mutePlayer()}>{this.state.muted ? <SvgIcon name='mute-icon' viewbox='0 0 23 16' /> : <SvgIcon name='volume-icon' viewbox='0 0 21.998 16.969' />}</button>
                           <input type='range' min={0} max={1} step='any' value={this.state.volume} onChange={this.handleVolumeChange} />
                        </div>

                        <div className='video-time'><Duration seconds={this.state.duration * this.state.played} /> / <Duration seconds={this.state.duration} /></div>

                     </div>

                     <div className='action-row-right'>
                        <button onClick={() => this.setFullscreen()}>
                           <SvgIcon name='fullscreen-icon' viewbox='0 0 20 20' />
                        </button>
                     </div>
                  </div>
               </div>


            </div>
         </span>
      );
   }
}

export default ReactVideoPlayer;