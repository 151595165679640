import axios from 'axios'
import {
    DUMMY,
    ALL_PRODUCTS,
    SINGLE_PRODUCT_INFO,
    SINGLE_PRODUCT_SUPPLIER,
    SINGLE_PRODUCT_ACTIVITY,
    SINGLE_PRODUCT_APPLICATION,
    SINGLE_PRODUCT_RAW_MATERIALS,
    FOLLOWED_PRODUCTS,
    RECOMMENDED_PRODUCTS,
    POTENTIAL_CUSTOMERS,
    PRODUCT_FOLLOWERS,
    RESET_PRODUCT_DATA,
    SET_BULK_PRODUCT_DETAILS
} from '../../config/types'
import { BASE_URL } from '../../config/config'
import { setSaveStatus } from '../common/common';
import { apiStatus } from '../../common/constants';

export const addProduct = (data) => async (dispatch) => {
    try {
        dispatch(setSaveStatus(apiStatus.IN_PROGRESS));
        let response = await axios.post(`${BASE_URL}/api/v1/product`, data, { withCredentials: true })
        if (response.data == 'Product already exist.') {
            dispatch(setSaveStatus(apiStatus.ALREADY_EXISTS));
        } else {
            dispatch(setSaveStatus(apiStatus.COMPLETED));
        }
    } catch (err) {
        console.log('err ', err)
        setTimeout(() => {
            dispatch(setSaveStatus(apiStatus.UNDEFINED));
        }, 2000);

    }
};

export const getAllProducts = () => async (dispatch) => {
    try {
        let res = await axios.get(`${BASE_URL}/api/v1/products/all`, { withCredentials: true })
        dispatch({ type: ALL_PRODUCTS, payload: res.data });
    } catch (err) {
        console.log('err ', err)
    }
};

export const deleteProduct = (id, cb) => async (dispatch) => {
    try {
        let res = await axios.delete(`${BASE_URL}/api/v1/product/${id}`, { withCredentials: true })
        cb();
        dispatch({ type: DUMMY, payload: res.data });
    } catch (err) {
        console.log('err ', err)
    }
};
export const followProduct = (id, name, cb) => async (dispatch) => {
    try {
        let res = await axios.post(`${BASE_URL}/api/v1/product/follow`, { productId: id, productName: name }, { withCredentials: true })
        cb();
        dispatch({ type: DUMMY, payload: res.data });
    } catch (err) {
        console.log('err ', err)
    }
};
export const unfollowProduct = (id, name, cb) => async (dispatch) => {
    try {
        let res = await axios.post(`${BASE_URL}/api/v1/product/unfollow`, { productId: id, productName: name }, { withCredentials: true })
        cb();
        dispatch({ type: DUMMY, payload: res.data });
    } catch (err) {
        console.log('err ', err)
    }
};
export const addStream = (type, parentProductId, childProductId) => async (dispatch) => {
    try {
        let data = {
            type,
            parentProductId,
            childProductId
        }
        let res = await axios.post(`${BASE_URL}/api/v1/product/add/stream`, data, { withCredentials: true })
        dispatch({ type: DUMMY, payload: res.data });
    } catch (err) {
        console.log('err ', err)
    }
};

export const addRawMaterials = (rawMaterials, product_id, product_name) => async (dispatch) => {
    try {
        dispatch(setSaveStatus(apiStatus.IN_PROGRESS));
        let promises = [];
        rawMaterials.forEach(rawMaterial => {
            let data = {
                raw_id: rawMaterial.id,
                raw: rawMaterial.name,
                application_id: product_id,
                application: product_name
            }
            promises.push(axios.post(`${BASE_URL}/api/v1/product/rawMaterial`, data, { withCredentials: true }));
        });

        await axios.all(promises);
        dispatch(getProductRawMaterial(product_id));
        dispatch(setSaveStatus(apiStatus.COMPLETED));
    } catch (err) {
        console.log('err ', err)
    }
};

export const getProductActivity = (id) => async (dispatch) => {
    try {
        let res = await axios.get(`${BASE_URL}/api/v1/product/activity/${id}`, { withCredentials: true })
        dispatch({ type: SINGLE_PRODUCT_ACTIVITY, payload: res.data });
    } catch (err) {
        console.log('err ', err)
    }
};

export const getProductInfo = (id, cb) => async (dispatch) => {
    try {
        let res = await axios.get(`${BASE_URL}/api/v1/product/${id}`, { withCredentials: true })
        dispatch({ type: SINGLE_PRODUCT_INFO, payload: res.data })
    } catch (err) {
        console.log('err ', err)
    }
};

export const getProductSupplier = (id, LastEvaluatedKey, type, pageSize) => async (dispatch) => {
    // let api, res;
    try {
        const api = `${BASE_URL}/api/v2/products/${id}/suppliers`;
        const response = await axios.get(api, { withCredentials: true });
        if (response.data.results.length) {
            dispatch({ type: SINGLE_PRODUCT_SUPPLIER, payload: response.data });
        }
    } catch (err) {
        console.log('err ', err)
    }

    // if (type === "nonPremium") {
    //     if (LastEvaluatedKey) {
    //         api = `${BASE_URL}/api/v2/products/${id}/suppliers?LastEvaluatedKey=${JSON.stringify(LastEvaluatedKey)}&pageSize=${pageSize}`;
    //     }
    //     else {
    //         api = `${BASE_URL}/api/v2/products/${id}/suppliers?pageSize=${pageSize}`;
    //     }
    //     res = await axios.get(api, { withCredentials: true });
    //     dispatch({ type: SINGLE_PRODUCT_SUPPLIER, payload: res.data });
    // }
    // else {
    //     api = `${BASE_URL}/api/v2/products/${id}/suppliers?type=premium`;
    //     const premium = axios.get(api, { withCredentials: true });
    //     const nonPremium = axios.get(`${BASE_URL}/api/v2/products/${id}/suppliers?pageSize=10`, { withCredentials: true });;
    //     premium.then(response => {
    //         if (response.data.results.length) {
    //             res = response;
    //             dispatch({ type: SINGLE_PRODUCT_SUPPLIER, payload: res.data });
    //         }
    //         else {
    //             nonPremium.then(npResponse => {
    //                 res = npResponse;
    //                 dispatch({ type: SINGLE_PRODUCT_SUPPLIER, payload: res.data });
    //             })
    //         }
    //     })
    // }
};


export const getProductApplication = (id) => async (dispatch) => {
    try {
        let res = await axios.get(`${BASE_URL}/api/v1/product/application/${id}`, { withCredentials: true })
        dispatch({ type: SINGLE_PRODUCT_APPLICATION, payload: res.data });
    } catch (err) {
        console.log('err ', err)
    }
};

export const getProductRawMaterial = (id) => async (dispatch) => {
    try {
        let res = await axios.get(`${BASE_URL}/api/v1/product/rawMaterial/${id}`, { withCredentials: true })
        dispatch({ type: SINGLE_PRODUCT_RAW_MATERIALS, payload: res.data });
    } catch (err) {
        console.log('err ', err)
    }
};

export const getFollowedProducts = (id, called_from) => async (dispatch) => {
    try {
        let res = await axios.get(`${BASE_URL}/api/v1/user/Products/followed`, { withCredentials: true })
        dispatch({ type: FOLLOWED_PRODUCTS, payload: res.data });
        
    } catch (error) {
        console.log('error ', error )
    }
};

export const addSupplier = (id, type) => async (dispatch) => {
    try {
        let data = {
            productId: id,
            type
        }
        let res = await axios.post(`${BASE_URL}/api/v1/product/supplier`, data, { withCredentials: true })
        dispatch({ type: 'DUMMY', payload: res.data });
    } catch (err) {
        console.log('err ', err)
    }
};

export const addSuppliers = (suppliers, product_id, product_name) => async (dispatch) => {
    try {
        dispatch(setSaveStatus(apiStatus.IN_PROGRESS));

        let promises = [];
        suppliers.forEach(supplier => {
            let data = {
                company_id: supplier.id,
                company_name: supplier.name,
                product_id,
                product_name,
            };
            promises.push(axios.post(`${BASE_URL}/api/v1/product/supplier`, data, { withCredentials: true }));
        });

        await axios.all(promises);
        dispatch(setSaveStatus(apiStatus.COMPLETED));
        dispatch(getProductSupplier(product_id));
    } catch (err) {
        console.log('err ', err)
    }
};

export const getRecommendedProducts = () => async (dispatch) => {
    try {
        let res = await axios.get(`${BASE_URL}/api/v1/product/recommended/all`, { withCredentials: true })
        dispatch({ type: RECOMMENDED_PRODUCTS, payload: res.data });
    } catch (err) {
        console.log('err ', err)
    }
};

export const getPotentialCustomers = (id) => async (dispatch) => {
    try {
        let res = await axios.get(`${BASE_URL}/api/v1/product/potentialCustomers/${id}`, { withCredentials: true })
        dispatch({ type: POTENTIAL_CUSTOMERS, payload: res.data });
    } catch (err) {
        console.log('err ', err)
    }
};


export const getFollowers = id => async dispatch => {
    try {
        let res = await axios.get(`${BASE_URL}/api/v2/products/${id}/followers`, { withCredentials: true });
        dispatch({ type: PRODUCT_FOLLOWERS, payload: res.data });
    } catch (err) {
        console.log('err ', err)
    }
};


export const addApplications = (applications, product_id, product_name) => async (dispatch) => {
    try {
        dispatch(setSaveStatus(apiStatus.IN_PROGRESS));

        let promises = [];
        applications.forEach(application => {
            let data = {
                application_id: application.id,
                application: application.name,
                raw_id: product_id,
                raw: product_name
            }
            promises.push(axios.post(`${BASE_URL}/api/v1/product/rawMaterial`, data, { withCredentials: true }));
        });

        await axios.all(promises);
        dispatch(setSaveStatus(apiStatus.COMPLETED));
        dispatch(getProductApplication(product_id));
        dispatch(getPotentialCustomers(product_id));
    } catch (err) {
        console.log('err ', err)
    }
};

export const updateProduct = (id, name, description) => async (dispatch) => {
    try {
        
        dispatch(setSaveStatus(apiStatus.IN_PROGRESS));
        let data = {
            name,
            description
        }
    
        const response = await axios.put(`${BASE_URL}/api/v1/product/${id}`, data, { withCredentials: true });
        if (response.data == 'Product already exist.') {
            dispatch(setSaveStatus(apiStatus.ALREADY_EXISTS));
        } else {
            dispatch(setSaveStatus(apiStatus.COMPLETED));
        }
        dispatch(getProductInfo(id));
    } catch (err) {
        console.log('err ', err)
    }
}




export const resetProductData = () => (dispatch) => {
    dispatch({ type: RESET_PRODUCT_DATA });
}

export const uploadProductImage = (data) => async (dispatch) => {
    try {
        dispatch(setSaveStatus(apiStatus.IN_PROGRESS));
        await axios.post(`${BASE_URL}/api/v2/product/upload?type=image`, data, {
            headers: {
                'content-type': 'multipart/form-data'
            }, withCredentials: true
        });
    
        dispatch(setSaveStatus(apiStatus.COMPLETED));
        dispatch(getProductInfo(data.get("product_id")));
    } catch (err) {
        console.log('err ', err)
    }

}

export const getBulkProductDetails = (productIds) => async (dispatch) => {
    try {
        const data = { products: productIds };
        const res = await axios.post(`${BASE_URL}/api/v2/products/bulkproducts`, data, { withCredentials: true });
        dispatch({ type: SET_BULK_PRODUCT_DETAILS, payload: res.data });
    } catch (err) {
        console.log('err ', err)
    }
}