import React, { Component } from 'react';
import Article from './post/Article';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { networkFeedActions } from '../../actions';
import Navbar from '../common/layout/navbar/Navbar';
import { Grid, Container, Loader, Dimmer } from 'semantic-ui-react';
import SEO from '../seo/SEO';

class PostDetails extends Component {
    componentDidMount() {
        this.getPostDetails();
        this.state = {
            articleText : '',
            imageUrl : '',
            videoPlayerLink : '' ,
        }
    }

    componentDidUpdate(prevProps) {
        if (this.props.match.params.postId !== prevProps.match.params.postId) {
            this.getPostDetails()
        }
        
        if (prevProps.postTagged!= undefined && prevProps.postTagged == true){
            this.getPostDetails()
        }
        
        if (prevProps.postStatus != undefined && prevProps.postStatus == "created") {
            this.getPostDetails()
        }
    }

    getPostDetails() {
        const { postId } = this.props.match.params;
        const { getPostDetails, getLikedPosts, auth } = this.props;
        getPostDetails(postId);
       
        auth && getLikedPosts(auth.id);
    }

    render() {
        const { auth, userInfoCollection, networkFeedUsers, removePost, entityId, likedPosts, feedType, postDetails, history } = this.props;
        if (!postDetails) return (
            <span>
                <Dimmer active inverted>
                    <Loader />
                </Dimmer>
            </span>
        )
      
        return (
            <Navbar {...this.props}>
                
                <div className="primary-background">
                    <SEO robots="index, follow" 
                        canonicalUrl={'/feed/post/' + postDetails.Item.id}
                        pageType={'post'}
                        pageContent={postDetails.Item}
                    />
                    <Container className="px-0">
                        <Grid padded stackable>
                            <div className="column main-content-area event-outer-area">
                                <div className=' pD-b-50'>
                                    <div className='main-content_inner'>
                                        <div className='feeds-wrapper'>
                                            <div className="feeds-container">
                                                <div className="ui feed">
                                                    <Article
                                                        article={postDetails.Item}
                                                        auth={auth}
                                                        key={postDetails.id}
                                                        userInfoCollection={userInfoCollection}
                                                        networkFeedUsers={networkFeedUsers}
                                                        removePost={removePost}
                                                        feedType={feedType}
                                                        entityId={entityId}
                                                        likedPosts={likedPosts}
                                                        history={history}
                                                        {...this.props}
                                                    />
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </Grid>
                    </Container>
                </div>
            </Navbar>
        );
    }
}
const mapStateToProps = (state) => {
    return { ...state.networkFeed, ...state.user, ...state.likedPosts, auth: state.auth }
}
const mapDispatchToProps = dispatch => {
    return {
        ...bindActionCreators(networkFeedActions, dispatch)
    }
}
export default connect(mapStateToProps, mapDispatchToProps)(PostDetails);