import React from 'react';
import { Route } from 'react-router-dom'
import { Message } from 'semantic-ui-react';
import { Button } from 'semantic-ui-react';
import { sendInvite } from '../../common/api';
import { emailRegex } from '../../common/constants';
import { withRouter } from 'react-router-dom';

class InviteUser extends React.Component {

    constructor(props) {
        super(props);
        this.state = {}
       
    }
    

    handleInputChange = (e) => {
        this.setState({ email: e.target.value })
    }

    sendInvite = () => { 
        const { email } = this.state;
        if (!email) return;
        if (!emailRegex.test(email.trim())) return;
        

        this.setState({ invitationStatus: "in progress" });
        sendInvite(email).then(() => {
            this.setState({
                invitationMessage: "Thank you for showing your interest in Mappes. A representative from our team will get in touch with you shortly.",
                invitationStatus: "sent"
            })

            setTimeout(() => {
                localStorage.setItem('email', this.state.email)
                 this.props.history.push('/otp-login');
            }, 100);
        }

        );
    }

    render() {
        const { invitationMessage, invitationStatus } = this.state;

        return (
            <span>
                <div className="ui action input invitation-action">
                    <input type="text" placeholder="Email Address" onChange={this.handleInputChange} />
                    <Button primary className="custom-btn text-inherit" onClick={this.sendInvite} loading={invitationStatus == "in progress"}>Join Now !</Button>
                </div>
                {
                    invitationStatus === "sent" && invitationMessage &&
                    <Message positive size="mini">
                        <Message.Header>{invitationMessage}</Message.Header>
                    </Message>
                }
            </span>
        )
    }
}

 
export default withRouter(InviteUser);
