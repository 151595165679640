import React, { useEffect, useState } from 'react';
import { Button, Input, Message, Dimmer, Loader } from 'semantic-ui-react';
import { SvgIcon } from '../../common';
import './index.scss'
import axios from 'axios';
import { BASE_URL } from '../../../config/config';
import { hasLowerCase, hasNumber, hasSpecialCharacters, hasUpperCase } from './util';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { authActions } from '../../../actions';

const LoginWithOtpNewUser = (props) => {
    const [basicDetails, setBasicDetails] = useState({
        first_name: '',
        last_name: ''
    })

    const [passwordDetails, setPasswordDetails] = useState({
        password: '',
        confirmPassword: ''
    })

    const [showPasswordCard, setShowPasswordCard] = useState(false);
    const [showPassword, setShowPassword] = useState(false);
    const [showConfirmPassword, setShowConfirmPassword] = useState(false);
    const [error, setError] = useState('');
    const [loading, setLoading] = useState(false);

    useEffect(() => {
        const userId = localStorage.getItem('userId')
        const email = localStorage.getItem('email')
        if (!userId || !email) {
            props.history.push('/otp-login')
        }
    }, [])

    const goToEmail = () => {
        localStorage.removeItem('userId')
        localStorage.removeItem('newUser')
        localStorage.removeItem('email')
        props.history.push('/otp-login')
    }

    const handleDetailsChange = (event) => {
        const { value, id } = event.target
        setBasicDetails((details) => ({ ...details, [id]: value }))
    }

    const handlePasswordChange = (event) => {
        const { value, id } = event.target
        setPasswordDetails((details) => ({ ...details, [id]: value }))
    }

    const handleMouseDownPassword = (event) => event.preventDefault()

    const saveBasicDetails = () => {
        if (!basicDetails.first_name || !basicDetails.last_name) {
            setError('Please provide your full name, including both your first and last name.')
            return;
        }
        const formData = {
            email_id: localStorage.getItem('email'),
            user_id: localStorage.getItem('userId'),
            first_name: basicDetails.first_name,
            last_name: basicDetails.last_name
        }
        setLoading(true)
        axios.post(`${BASE_URL}/api/user/update`, formData).then(() => {
            props.fetchUser();
            setLoading(false)
            setShowPasswordCard(true);
            setError('')
        }).finally(() => {
            setLoading(false)
        })
    }

    const backToBasicDetailsCard = () => setShowPasswordCard(false);

    const navigateToNewFeed = () => props.history.push('/networkfeed/new')

    const savePasswordDetails = () => {
        const { password, confirmPassword } = passwordDetails
        if (password !== confirmPassword) {
            setError('The password and confirm password do not match ')
            return;
        } else if (password.length < 8 || !hasLowerCase(password) || !hasUpperCase(password) || !hasNumber(password) || !hasSpecialCharacters(password)) {
            setError('Passwords not meeting criteria, please check.')
            return;
        }
        const formData = {
            email_id: localStorage.getItem('email'),
            user_id: localStorage.getItem('userId'),
            password: password,
            confirmPassword: confirmPassword
        }
        setLoading(true)
        axios.post(`${BASE_URL}/api/user/update`, formData).then(() => {
            setLoading(false)
            navigateToNewFeed()
        })
    }

    return (
        <div className='lwo-body'>
            <Dimmer active={loading}>
                <Loader indeterminate>Please Wait.!</Loader>
            </Dimmer>
            <div className='lwo-card'>
                {showPasswordCard ?
                    <div className='lwo-card-inner'>
                        <Button onClick={backToBasicDetailsCard} className='back-btn'><SvgIcon name='chevron-left' viewbox="0 0 8 14" /> Back</Button>
                        <Button className='skip-password' onClick={navigateToNewFeed}>Skip password</Button>
                        <div className='upper-head mt-4 pt-2'>
                            <h1>Setup your password</h1>
                            <p className='text-center mb-0'>You’re almost done! Final step to join the community</p>
                        </div>
                        <div className='form-item'>
                            <label>Enter password</label>
                            <div className='password-form-item'>
                                <Input
                                    type={showPassword ? "text" : "password"}
                                    onChange={handlePasswordChange}
                                    value={passwordDetails.password}
                                    placeholder='**********'
                                    id="password"
                                />
                                <div
                                    onClick={() => setShowPassword((show) => !show)}
                                    onMouseDown={handleMouseDownPassword}
                                >
                                    {showPassword ? <SvgIcon name='password-show' viewbox="0 0 17 12" /> : <SvgIcon name='password-hide' viewbox="0 0 16 14" />}
                                </div>
                            </div>
                        </div>
                        <div className='form-item mt-4'>
                            <label>Retype password</label>
                            <div className='password-form-item'>
                                <Input
                                    type={showConfirmPassword ? "text" : "password"}
                                    onChange={handlePasswordChange}
                                    value={passwordDetails.confirmPassword}
                                    placeholder='**********'
                                    id="confirmPassword"
                                />
                                <div
                                    onClick={() => setShowConfirmPassword((show) => !show)}
                                    onMouseDown={handleMouseDownPassword}
                                >
                                    {showConfirmPassword ? <SvgIcon name='password-show' viewbox="0 0 17 12" /> : <SvgIcon name='password-hide' viewbox="0 0 16 14" />}
                                </div>
                            </div>
                        </div>
                        <div className='password-hint'>
                            <p>must be at least 8 characters</p>
                            <ul>
                                <li className={passwordDetails.password.length >= 8 ? 'active' : ''}>8 characters</li>
                                <li className={hasUpperCase(passwordDetails.password) ? 'active' : ''}>Upper case letter (A-Z)</li>
                                <li className={hasLowerCase(passwordDetails.password) ? 'active' : ''}>Lower case letter (a-z)</li>
                                <li className={hasNumber(passwordDetails.password) ? 'active' : ''}>Numbers (0-9)</li>
                                <li className={hasSpecialCharacters(passwordDetails.password) ? 'active' : ''}>Special character includes (!,@,#,$,%,&)</li>
                            </ul>
                        </div>
                        {error
                            && <Message error>
                                {error}
                            </Message>
                        }
                        <Button
                            className='submit-btn'
                            disabled={!passwordDetails.password || !passwordDetails.confirmPassword}
                            primary
                            onClick={savePasswordDetails}
                            loading={loading}
                        >
                            Continue
                        </Button>
                    </div>
                    :
                    <div className='lwo-card-inner'>
                        <Button className='back-btn' onClick={goToEmail}><SvgIcon name='chevron-left' viewbox="0 0 8 14" /> Back</Button>
                        <div className='upper-head mt-4 pt-2'>
                            <h1>What’s your name?</h1>
                            <p className='text-center mb-0'>How do you like to be called? Please share full name</p>
                        </div>
                        <div className='form-item'>
                            <label>First name</label>
                            <Input placeholder='John' id="first_name" value={basicDetails.first_name} onChange={handleDetailsChange} />
                        </div>
                        <div className='form-item mt-4'>
                            <label>Last name</label>
                            <Input placeholder='Wick' id="last_name" value={basicDetails.last_name} onChange={handleDetailsChange} />
                        </div>
                        {error
                            && <Message error>
                                {error}
                            </Message>
                        }
                        <Button className='submit-btn' primary onClick={saveBasicDetails} loading={loading}>Continue</Button>
                    </div>
                }
            </div>
        </div>
    );
}

function mapDispatchToProps(dispatch) {
    return { ...bindActionCreators({ ...authActions }, dispatch) }
}

export default connect(null, mapDispatchToProps,)(LoginWithOtpNewUser);