import React from 'react';
/* import  ReactDOM from 'react-dom/client'; */
/* import { createRoot } from 'react-dom/client'; */
import { createRoot } from "react-dom/client";
import 'semantic-ui-css/semantic.min.css';
import { Provider } from 'react-redux';
import { createStore, applyMiddleware } from 'redux'
import { thunk } from 'redux-thunk'
// import * as Sentry from "@sentry/react";
import { BrowserTracing } from "@sentry/tracing";
import './index.css';
import App from './components/App';
import reducers from './reducers'


var env = '';
/* var senetry_init = true;
const hostname = window && window.location && window.location.hostname;
if (hostname === 'mappes.io' || hostname === 'www.mappes.io') {
    env = 'production';
    senetry_init = true;
} else if (hostname === 'staging.mappes.io') {
    env = 'staging';
    senetry_init = true;
} else if (hostname === 'devapp.mappes.io') {
    env = 'dev';
    senetry_init = false;
} else {
    env = 'dev';
    senetry_init = false;
}
if (senetry_init) {
    Sentry.init({
        dsn: "https://500a06b466df4f0db5499515559f25bc@o1403183.ingest.sentry.io/6768802",
        integrations: [new BrowserTracing()],
        tracesSampleRate: 1.0,
        environment: env
    });
} */
const store = createStore(reducers, {}, applyMiddleware(thunk));
const REACT_VERSION = React.version;
console.log('REACT_VERSION ', REACT_VERSION )
const rootElement = document.getElementById("root");
const root = createRoot(rootElement);
root.render(<Provider store={store}><App /></Provider>);