import React from 'react'
import { Grid, Button, Divider, Card, Image, Icon, Popup, Transition, Feed } from 'semantic-ui-react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { SvgIcon } from '../../common';
import './index.scss';
import ArticleBlankImg from '../../../assets/images/blank-img.png';

class MoreArticles extends React.Component {
   constructor(props) {
      super(props)
      this.state = {
         
      }
   }

   render() {
      const { article } = this.props
      return (
         <span>
            <div className='more-articles'>
               <h3 className='title'>More from {article.user.user_name}</h3>
               <ul>
                  <li>
                     <Card>
                        <Image src={ArticleBlankImg}   />
                        <Card.Content>
                           <Card.Description>
                              Semiconductor
                           </Card.Description>
                           <Card.Header>The steps in semiconductor title text place holder</Card.Header>
                           <Card.Meta>
                              <span className='date'>Published on 2015</span>
                              <span className='time'>5mins read</span>
                           </Card.Meta>
                        </Card.Content>
                     </Card>
                  </li>
                  <li>
                     <Card>
                        <Image src={ArticleBlankImg}   />
                        <Card.Content>
                           <Card.Description>
                              Semiconductor
                           </Card.Description>
                           <Card.Header>The steps in semiconductor title text place holder</Card.Header>
                           <Card.Meta>
                              <span className='date'>Published on 2015</span>
                              <span className='time'>5mins read</span>
                           </Card.Meta>
                        </Card.Content>
                     </Card>
                  </li>
                  <li>
                     <Card>
                        <Image src={ArticleBlankImg}   />
                        <Card.Content>
                           <Card.Description>
                              Semiconductor
                           </Card.Description>
                           <Card.Header>The steps in semiconductor title text place holder</Card.Header>
                           <Card.Meta>
                              <span className='date'>Published on 2015</span>
                              <span className='time'>5mins read</span>
                           </Card.Meta>
                        </Card.Content>
                     </Card>
                  </li>
               </ul>
            </div>
         </span>
      )
   }
}

export default MoreArticles;