import React, { Component } from 'react'
import { Link } from 'react-router-dom'
import { Accordion, Icon } from 'semantic-ui-react'
import { replaceSpaceInName } from '../../../common/helperMethods'
import { SvgIcon } from '../../common'
import AddRawMaterials from './AddRawMaterials'

export default class RawMaterials extends Component {
    state = { activeIndex: 0 }

    handleClick = (e, titleProps) => {
        const { index } = titleProps
        const { activeIndex } = this.state
        const newIndex = activeIndex === index ? -1 : index

        this.setState({ activeIndex: newIndex })
    }

    render() {
        const { activeIndex } = this.state;
        const { rawMaterials } = this.props;

        return (
            <Accordion className="rawmaterial-list" styled fluid>
                {
                    rawMaterials && rawMaterials.map((item, index) =>
                        <span>
                            <Accordion.Title
                                active={activeIndex === index}
                                index={index}
                                onClick={this.handleClick}
                            >
                                <Link to={`/product/${replaceSpaceInName(item.rawMaterial_title)}/${item.rawMaterial}`}>{item.rawMaterial_title}</Link>
                                <div>
                                    {item.supplierCount > 0 && <span>{item.supplierCount} Suppliers</span>}
                                    <SvgIcon name="menu-arrow" viewbox="0 0 23.619 13.503" />
                                </div>
                            </Accordion.Title>
                            <Accordion.Content active={activeIndex === index}>
                                <p>{item.description}</p>
                            </Accordion.Content>
                        </span>
                    )
                }
            </Accordion>
        )
    }
}