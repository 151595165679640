import React, { Component } from 'react';
// import * as actions from '../../actions/';
import { connect } from 'react-redux';
import { Link, withRouter } from 'react-router-dom';
import { bindActionCreators } from 'redux';
import { Transition, List, Grid, Image, Popup } from 'semantic-ui-react';
import { userActions, productActions, getUserInfo, authActions } from '../../actions/';
import { Scrollbars } from 'react-custom-scrollbars';
import { GuestSliderCard } from '../common';
import Slider from "react-slick";
// import { CircularProgressbar, buildStyles } from 'react-circular-progressbar';
// import 'react-circular-progressbar/dist/styles.css';
import { CircularProgressbar, buildStyles } from 'react-circular-progressbar';
import 'react-circular-progressbar/dist/styles.css';
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import '../common/layout/leftbar/leftbar.scss';

// import images
import Slide1 from '../../assets/images/leftbar/do-you-manufacture.png';
import Slide2 from '../../assets/images/leftbar/employee.png';
import Slide3 from '../../assets/images/leftbar/event.png';
import Slide4 from '../../assets/images/leftbar/exhibiting.png';
import Slide5 from '../../assets/images/leftbar/promote.png';
import Slide6 from '../../assets/images/leftbar/source.png';
import Slide7 from '../../assets/images/leftbar/want-updates-company.png';
import SvgIcon from './svg-icon/svg-icon';
import { replaceSpaceInName } from '../../common/helperMethods';
import News from '../News/News';

const ProductSlide = [
    { key: 1, image: Slide1, title: 'Do you manufacture or supply this product?', content: 'Discover and Network with the right buyers of your product from all around the world.', button_name: 'List your company' },
    { key: 2, image: Slide6, title: 'Want to source this product? ', content: 'Discover and connect with global suppliers of this product ', button_name: 'Join Now' },
    { key: 3, image: Slide7, title: 'Want updates about this product/technology?', content: 'FOLLOW this product and be part of the technology focussed ecosystem and community.', button_name: 'Join now' }
]

const CompanySlide = [
    { key: 1, image: Slide7, title: 'Want updates about this company? ', content: 'FOLLOW this company to stay updated about their products and technology.  ', button_name: 'Follow this Company' },
    { key: 2, image: Slide2, title: 'Are you an employee of this company?', content: 'Post your product information to educate your potential customers.', button_name: 'Post Updates' },
    { key: 3, image: Slide5, title: 'Want to promote your products and company?', content: 'Be part of your technology ecosystem and educate the industry about your solutions.', button_name: 'List your company' }
]

const EventSlide = [
    { key: 1, image: Slide3, title: 'Are you attending this event?', content: 'Connect with all the exhibitors and understand their products before the show.', button_name: 'Join Now' },
    { key: 2, image: Slide4, title: 'Are you exhibiting at this event?', content: 'Post and promote your product and company before the event. Start educating your potential customers now.', button_name: 'List your company' },
]

class LeftSidebar extends Component {
    state = {
        visible: false,
        visibleProducts: false,
        profilePic: '',
        visibleConnections: false,
        visibleCompanyInfo: false,
        visibleMyConnections: false,
    };


    toggleVisibility = () => this.setState({ visible: !this.state.visible });
    toggleProductVisibility = () => this.setState({ visibleProducts: !this.state.visibleProducts });
    toggleConnectionsVisibility = () => this.setState({ visibleConnections: !this.state.visibleConnections });
    toggleCompanyInfoVisibility = () => this.setState({ visibleCompanyInfo: !this.state.visibleCompanyInfo });
    toggleMyConnectionsVisibility = () => this.setState({ visibleMyConnections: !this.state.visibleMyConnections });

    renderName() {
        if (this.props.auth) {
            return (
                <span className="hide-menu l-h-20">
                    <Link id="user-name" name="user-name" to={`/networkFeed/user/${this.props.auth.id}`} >
                        {this.props.auth.displayName.split(' ')[0]} {this.props.auth.displayName.split(' ')[1]}
                    </Link>
                    <span className="fa arrow"></span>
                </span>
            );
        } else {
            return (
                <span className="hide-menu">
                    Loading...<span className="fa arrow"></span>
                </span>
            );
        }
    }
    renderProfileImage() {
        const handleImageChange = (event) => {
            const file = event.target.files[0];
            this.props.uploadUserImage(file)
        }
        const { userProfileInfo } = this.props;
        let userImage;
        if (userProfileInfo) {
            if (userProfileInfo.image && userProfileInfo.linkedinImage) {
                return (
                    userImage = <Image
                        className="avatar-42"
                        avatar
                        src={userProfileInfo.image}
                        srcChanged={false}
                        onError={e => {
                            if (!e.target.srcChanged) {
                                e.target.src = userProfileInfo.linkedinImage;
                            }
                            e.target.srcChanged = true;
                        }}
                    />
                );
            } else if (userProfileInfo.image) {
                userImage = <Image className="avatar-42" avatar src={userProfileInfo.image} />;
            } else if (userProfileInfo.linkedinImage) {
                userImage = <Image className="avatar-42" avatar src={userProfileInfo.linkedinImage} />;
            }
            else {
                // userImage = <span className="user-initials" className="text-white"><Icon name='camera' /></span>;
                userImage = <div className="user-initials text-white"><div className='user-initials-inner'><SvgIcon name='camera-icon-alt' viewBox="0 0 16 14" /></div></div>
            }
        }
        return <div id="user-profile-image">
            <label htmlFor='profile-photo'>Change Photo</label>
            <input id="profile-photo" type="file" onChange={handleImageChange} style={{ display: 'none', height: 0, width: 0 }} />
            {userImage}
        </div>
    }
    renderFollowedProducts() {
        const { followedProducts } = this.props;
        if (followedProducts && followedProducts.Items.length > 0) {
            return followedProducts.Items.map((item, index) => (
                <List.Item key={index}>
                    <List.Content>
                        <Link to={`/product/${replaceSpaceInName(item.product_name)}/${item.product_id}`}>
                            <span>{item.product_name}</span>
                        </Link>
                    </List.Content>
                </List.Item>
            ));
        } else {
            return (
                <div>
                    <h4>You are not following any products</h4>
                </div>
            );
        }
    }

    componentDidMount() {
        if (this.props.auth) {
            // will load only if user is logged in 
            this.props.getFollowedProducts('', 'lsb');
            this.props.getUserProfileDetails();
            this.props.fetchUser();
            // will load only if user is logged in 
            // if (this.props.auth) {
            this.getUserPicture(this.props.auth);
            this.props.getVendorsCount();
            this.props.getConnectionsCount();
            this.props.getCustomersCount();
        }
    }
    componentWillReceiveProps(nextProps) {
        if (!this.props.auth && nextProps && nextProps.auth) {
            this.getUserPicture(nextProps.auth);
        }
    }
    getUserPicture(user) {
        if (user) {
            this.props.getUserInfo(user.id);
        }
    }

    renderLeftPanel(settings) {
        const { match, history } = this.props;
        const page = match.url.split('/')[1]
        const goToSignUp = () => {
            history.push('/otp-login')
        }
        switch (page) {
            case 'feed':
            case 'product': return (
                <Scrollbars className='guestscroll-left' style={{ height: "calc(100vh - 60px)", overflow: 'hidden' }}>
                    <Slider {...settings} className="left-guest-slider">
                        {
                            ProductSlide.map((item, i) =>
                                <GuestSliderCard
                                    key={i}
                                    image={item.image}
                                    title={item.title}
                                    content={item.content}
                                    button_name={item.button_name}
                                    handleButtonClick={goToSignUp}
                                />)
                        }
                    </Slider>
                </Scrollbars>
            )
            case 'company': return (
                <Scrollbars className='guestscroll-left' style={{ height: "calc(100vh - 60px)", overflow: 'hidden' }}>
                    <Slider {...settings} className="left-guest-slider">
                        {
                            CompanySlide.map((item, i) =>
                                <GuestSliderCard
                                    key={i}
                                    image={item.image}
                                    title={item.title}
                                    content={item.content}
                                    button_name={item.button_name}
                                    handleButtonClick={goToSignUp}
                                />)
                        }
                    </Slider>
                </Scrollbars>
            )
            case 'events': return (
                <Scrollbars className='guestscroll-left' style={{ height: "calc(100vh - 60px)", overflow: 'hidden' }}>
                    <Slider {...settings} className="left-guest-slider">
                        {
                            EventSlide.map((item, i) =>
                                <GuestSliderCard
                                    key={i}
                                    image={item.image}
                                    title={item.title}
                                    content={item.content}
                                    button_name={item.button_name}
                                    handleButtonClick={goToSignUp}
                                />)
                        }
                    </Slider>
                </Scrollbars>
            )
            default: return null;
        }
    }

    getProfileCompletionPercentage = () => {
        const { userProfileDetails } = this.props;
        if (!userProfileDetails) return 0;
        const totalItems = 8;
        let completedItems = 0;
        if (userProfileDetails.image) completedItems++;
        if (userProfileDetails.firstName) completedItems++;
        if (userProfileDetails.lastName) completedItems++;
        if (userProfileDetails.company) completedItems++;
        if (userProfileDetails.country) completedItems++;
        if (userProfileDetails.designation) completedItems++;
        if (userProfileDetails.primaryEmail) completedItems++;
        if (userProfileDetails.phone) completedItems++;
        console.log('completedItems ', completedItems )
        return (completedItems / totalItems) * 100;
    }

    render() {
        const { auth, userProfileDetails, vendorsCount = 0, connectionsCount, customersCount = 0 } = this.props;
        const isPaidUser = auth && auth.isActive;
        const settings = {
            dots: true,
            infinite: true,
            className: "center",
            slidesToShow: 1,
            slidesToScroll: 1,
            autoplay: true,
            autoplaySpeed: 2500
        };

        if (!auth) {
            return this.renderLeftPanel(settings);
        }

        const percentage = Math.round(this.getProfileCompletionPercentage());
        return (
            <Grid.Column className="left-content mt-15px">
                <div className='leftbar-inner' style={{ height: "calc(100vh - 70px)" }}>
                    <div className="leftuserbox user-info-card">
                        <div className="card-inner">
                            <div className="d-flex align-items-center justify-content-between heads">
                                {/* <small className="mb-0"> {isPaidUser ? "Premium User" : "Free User"}</small> */}
                                {/* {
                                    !isPaidUser && <b><Link to="/upgrade">Upgrade</Link></b>
                                } */}
                            </div>
                            <div className="image">
                                {this.renderProfileImage()}
                            </div>
                            <div className="content userprofile-leftdtl">
                                {/* <div className="header">Michelle Smith</div> */}
                                <div className="header">
                                    <div className='names'>
                                        {this.renderName()}
                                        <Link to="/profile">
                                            <SvgIcon className='edit-link' name='edit' viewbox='0 0 31.5 31.5' />
                                        </Link>
                                    </div>
                                    <div className="meta">
                                        {/* <p>
                                                {
                                                    userProfileDetails ? userProfileDetails.designation
                                                        :
                                                        <Link to="/profile" name="user-designation">Add designation</Link>
                                                }
                                            </p> */}
                                        <p>
                                            {
                                                userProfileDetails ?
                                                    <Link to={`/company/${replaceSpaceInName(userProfileDetails.company)}/${userProfileDetails.companyId}`}>{userProfileDetails.company}</Link>
                                                    :
                                                    <Link to="/profile">Add Company</Link>
                                            }
                                        </p>
                                    </div>
                                </div>
                                <div className="extra">
                                    <Link id="connections" to="/connections">
                                        Network Connections
                                    </Link>
                                    <span><b>{connectionsCount}</b></span>
                                </div>

                                {percentage < 100 &&
                                    <span>
                                        <h3 className='completeprofile-title'>Complete Your Profile</h3>
                                        <div className='profile-progress'>
                                            <div className='progreesbar'>
                                                <CircularProgressbar
                                                    styles={buildStyles({
                                                        rotation: 0.25,
                                                        width: '44px',
                                                        textSize: '20px',
                                                        fontWeight: '600',
                                                        pathTransitionDuration: 0.5,
                                                        pathColor: `#2166EE`,
                                                        textColor: '#243B53',
                                                        trailColor: '#DBE2EB',
                                                    })}
                                                    value={percentage} text={`${percentage}%`} />
                                            </div>
                                            <div className='right-content'>
                                                Build quality network to explore opportunities by providing <Link to='/profile'>additional details</Link>.
                                            </div>
                                        </div>
                                    </span>
                                }
                            </div>
                        </div>
                    </div>
                    {/* <div className="leftuserbox productsleft-box second-box network-card mt-2">
                        <div className="ui items">
                            <div className="item">
                                <div className="content">
                                    <List selection verticalAlign="middle" className="inner-list my-product-list leftbar-following-list">
                                        <List.Item>
                                            <List.Content className="full-width">
                                                <Link className='px-3 py-3' to="/networkFeed/global"><List.Icon name="list" verticalAlign="middle"></List.Icon> Network Feed</Link>
                                            </List.Content>
                                        </List.Item>
                                    </List>
                                </div>
                            </div>
                        </div>
                    </div> */}
                    <div className="leftuserbox group-card connect-left-card">
                        <div className="ui items">
                            <div className="item">
                                <div className="content mt-0">
                                    <List className="m-0" selection verticalAlign="middle">
                                        <Popup
                                            inverted
                                            trigger={
                                                <List.Item onClick={this.toggleMyConnectionsVisibility} className="m-0 py-0 px-2">
                                                    <div className="header header2">My Connections <SvgIcon name='menu-arrow' viewbox='0 0 23.619 13.503' /></div>
                                                </List.Item>
                                            }
                                            content="My Connections"
                                            position="top center"
                                        />
                                        <Transition visible={this.state.visibleMyConnections} animation={'fade'} duration={500}>
                                            {/* <Scrollbars style={{ height: "60px" }}> */}
                                            <List verticalAlign="middle" className="inner-list my-product-list leftbar-following-list">
                                                <List.Item key="1">
                                                    <Link to="/vendors">
                                                        <div className="meta-rows">
                                                            Vendors
                                                            <span>{vendorsCount}</span>
                                                        </div>
                                                    </Link>
                                                </List.Item>
                                                <List.Item key="2">
                                                    <Link to="/customers">
                                                        <div className="meta-rows">
                                                            Customers
                                                            <span>{customersCount}</span>
                                                        </div>
                                                    </Link>
                                                </List.Item>
                                            </List>
                                            {/* </Scrollbars> */}
                                        </Transition>
                                    </List>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div className="leftuserbox group-card follow-product-card">
                        <div className="ui items">
                            <div className="item">
                                <div className="content">
                                    <List selection verticalAlign="middle">
                                        <Popup
                                            inverted
                                            trigger={
                                                <List.Item onClick={this.toggleProductVisibility} className="m-0 py-0 px-2">
                                                    <List.Content className="full-width">
                                                        <div className="header header2">Technologies I Follow <SvgIcon name='menu-arrow' viewbox='0 0 23.619 13.503' /> </div>
                                                    </List.Content>
                                                </List.Item>
                                            }
                                            content="Technologies I Follow"
                                            position="top center"
                                        />
                                        <Transition visible={this.state.visibleProducts} animation={'fade'} duration={500}>
                                            {/* <Scrollbars style={{ height: "calc(100vh - 550px)" }}> */}
                                            <List verticalAlign="middle" className="inner-list my-product-list slim-scroll leftbar-following-list">
                                                {this.props.followedProducts && this.renderFollowedProducts()}
                                            </List>
                                            {/* </Scrollbars> */}
                                        </Transition>
                                    </List>
                                </div>
                            </div>
                        </div>
                    </div>
                    <News />
                    {/* <div className="leftuserbox productsleft-box">
                    <div className="ui items">
                        <div className="item">
                            <div className="content">                              
                                <div className="followproduct-leftox">
                                    <h3>Follow the product and start receiving alerts and updates in your inbox.</h3>
                                    <Button primary fluid className="mt-1 custom-btn text-inherit px-2">Request a quote</Button>
                                     <Button primary fluid className="mt-2 basic custom-btn  text-inherit px-2">Sign In</Button>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="leftuserbox startnow-leftbox mt-4">
                    <div className="ui items">
                        <div className="item">
                        <div className="header">SIGN IN</div>
                            <div className="content">                              
                                <div className="followproduct-leftox">
                                    <p>Follow the product and start receiving alerts and updates in your inbox.</p>
                                    <Link to="/">Start Now</Link>
                                </div>
                            </div>
                        </div>
                    </div>
                </div> */}
                </div>
            </Grid.Column>
        );
    }
}

function mapStateToProps({ auth, user }) {
    return {
        auth,
        userId: user.id,
        followedProducts: user && user.followedProducts,
        userProfileInfo: user.userInfoCollection.find(item => item.id === auth.id),
        userProfileDetails: user.userProfileDetails,
        vendorsCount: user.vendorsCount,
        connectionsCount: user.connectionsCount,
        customersCount: user.customersCount
    };
}

function mapDispatchToProps(dispatch) {
    return { ...bindActionCreators({ ...productActions, ...userActions, ...authActions, getUserInfo }, dispatch) }
}

export default connect(
    mapStateToProps,
    mapDispatchToProps,
)(withRouter(LeftSidebar));
