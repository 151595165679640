import React from 'react'
import { Grid, Container } from 'semantic-ui-react';
import MediaQuery from 'react-responsive'
import RightSection from '../RightSection';
import Navbar from './navbar/Navbar';
import LeftSidebar from '../LeftSidebar';

const Layout = (props) => {
    return (
        <Navbar {...props}>
            <div id="HEADER" className="primary-background">
                <Container className="body-background palm-nudge-sides">
                    <Grid padded stackable>
                        <MediaQuery minWidth={768}>
                            <Grid.Column className="left-content">
                                <LeftSidebar />
                            </Grid.Column>
                        </MediaQuery>

                        <Grid.Column className="main-content-area">
                            <div className="main-content pD-b-50">
                                <div className="main-content_inner">
                                    {props.children}
                                </div>
                            </div>
                        </Grid.Column>
                        <RightSection entityType={props.entityType} entityId={props.entityId} />
                    </Grid>
                </Container>
            </div>
        </Navbar>
    );
}

export default Layout;