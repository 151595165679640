import React, { useState, useRef, useEffect } from 'react';
import './index.scss'; 

const MappesDropdown = ({ buttonLabel, children, width, overlayClass }) => {
  const [isOpen, setIsOpen] = useState(false);
  const dropdownRef = useRef(null);

  const toggleDropdown = (e) => {
    setIsOpen((prev) => !prev);
    e.stopPropagation();
  };

  const handleClickOutside = (event) => {
    if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
      setIsOpen(false);
    }
  };

  useEffect(() => {
    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, []);

  return (
    <div className="mappes-dropdown" ref={dropdownRef}>
      <button onClick={toggleDropdown}  className="mappes-dropdown-trigger">
        {buttonLabel}
      </button>
      <div className={`mappes-dropdown-overlay ${overlayClass} ${isOpen === true ? 'show-drop' : 'hide-drop'}`} style={{ width: `${width}px` }} onClick={() => setIsOpen(false)}>
        {children}
      </div>
    </div>
  );
};

export default MappesDropdown;