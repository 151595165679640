import React from 'react';
import { Link } from 'react-router-dom'
import { Image, Embed, Button, TransitionablePortal, Modal, Feed } from 'semantic-ui-react';

import Microlink from '@microlink/react'
import { helperMethods, constants } from '../../../common'
import TaggedItems from './TaggedItems';
import ReactVideoPlayer from './react-player/ReactPlayer';
import { BASE_URL } from '../../../config/config';
import ShowMoreLess from '../showMoreLess';
import { replaceSpaceInName } from '../../../common/helperMethods';


class ArticleBodyNew extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            showPpt: false,
            showPdf: false
        }
    }

    showPdf = () => {
        const { showPdf } = this.state;
        this.setState({ showPdf: !showPdf })
    }

    showPpt = () => {
        const { showPpt } = this.state;
        this.setState({ showPpt: !showPpt })
    }

    handleOpen = () => {
        this.setState({ showPdf: !this.state.showPdf })
    }
    
    onTruncate = (ev) => {
        console.log('ev ', ev )
    }

    show_post_article(type, articleText, title, id, imageUrl) {
        if (type == 'post' || type == 'news') {
            return (
                <div>
                {/* <ShowMoreText
                    lines={3}
                    children={'React Node'}
                    more="Show more"
                    less="Show less"
                    className="content-css feed-text-only"
                    anchorClass="my-anchor-css-class"
                    onClick={this.executeOnClick}
                    expanded={false}
                    width={800}
                    truncatedEndingComponent={"... "}
                    onTruncate={() =>  this.onTruncate } >
                    <div className="art-body-text" dangerouslySetInnerHTML={{ __html: articleText }}></div>
                </ShowMoreText> */}
                <ShowMoreLess content={articleText}  ></ShowMoreLess>
                </div>
            );
        }

        if (type == 'article') {
            let shortalt = '';
            articleText = (articleText != undefined && articleText != '') ? articleText.replace(/<\/?[^>]+(>|$)/g, "").trim() : '';
            articleText = (articleText != undefined && articleText != '') ? articleText.replaceAll('&nbsp;', '') : '';
            if (articleText.length > 150) {
                articleText = articleText.substr(0, 150) + '.... ';
            }
            var articleUrl = `${window.location.origin}/article/${replaceSpaceInName(title)}/${id}`;
            if (title!= ''){
                shortalt = title.substr(0, 20);
            } else {
                shortalt = articleText.substr(0, 20);
            }
            return (
                <React.Fragment>
                    {/* <TaggedItems taggedItems={tagged_product_names} type="product" delimiter="#" />
                    <TaggedItems taggedItems={tagged_company_names} type="company" delimiter="@" /> */}
                    <div className="images extra withimage-dtl"><a href={articleUrl} target="_blank" className="sc-jDwBTQ " title={title} >
                        <div className="sc-htoDjs sc-dnqmqq kUyiLB microlink_card__media microlink_card__media_image">
                            {imageUrl  && <Image src={imageUrl} alt={shortalt} /> } 
                        </div>
                        <div className="feed-text-content">
                            {title  && <header><p title={title} className="dtl-title">{title}</p></header>}
                            <div className="content_description" >
                                <p title={articleText}>{articleText}</p>
                            </div>
                            <footer className="content-footer">
                                <p  title={articleUrl}>{articleUrl}</p>
                            </footer>
                        </div>
                    </a>
                    </div>
                </React.Fragment>
            )
        }

        if (type == 'news') {
        }
    }


    render() {
        const { id, title, imageContent, media, text, tagged_product_names, tagged_company_names, type } = this.props.article;
        const articleMediaType = media && Object.keys(media)[0];

        let imageUrl, videoUrl, pdfUrl, youtubeVideoId, vimeoUrlLink, articleUrl, ppturl;
        let videoPlayerLink = '';
        switch (articleMediaType) {
            case "image":
                imageUrl = media.image.data;
                break;
            case "video":
                videoUrl = media.video.data;
                break;
            case "pdf":
                pdfUrl = media.pdf.data;
                break;
            case "ppt":
                ppturl = media.ppt.data;
                break;
            default:
                break;
        }

        let articleText = text;
        if (articleText) {
            youtubeVideoId = helperMethods.getYouTubeVideoId(articleText);
            if (youtubeVideoId) {
                articleText = articleText.replace(constants.youtubeUrlRegex, '');
            }

            if (!youtubeVideoId) {
                let getTextWithoutAnchor = articleText.replace(/<a[^>]*>|<\/a>/g, "");
                articleUrl = helperMethods.getUrlFromHtml(getTextWithoutAnchor);
            }

            vimeoUrlLink = helperMethods.checkVimeoUrl(articleText);
            if (vimeoUrlLink != '') {
                if (!/^https?:\/\//i.test(vimeoUrlLink)) {
                    vimeoUrlLink = 'http://' + vimeoUrlLink;
                }
            }
        }

        if (videoUrl != '') {
            videoPlayerLink = videoUrl;
        }
        if (vimeoUrlLink != '') {
            videoPlayerLink = vimeoUrlLink;
        }

        if (videoPlayerLink) {
            articleText = articleText.replaceAll(videoPlayerLink, '');
        }
        
        let shortalt = (articleText != undefined && articleText != '') ? articleText.replace(/<\/?[^>]+(>|$)/g, "").trim().substr(0, 20) : '';  
        

        return (
            <React.Fragment>
                <Feed.Extra text>
                    {this.show_post_article(type, articleText, title, id, imageUrl)}
                </Feed.Extra>
                
                <Feed.Extra images>

                    {
                        !imageUrl && !pdfUrl && !videoUrl && youtubeVideoId &&
                        <Embed autoplay={false} source='youtube' id={youtubeVideoId} active={true} />
                    }
                    {
                        videoPlayerLink && <ReactVideoPlayer vurl={videoPlayerLink} />
                    }
                    {/*   {
                        vimeoUrlLink && <ReactVideoPlayer vurl={vimeoUrlLink} />
                    } */}
                    {/* <video width="100%" height="400" controls autostart="false">
                        <source src={`${videoUrl}#t=1`} type="video/mp4" />
                    </video> */}
                    {
                        imageUrl && type !== 'article' && <Image src={imageUrl} width="100%" height="400" alt={shortalt} />
                    }
                    {
                        pdfUrl &&
                        <React.Fragment>
                            <Button
                                className=" button w-25"
                                content='Full View'
                                icon='expand'
                                labelPosition='right'
                                floated="right"
                                style={{ marginBottom: '16px' }}
                                onClick={this.showPdf}
                            />
                            <iframe title="media" src={`https://docs.google.com/gview?embedded=true&url=${pdfUrl}&amp;`} width="100%" height="450px" type="application/pdf" frameBorder="0" />
                            <TransitionablePortal open={this.state.showPdf}>
                                <Modal
                                    dimmer
                                    open={true}
                                    onClose={this.handleOpen}
                                    size='fullscreen'
                                    className='conversation-box'
                                >
                                    <div>
                                        <Button icon="delete" className="button" circular size="tiny" floated='right' onClick={this.showPdf} />
                                        <embed src={`https://docs.google.com/gview?embedded=true&url=${pdfUrl}&amp;`} width="100%" height="700px" type="application/pdf" />
                                        {/* <iframe title="media" src={`https://docs.google.com/gview?embedded=true&url=${pdfUrl}&amp;`} width="100%" height="450px" type="application/pdf" frameBorder="0" /> */}
                                    </div>
                                </Modal>
                            </TransitionablePortal>

                        </React.Fragment>
                    }
                    {
                        ppturl &&
                        <React.Fragment>
                            <iframe title="ppt" src={`https://docs.google.com/gview?embedded=true&url=${ppturl}&amp;`} width="100%" height="450px" frameBorder="0" />
                        </React.Fragment>
                    }

                    {

                        !imageUrl && !pdfUrl && !videoUrl && !vimeoUrlLink && articleUrl &&
                        <Microlink
                            url={articleUrl}
                            size="large"
                            style={{ width: '100%' }}
                        />
                    }
                  
                </Feed.Extra>


            </React.Fragment>
        )
    }
}

export default ArticleBodyNew;
