import React, { useEffect, useState } from 'react';
import { Form, Button, Radio, Dropdown, Icon, Search, List, Label } from 'semantic-ui-react';
import { countryList } from '../../../common/countries';
import "./index.scss";
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { getSuggestedCompany } from '../../../actions/user/user';
import { BASE_URL } from '../../../config/config';
import axios from 'axios';

const CompanySelection = (props) => {
    const [suggestedCompany, setSuggestedCompany] = useState('');
    const [company, setCompany] = useState({});
    const [disableCompanySearch, setDisableCompanySearch] = useState(true);
    const [isLoading, setIsLoading] = useState(false);
    const [value, setValue] = useState('');
    const [results, setResults] = useState([]);

    useEffect(() => {
        props.getSuggestedCompany(props.userProfileDetails.primaryEmail);
    }, []);

    useEffect(() => {
        if (props.user.suggestedCompany) {
            setSuggestedCompany(props.user.suggestedCompany);
            setCompany(props.user.suggestedCompany);
        }
    }, [props.user.suggestedCompany]);

    const saveDetails = async () => {
        await axios.put(`${BASE_URL}/api/v2/me`, { ...props.userProfileDetails, company: company.name, companyId: company.id, country: company.country }, { withCredentials: true });
        localStorage.setItem('companySelectionTime', new Date().toLocaleDateString());
        props.onSubmitSuccessful();
    }


    const handleResultSelect = (e, { result }) => {
        let company = {
            id: result._source.id,
            name: result._source.name,
            country: result._source.country
        }
        setCompany(company);
        setValue(company.name);
    }

    const handleSearchChange = (e, { value }) => {
        setValue(value);
        if (value.length > 4) {
            setIsLoading(true);
            axios.get(`${BASE_URL}/api/v1/company/search/${value}`, { withCredentials: true })
                .then(response => {
                    setIsLoading(false);
                    setResults(response.data);
                })
        }
    }


    const resultRenderer = result => {
        if (result && result._source && result._source.id) {
            return (
                <div key={result._source.id}>
                    <Label content={result._source.name} />
                    <Label style={{ float: 'right' }} content={result._source.country} />
                </div>
            );
        }
    };

    const handleChange = (e, { value }) => {
        if (value === suggestedCompany) {
            setCompany(value);
            setDisableCompanySearch(true);
        }
        else {
            setCompany({});
            setDisableCompanySearch(false);
        }
    }
    return (
        <span>
            {
                <div className="middle-section profilecompletion-section">

                    <div className='countryselect-card'>
                        <div className='countryselect-card-inner'>
                            {
                                suggestedCompany && suggestedCompany.id ?
                                    <span>
                                        <h4>Recommended to update</h4>
                                        <h2>Looks like you work at “{suggestedCompany.name}”</h2>
                                        <p>Adding your company will allow you to manage your company page as well as provide you with relevant information about your industry and products.</p>
                                        <div className='mt-2'>
                                            <Form.Field>
                                                <Radio
                                                    label={`Yes! I work at “${suggestedCompany.name}”`}
                                                    name='radioGroup'
                                                    value={suggestedCompany}
                                                    onChange={handleChange}
                                                    checked={company && company.id === suggestedCompany.id}
                                                />
                                            </Form.Field>
                                            <Form.Field>
                                                <Radio
                                                    label='No. I work somewhere else'
                                                    name='radioGroup'
                                                    onChange={handleChange}
                                                    value={null}
                                                    checked={!company.id || company.id !== suggestedCompany.id}
                                                />
                                            </Form.Field>
                                        </div>
                                        <div className='mt-2'>
                                            <Search
                                                fluid
                                                placeholder="Search company"
                                                minCharacters={3}
                                                loading={isLoading}
                                                onResultSelect={handleResultSelect}
                                                onSearchChange={handleSearchChange}
                                                results={results}
                                                value={value}
                                                resultRenderer={resultRenderer}
                                                disabled={disableCompanySearch}
                                            />
\
                                        </div>
                                    </span>
                                    :
                                    <span>
                                        <h4>Recommended to update</h4>
                                        <p>Adding your company will allow you to manage your company page as well as provide you with relevant information about your industry and products.</p>

                                        <Search
                                            fluid
                                            placeholder="Search company"
                                            minCharacters={3}
                                            loading={isLoading}
                                            onResultSelect={handleResultSelect}
                                            onSearchChange={handleSearchChange}
                                            results={results}
                                            value={value}
                                            resultRenderer={resultRenderer}
                                        />

                                    </span>
                            }

                            <div className='text-center mt-4'>
                                <Button onClick={saveDetails} primary className='btn-primary'>Save details</Button>
                            </div>
                        </div>
                    </div>
                </div>
            }
        </span>
    )
}


function mapDispatchToProps(dispatch) {
    return { ...bindActionCreators({ getSuggestedCompany }, dispatch) }
}
function mapStateToProps({ auth, user }) {
    return { auth, user };
}


export default connect(mapStateToProps, mapDispatchToProps)(CompanySelection);