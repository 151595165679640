import React from 'react';
import { Link } from 'react-router-dom';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { commonActions } from '../../actions';

const Message = ({ auth, userId, userName, showLoginModal }) => {
    return (
        <span>
            {
                auth ?
                    <Link to={`/message?userId=${userId}&userName=${userName}`} title="Message" className="user-profile-messageicon text-primary">
                        Message
                    </Link>
                    :
                    <span className="color-green cursor-pointer font-21" onClick={showLoginModal}>Message </span>
            }
        </span>
    )
}

const mapDispatchToProps = dispatch => {
    return {
        ...bindActionCreators({ ...commonActions }, dispatch)
    }
}

export default connect(null, mapDispatchToProps)(Message);