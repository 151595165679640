import React from 'react';
import { Button, Search } from 'semantic-ui-react';
import axios from 'axios';
import { SvgIcon, Row, Col } from '../../common';
import { BASE_URL } from '../../../config/config';

import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { commonActions, productActions } from '../../../actions';
import { apiStatus } from '../../../common/constants';
import { Dropdown } from 'semantic-ui-react';
import AddNewProduct from '../../common/AddProduct/AddNewProduct';
import SweetAlerts from '../../common/sweetalerts/sweetalert';
import { toast, ToastContainer } from 'react-toastify';


class AddRawMaterials extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            modalOpen: false,
            searchQuery: '',
            addRawButtonLoading: false,
            showLoadMore: true,
            condition: false,
            LastEvaluatedKey: props.supplier && props.supplier.LastEvaluatedKey,
            rawMaterials: [],
            showSupplierLoader: true,
            product_submit: false,
            product_update_submit: false
        };
    }

    componentDidUpdate(prevProps) {
        if (prevProps.saveStatus === apiStatus.IN_PROGRESS
            && this.props.saveStatus === apiStatus.COMPLETED) {
            this.setState({ rawMaterials: [] })
        }
    }

    addRawMaterial = () => {
        const { auth, addRawMaterials, productId, productName, showLoginModal } = this.props;
        if (!auth) {
            showLoginModal(true);
        }
        else {
            if (this.state.rawMaterials.length > 0) {
                addRawMaterials(this.state.rawMaterials, productId, productName);
                this.clearList();
                setTimeout(() => {
                    this.handleUpdateSucess();
                }, 500);
            } else {
                console.log('in here')
                toast.error('Please add a raw materail to continue !', {
                    autoClose: 3000,
                });
            }
        }
    }

    clearList = () => {
        this.setState({ rawMaterials: [] })
    }

    removeRawMaterial = (rawMaterial) => {
        const index = this.state.rawMaterials.findIndex(rawMaterial => rawMaterial.company_id === rawMaterial.company_id);
        const rawMaterials = [...this.state.rawMaterials];
        rawMaterials.splice(index, 1);
        this.setState({ rawMaterials });
    }

    /* handleResultSelect = (e, data) => {
        if (!data.options)
            return;

        this.setState({ value: data.value });
        const selectedRaw = data.options.find(item => item.value === data.value);
        let newRawMaterials = this.state.rawMaterials;
        if (selectedRaw) {
            newRawMaterials.push({
                id: selectedRaw.key,
                name: selectedRaw.name,
                country: selectedRaw.country
            });

        }
        this.setState({
            rawMaterials: newRawMaterials,
            value: '',
            results: []
        });
    };

    handleSearchChange = (e, { searchQuery }) => {
        this.setState({ isLoading: true });
        axios.get(`${BASE_URL}/api/v1/product/search/${searchQuery}`, { withCredentials: true }).then(response => {
            const test = response.data.length && response.data.map(k => {
                return {
                    key: k._source.id,
                    value: k._source.id,
                    text: k._source.name,
                    name: k._source.name
                }
            })
            this.setState({
                isLoading: false,
                results: test,
            });
        });
    }; */
    handleResultSelect = (data) => {
        console.log(data)
        if (!data.value) {
            return;
        }
        this.setState({ value: data.value });
        const selectedRaw = data
        let newRawMaterials = this.state.rawMaterials;
        if (selectedRaw) {
            newRawMaterials.push({
                id: selectedRaw.key,
                name: selectedRaw.name,
                country: selectedRaw.country
            });
        }

        this.setState({
            newRawMaterials: newRawMaterials,
            value: '',
            results: [],
            searchQuery: '',
        });
    };

    handleSearchChange = (e) => {
        const searchQuery = e.target.value;
        this.setState({ searchQuery: searchQuery, value: searchQuery, isLoading: true });
        axios.get(`${BASE_URL}/api/v1/product/search/${searchQuery}`, { withCredentials: true }).then(response => {
            const result = response.data && response.data.length > 0 && response.data.map(k => {
                return {
                    key: k._source.id,
                    value: k._source.id,
                    text: k._source.name,
                    name: k._source.name
                }
            })
            this.setState({
                isLoading: false,
                results: result,
            });


        });
    };

    openCreatePopup = () => {
        console.log('in open create ')
        this.setState({
            open: true,
            value: '',
            results: [],
            searchQuery: '',
        });
    }

    renderlist = (result, search) => {
        if (search != '') {
            return (
                <span>
                    <div >
                        <span className="text-primary addnewcompany-link" onClick={() => this.openCreatePopup()}> + Create a New Product </span>
                        <label>{search}</label>
                    </div>
                    {result && result.length > 0 &&
                        <ul>
                            {result.map((material) => {

                                return <li onClick={() => this.handleResultSelect(material)}>
                                    {material.name}
                                </li>
                            })
                            }
                        </ul>
                    }
                </span>
            )
        }
    }

    handleSucess = () => {
        this.setState({ product_submit: true });
    }

    handleCloseAlert = () => {
        this.setState({ product_submit: false })
    }

    handleUpdateSucess = () => {
        this.setState({ product_update_submit: true });
    }

    handleUpdateCloseAlert = () => {
        this.setState({ product_update_submit: false })
    }

    render() {
        const { isLoading, value, results, rawMaterials, open, searchQuery, product_submit, product_update_submit } = this.state;
        const { saveStatus, productName, productId } = this.props;

        return (
            <span>
                <div>
                    <ToastContainer />
                </div>
                <div className="supplier-head-add mb-3">
                    <div className='supplier-head-add-inner'>
                        <div>
                            <h3>Help us map the value chain.</h3>
                            <p>Are there more materials required to make the {productName}? Add more to build the network.</p>
                        </div>
                        <div className="w-100 mt-3 d-flex justify-content-between">
                            {open &&
                                <AddNewProduct
                                    productId={productId}
                                    open={open}
                                    handleClose={() => this.setState({ open: false })}
                                    type="RawMaterial"
                                    handleSucess={this.handleSucess}
                                />
                            }
                            {/*                         <Dropdown
                            className="selectsupplier-drop"
                            loading={isLoading}
                            selection
                            options={results}
                            fluid
                            search
                            icon='search'
                            placeholder='Search'
                            onSearchChange={this.handleSearchChange}
                            onChange={this.handleResultSelect}
                            allowAdditions
                            additionLabel={
                                <span className="text-primary addnewcompany-link" onClick={() => this.setState({ open: true })}> + Create a New Product </span>
                            }
                            value={value}
                            searchQuery={searchQuery}
                            selectOnBlur={false}
                            noResultsMessage={null}
                        /> */}
                            <div className='w-100 product-search-alt'>
                                <Search
                                    fluid
                                    placeholder="Search here..."
                                    loading={isLoading}
                                    showNoResults={false}
                                    onSearchChange={this.handleSearchChange}
                                    value={value}
                                    minCharacters={3}
                                // onResultSelect={this.handleResultSelect}
                                //   results={results}
                                // resultRenderer={resultRenderer}
                                />
                                <div className="result_list">
                                    {this.renderlist(results, searchQuery)}
                                </div>
                            </div>
                            <Button type='submit' primary onClick={this.addRawMaterial} loading={saveStatus === apiStatus.IN_PROGRESS}>Add To List</Button>
                        </div>
                        <div className="w-100 selectedsupplier">
                            {
                                rawMaterials.map(rawMaterial =>
                                    <span className="badge"><span className="badge-inner">{rawMaterial.name}<SvgIcon name="close" viewbox="0 0 23.126 23.126" onClick={() => this.removeRawMaterial(rawMaterial)} /></span></span>
                                )
                            }
                        </div>

                        {
                            rawMaterials.length > 0 &&
                            <div className="text-right mt-3">
                                <Button type='submit' onClick={this.clearList}>Cancel</Button>
                            </div>
                        }
                    </div>
                </div>
                {
                    product_submit && <SweetAlerts type={'S'} title={'Product has been added successfully'} message={'The product you submitted is undergoing a verification process and will be accessible to everyone once it receives approval'} closeAlert={this.handleCloseAlert}> </SweetAlerts>
                }
                {
                    product_update_submit && <SweetAlerts type={'S'} title={'Success'} message={'Raw material added successfully'} closeAlert={this.handleUpdateCloseAlert}> </SweetAlerts>
                }
            </span>

        )
    }
}

const mapStateToProps = ({ singleProduct, common, auth }) => {
    return {
        productName: singleProduct.info && singleProduct.info.Item.name,
        saveStatus: common.saveStatus,
        auth
    };
}

const mapDispatchToProps = (dispatch) => {
    return {
        dispatch,
        ...bindActionCreators({ ...productActions, ...commonActions }, dispatch)
    }
}
export default connect(mapStateToProps, mapDispatchToProps)(AddRawMaterials);