import React, { Component } from 'react';
import { Container, Label, Search, Header, Grid, Image, Statistic, Dropdown } from 'semantic-ui-react';
import Navbar from '../common/layout/navbar/Navbar';
import qs from 'querystring';
import mappes from './mappesLogo.svg';
import './newLanding.css';
import axios from 'axios';
import { BASE_URL } from '../../config/config';
import LoginModal from '../common/LoginModal';
import Footer from '../common/Footer';
import * as actions from '../../actions';
import { connect } from 'react-redux';
import LoginBar from '../common/LoginBar';
import { replaceSpaceInName } from '../../common/helperMethods';

const resultRenderer = (result) => {
    if (result && result._source && result._source.id)
        return (
            <div key={result._source.id}>

                {result._index === 'products' && <Label content={result._source.name} />}
                {result._index !== 'products' && <Label content={result._source.name + " (" + result._source.country + ")"} />}
                {result._index === 'products' && <Label style={{ float: "right" }} content={"Product"} />}
                {result._index !== 'products' && <Label style={{ float: "right" }} content={"Company"} />}
            </div>
        )
}

const options = [
    { key: 1, text: 'By Company', value: 1 },
    { key: 2, text: 'By Product', value: 2 },
]


class NewLanding extends Component {
    constructor(props) {
        super(props);
        this.state = {
            active: true,
            loginModalOpen: false,
            signInVisible: true,
            searchCategory: 2
        }
    }
    componentDidMount() {
        let loginInfo = sessionStorage.getItem("login");
        if (!loginInfo) {
            sessionStorage.setItem("login", 0);
        } else {
            loginInfo++;
            sessionStorage.setItem("login", loginInfo);
            if (loginInfo > 3) {
                // this.props.showLoginModal();
            }
        }
        this.resetComponent()
        const query = qs.parse(this.props.location.search.slice(1))
        console.log("query string", this.props.location.search, query)
        if (query && query.loginerror) {
            this.setState({ loginerror: query.loginerror })
        }
        if (query && query.login) {
            this.setState({ loginModalOpen: true })
        }
        axios.get(`${BASE_URL}/api/v1/log/landing`, { withCredentials: true })
            .then(response => {
                console.log(response.data);

            })
        document.body.classList.add('home-page')
    }
    componentWillUnmount() {
        document.body.classList.remove('home-page')
    }
    resetComponent = () => this.setState({ isLoading: false, results: [], value: '' })

    handleResultSelect = (e, { result }) => {
        if (result._index === 'products') {
            this.props.history.push(`/product/${replaceSpaceInName(result._source.name)}/${result._source.id}`)
        } else {
            this.props.history.push(`/company/${replaceSpaceInName(result._source.name)}/${result._source.id}`)
        }
    }

    handleSearchChange = (e, { value }) => {
        this.setState({ value })
        if (value.length > 2) {
            let searchCategoryUrl = "company"
            this.setState({ isLoading: true })
            if (this.state.searchCategory == 2) {
                searchCategoryUrl = "product"
            }
            axios.get(`${BASE_URL}/api/v1/${searchCategoryUrl}/search/${value}`, { withCredentials: true })
                .then(response => {
                    console.log(response.data);
                    this.setState({
                        isLoading: false,
                        results: response.data
                    })
                })
        }

    }

    handleSearchCategoryChange = (e, { value }) => {
        this.setState({ searchCategory: value })
        this.handleSearchChange(null, { value: this.state.value })
    }

    handleLoginModalOpen = () => this.setState({ loginModalOpen: true })
    handleLoginModalClose = () => this.setState({ loginModalOpen: false, signInVisible: true })

    handleSignInOption = () => this.setState({ signInVisible: true, loginModalOpen: true })
    handleSignUpOption = () => this.setState({ signInVisible: false, loginModalOpen: true })

    startOnboarding = history => history.push({ pathname: '/onBoarding', state: { email: this.state.email } })
    handleEmailChange = (e, { value }) => this.setState({ email: value })

    render() {
        const { isLoading, value, results, active } = this.state
        return (

            <Navbar {...this.props} >
                <LoginModal />
                <Container>
                    <Grid padded centered>
                        <Grid.Column className='landing-content-area' >
                            <Image style={{ width: 240 }} centered src={mappes} />

                            {!active && <span className="radTekText">For RadTech Industry</span>}
                            {active && <span className="radTekText">For Optical Fiber and Cable Industry</span>}

                            <Header textAlign="center" className="landing-header-text">
                                Network and collaborate with industry stakeholders.
                            </Header>
                            <div className="landingSearchBarWrapper">

                                <Search
                                    fluid
                                    placeholder="Search here..."
                                    className="searchWithScroll dark-input landingSearchBar"
                                    loading={isLoading}
                                    onResultSelect={this.handleResultSelect}
                                    onSearchChange={this.handleSearchChange}
                                    results={results}
                                    value={value}
                                    resultRenderer={resultRenderer}
                                />
                                <Dropdown options={options} selection defaultValue={options[1].value}
                                    onChange={this.handleSearchCategoryChange} />
                            </div>
                            <div className="landing-page-wrap">
                                <div className="landing-page-featured">
                                    <Grid padded textAlign="center" stackable>
                                        <Grid.Row>
                                            <Grid.Column width={16}>
                                                <Statistic size='mini'>
                                                    <Statistic.Label className="color-gray">Find customers, suppliers, raw materials, machinery, news</Statistic.Label>
                                                </Statistic>
                                            </Grid.Column>
                                        </Grid.Row>
                                    </Grid>

                                </div>
                            </div>
                        </Grid.Column>
                    </Grid>
                </Container>
                <LoginBar className="other-page-login-bar"
                    location={this.props.location}
                    loginModelOpenCallback={() => { this.setState({ modalOpen: false }) }}
                    history={this.props.history} />

                <Footer />
            </Navbar>
        )
    }
}

function mapStateToProps({ auth, user }) {
    return { auth, user };
}

export default connect(mapStateToProps, actions)(NewLanding);
