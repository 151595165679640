import React, { Component } from 'react';
import { Accordion, Dropdown, Search, Label, Menu } from 'semantic-ui-react'
import SvgIcon from '../../svg-icon/svg-icon';
import { BASE_URL, FRESH_CHAT_TOKEN } from '../../../../config/config';
import axios from 'axios';
import { Link } from 'react-router-dom';
import MediaQuery from 'react-responsive';
import { replaceSpaceInName } from '../../../../common/helperMethods';


class GlobalSearch extends Component {
   constructor(props) {
      super(props);
      this.state = {
         searchCategory: 'product',
         set_open: false,
         isActiveSerach: false,
         current_filter: '',
         value: '',
      };
      this.searchRef = React.createRef(); 
   }

   componentDidMount() {
      document.addEventListener('mousedown', this.handleOutsideClick); // Add event listener
   }

   componentWillUnmount() {
      document.removeEventListener('mousedown', this.handleOutsideClick); // Clean up
   }

   handleOutsideClick = (event) => {
      if (this.searchRef.current && !this.searchRef.current.contains(event.target)) {
         this.setState({ isActiveSerach: false }); // Close the search if clicked outside
      }
   };

   handleResultSelect = (e, { result }) => {
      if (result._index === 'products') {
         this.props.history.push(`/product/${replaceSpaceInName(result._source.name)}/${result._source.id}`);
      } else {
         this.props.history.push(`/company/${replaceSpaceInName(result._source.name)}/${result._source.id}`);
      }
   };

   handleSearchChange = (e, { value }) => {
      this.setState({ value });
      if (value.length > 2) {
         const { searchCategory } = this.state;
         this.setState({ isLoading: true });

         axios.get(`${BASE_URL}/api/v1/${searchCategory}/search/${value}`, { withCredentials: true }).then(response => {
            this.setState({
               isLoading: false,
               results: response.data,
            });
         });
      }
   };
   handleSearchCategoryChange = (e, { value }) => {
      this.setState({ searchCategory: value }, () => {
         this.handleSearchChange(null, { value: this.state.value });
      });
   };

   resetComponent = () => this.setState({ isLoading: false, results: [], value: '' });

   closeClassSearch = () => {
      this.setState((prevState) => ({
         isActiveSerach: !prevState.isActiveSerach,
         value: '',
         current_filter: '',
         results: [],
         value: '',
      }));
   };

   ActivateClassSearch = () => {
      this.setState((prevState) => ({
         isActiveSerach: true
      }));
   };

   handleProdCompanySearchChange = (event) => {
      let value = event.target.value
      this.setState({ value: value })
      this.search_comp_prod(value);
   };
   
   search_comp_prod = (value) => {
      if(value!=''){
         if (value.length >= 2) {
            this.setState({ isLoading: true, results : [] });
            if (this.state.current_filter != '') {
               axios.get(`${BASE_URL}/api/v1/${this.state.current_filter}/search/${value}`, { withCredentials: true }).then(response => {
                  let new_response = [];
                  if (response.data.length > 0) {
                     new_response = response.data.map(y => {
                        y._source._index = y._index
                        y._source._id = y._id
                        y._source._type = y._type
                        return y._source
                     })
                  }
                 setTimeout(() => {
                    this.setState({
                       isLoading: false,
                       results: new_response,
                    });
                 }, 250);
               });
            } else {
               axios.get(`${BASE_URL}/api/technologies/search/${value}`, { withCredentials: false }).then(response => {
                  setTimeout(() => {
                     this.setState({
                        isLoading: false,
                        results: response.data.data,
                     });
                  }, 250);
               });
   
            }
         } else {
            this.setState({
               isLoading: false,
               results: []
            });
         }
      }
   }

   renderSearchResults = () => {
      let result = this.state.results
      if (result != undefined && result.length > 0) {
         return <span>
            {/* <label>Recent searches</label> */}
            <ul>
               {result.map(list =>
                  <li onClick={() => this.closeClassSearch()}>
                     {/* uncomment for recent searches list */}
                     {/* 
                    <div className='searchicon'>
                        <SvgIcon name='arrow-topright' viewbox="0 0 11 11" />
                    </div> */}
                     <Link to={this.generate_link(list)}>
                        <div className='searchdata'>
                           <p>{list.name}</p>
                           <small> {list.country}
                              {list._index == 'company' && list.country ? (' | ') : '  '}
                              {list._index}</small>
                        </div>
                     </Link>
                  </li>
               )}
            </ul>
         </span>

      }
   }

   generate_link = (result) => {
      if (result._index === 'products') {
         return (`/product/${replaceSpaceInName(result.name)}/${result.id}`);
      } else {
         return (`/company/${replaceSpaceInName(result.name)}/${result.id}`);
      }
   };

   selectFilter = (current_filter) => {
      const { value } = this.state;
      if (this.state.current_filter == current_filter){
         this.setState({ current_filter: '' })
      } else {
         this.setState({ current_filter: current_filter })
      }
      
      setTimeout(() => {
         if (value != '') {
            this.search_comp_prod(value);
         }
      }, 250);
   }

   render() {
      const { isActiveSerach, current_filter, value } = this.state;

      return (
         <div ref={this.searchRef} className={isActiveSerach ? 'p-1 search-main expaned-search' : 'p-1 search-main'}>
            {/*  
             <Search
               fluid
               placeholder="Search here..."
               className="searchWithScroll dark-input landingSearchBar"
               loading={isLoading}
               onResultSelect={this.handleResultSelect}
               onSearchChange={this.handleSearchChange}
               results={results}
               value={value}
               resultRenderer={resultRenderer}
               minCharacters={3}
            />
            <Dropdown
               options={options} selection
               defaultValue={options[1].value}
               onChange={this.handleSearchCategoryChange}
            /> 
            */}
            <Menu.Item className='p-2'>
               <MediaQuery maxWidth={767}>
                  <button className='back-arrow' onClick={this.closeClassSearch}>
                     <SvgIcon name='chevron-left' viewbox="0 0 10 16" />
                  </button>
               </MediaQuery>
               <input className={isActiveSerach ? 'mobile-search expaned-search' : 'mobile-search'} placeholder="Search product / technologies / company" onClick={this.ActivateClassSearch} onInput={this.handleProdCompanySearchChange} value={value} />
               {isActiveSerach && (
                  <div className={isActiveSerach ? 'search-drop-mappes expaned-search' : 'search-drop-mappes'}>
                     <div className='search-drop-mappes-inner'>
                        <label>I’m looking for ...</label>
                        <div className='btn-row'>
                           <button onClick={() => this.selectFilter('company')} className={(current_filter == 'company' ? "active selected" : " ") + ''}>Company</button>
                           <button onClick={() => this.selectFilter('product')} className={(current_filter == 'product' ? "active selected" : " ") + ''}>Product</button>
                        </div>
                        {this.renderSearchResults()}
                     </div>
                  </div>
               )}
            </Menu.Item>

         </div>
      )
   }
}

export default GlobalSearch;