import mappesIcon from '../../../assets/images/mappes-icon.png';
import React from 'react';
import { Button, Input, Message } from 'semantic-ui-react';
import './index.scss'
import { emailRegex } from '../../../common/constants';

export const Email = ({ handleEmailChange, submitEmail, email, error, emailLoading }) => {
    return (
        <div className='lwo-card-inner'>
            <div className='upper-head'>
                <img src={mappesIcon} alt='' />
                <h1>Welcome to Mappes</h1>
                <p className='text-center'>Enter business email ID to access product community</p>
            </div>
            <div className='form-item'>
                <label>Enter business email</label>
                <Input
                    onChange={handleEmailChange}
                    value={email}
                    placeholder='abc.name@company.com'
                    id="email"
                />
                {error
                    && <Message error className="w-100">
                        {error}
                    </Message>
                }
            </div>
            <Button
                className='submit-btn'
                primary
                onClick={submitEmail}
                type="button"
                loading={emailLoading}
                disabled={!emailRegex.test(email)}
                >
                Continue
            </Button>
            <div className='ageee-text'>
                By continuing, you agree to Mappes <br /> <b>Terms & conditions,</b> and <b>Privacy policy</b>
            </div>
        </div>
    )
}