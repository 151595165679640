import React, { Component } from 'react';
import { Icon, Grid, Button, Dropdown, Popup, Container, Table, Header, Divider, Checkbox } from 'semantic-ui-react'
import MediaQuery from 'react-responsive'
import Navbar from '../common/layout/navbar/Navbar';
import LeftSidebar from '../common/LeftSidebar';
import RightSection from '../common/RightSection';
import "./select-mail.scss";

import PremiumTag from '../../assets/images/premium-tag.svg';

class selectMail extends Component {


    constructor(props) {
        super(props);
        this.state = {
            modalOpen: false,
        }

    }

   
    renderFollow() {
        if (this.props.user && this.props.user.followedProducts) {
            for (let i = 0; i < this.props.user.followedProducts.Items.length; i++) {
                if (this.props.user.followedProducts.Items[i].product_id === this.props.info.Item.id) {
                    return (
                        <Popup
                            trigger={
                                <Button className=" follow-btn background-light-blue" primary size={'mini'} animated>
                                    <Button.Content visible>Following</Button.Content>
                                    <Button.Content hidden>
                                        <Icon name='plus' />
                                    </Button.Content>
                                </Button>
                            }
                            inverted
                            content='Follow Product'
                            position='top center'
                        />
                    )
                }
            }

        }
        return (
            <Button className=" follow-btn background-light-blue" primary size={'mini'} animated>
                <Button.Content visible>Follow</Button.Content>
                <Button.Content hidden>
                    <Icon name='plus' />
                </Button.Content>
            </Button>
        )
    }


    render() {
        return ( 
            <Navbar {...this.props}>
            <div className="primary-background">
                <Container className="body-background palm-nudge-sides">
                    <Grid padded stackable>
                        <MediaQuery minWidth={768}>
                            <Grid.Column className="left-content">
                                <LeftSidebar />
                            </Grid.Column>
                        </MediaQuery>
                        <Grid.Column className="main-content-area">
                            <div className="main-content pD-b-50">
                                <div className="main-content_inner">
                                    <Grid padded>
                                        <Grid.Row className="extra-padding-sides">
                                            <Grid padded className="full-width">
                                                <Grid.Column width={13}>
                                                    <span className="color-light-green product-name">Single Mode Optical Fiber</span>
                                                    {this.renderFollow()}
                                                </Grid.Column>
                                                <Grid.Column floated='right' width={3} textAlign="right">
                                                    <Dropdown trigger={<Icon size='large' name={'ellipsis horizontal'} />}>
                                                        <Dropdown.Menu direction='right' style={{ borderRadius: "10px" }}>
                                                            <Dropdown.Item style={{ marginTop: "5px", marginBottom: "5px" }} className="dropDownThreeDots" text='Request Quote' />
                                                        </Dropdown.Menu>
                                                    </Dropdown>
                                                </Grid.Column>
                                            </Grid>
                                        </Grid.Row>
                                    </Grid>
                                    <Grid id="abc" padded>
                                        <Grid.Row className="background-white extra-padding-sides mx-0">
                                            <Grid.Column width={16}>
                                                <Grid>
                                                    <Grid.Column floated="left" width={13}>
                                                        <Header as={'h4'} className="table-heading pD-b-10">
                                                            Suppliers
                                                        </Header>
                                                    </Grid.Column>
                                                    <Grid.Column textAlign="right" floated="right" width={3}>
                                                    <Dropdown
                                                        className="padding-top-15"
                                                        trigger={
                                                            <Icon
                                                                className="color-light-blue"
                                                                size="large"
                                                                name={'ellipsis horizontal'}
                                                            />
                                                        }>
                                                        <Dropdown.Menu direction="right" style={{ borderRadius: '10px' }}>
                                                            <Dropdown.Item className="dropDownThreeDots" text='Remove Suppliers' />
                                                        </Dropdown.Menu>
                                                    </Dropdown>
                                                    </Grid.Column>
                                                </Grid>
                                            </Grid.Column>
                                            <Grid.Column width={16}>
                                                <Divider className="tableDivider" />
                                            </Grid.Column>
                                            <Grid.Column width={16}>
                                                <Table padded basic="very" striped className="emailselect-list">
                                                    <Table.Header>
                                                        <Table.Row>
                                                            <Table.HeaderCell className="color-light-blue">Company Name</Table.HeaderCell>
                                                            <Table.HeaderCell className="color-light-blue">Country</Table.HeaderCell>
                                                            <Table.HeaderCell className="color-light-blue" textAlign="center">Listed Contacts</Table.HeaderCell>
                                                        </Table.Row>
                                                    </Table.Header>
                                                    <Table.Body>
                                                        <Table.Row>
                                                            <Table.Cell className="pl-2">
                                                                <Checkbox label="Simco Materials International Pvt. Ltd." />  
                                                                <img alt={PremiumTag} style={{ width: 56, marginBottom: -3, marginLeft: 2 }} src={PremiumTag} />
                                                            </Table.Cell>
                                                            <Table.Cell>India</Table.Cell>    
                                                            <Table.Cell textAlign="center">5</Table.Cell> 
                                                        </Table.Row> 
                                                        <Table.Row>
                                                            <Table.Cell className="pl-2">
                                                                <Checkbox label="ZTT India Private Limited" />  
                                                                <img alt={PremiumTag} style={{ width: 56, marginBottom: -3, marginLeft: 2 }} src={PremiumTag} />
                                                            </Table.Cell>
                                                            <Table.Cell>India</Table.Cell>    
                                                            <Table.Cell textAlign="center">5</Table.Cell> 
                                                        </Table.Row> 
                                                        <Table.Row className="selected">
                                                            <Table.Cell className="pl-2">
                                                                <Checkbox label="Himachal Futuristic Communication Ltd" />  
                                                                <img alt={PremiumTag} style={{ width: 56, marginBottom: -3, marginLeft: 2 }} src={PremiumTag} />
                                                            </Table.Cell>
                                                            <Table.Cell>India</Table.Cell>    
                                                            <Table.Cell textAlign="center">5</Table.Cell> 
                                                        </Table.Row>  
                                                        <Table.Row className="selected">
                                                            <Table.Cell className="pl-2">
                                                                <Checkbox label="Simco Materials International Pvt. Ltd." />  
                                                                <img alt={PremiumTag} style={{ width: 56, marginBottom: -3, marginLeft: 2 }} src={PremiumTag} />
                                                            </Table.Cell>
                                                            <Table.Cell>India</Table.Cell>    
                                                            <Table.Cell textAlign="center">5</Table.Cell> 
                                                        </Table.Row> 
                                                        <Table.Row>
                                                            <Table.Cell className="pl-2">
                                                                <Checkbox label="ZTT India Private Limited" />  
                                                                <img alt={PremiumTag} style={{ width: 56, marginBottom: -3, marginLeft: 2 }} src={PremiumTag} />
                                                            </Table.Cell>
                                                            <Table.Cell>India</Table.Cell>    
                                                            <Table.Cell textAlign="center">5</Table.Cell> 
                                                        </Table.Row> 
                                                        <Table.Row>
                                                            <Table.Cell className="pl-2">
                                                                <Checkbox label="Himachal Futuristic Communication Ltd" />  
                                                                <img alt={PremiumTag} style={{ width: 56, marginBottom: -3, marginLeft: 2 }} src={PremiumTag} />
                                                            </Table.Cell>
                                                            <Table.Cell>India</Table.Cell>    
                                                            <Table.Cell textAlign="center">5</Table.Cell> 
                                                        </Table.Row>  
                                                        <Table.Row>
                                                            <Table.Cell className="pl-2">
                                                                <Checkbox label="Simco Materials International Pvt. Ltd." />  
                                                                <img alt={PremiumTag} style={{ width: 56, marginBottom: -3, marginLeft: 2 }} src={PremiumTag} />
                                                            </Table.Cell>
                                                            <Table.Cell>India</Table.Cell>    
                                                            <Table.Cell textAlign="center">5</Table.Cell> 
                                                        </Table.Row> 
                                                        <Table.Row>
                                                            <Table.Cell className="pl-2">
                                                                <Checkbox label="ZTT India Private Limited" />  
                                                                <img alt={PremiumTag} style={{ width: 56, marginBottom: -3, marginLeft: 2 }} src={PremiumTag} />
                                                            </Table.Cell>
                                                            <Table.Cell>India</Table.Cell>    
                                                            <Table.Cell textAlign="center">5</Table.Cell> 
                                                        </Table.Row> 
                                                        <Table.Row>
                                                            <Table.Cell className="pl-2">
                                                                <Checkbox label="Himachal Futuristic Communication Ltd" />  
                                                                <img alt={PremiumTag} style={{ width: 56, marginBottom: -3, marginLeft: 2 }} src={PremiumTag} />
                                                            </Table.Cell>
                                                            <Table.Cell>India</Table.Cell>    
                                                            <Table.Cell textAlign="center">5</Table.Cell> 
                                                        </Table.Row>   
                                                    </Table.Body>
                                                </Table>
                                                <Button className="" primary>
                                                    Proceed to final step     
                                                </Button>
                                            </Grid.Column>
                                        </Grid.Row>
                                    </Grid>
                                </div>
                            </div>
                        </Grid.Column>
                        <RightSection entityType="company" />
                    </Grid>
                </Container>
            </div>
            </Navbar>
        )
    }

}

 
export default selectMail;