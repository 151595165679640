import React, { useEffect, useState } from 'react';
import { Button, } from 'semantic-ui-react';
import { SvgIcon } from '../common';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { networkFeedActions } from '../../actions';
import { articleTimeAgo, feedTimeAgo, getFormattedDate } from '../../common/helperMethods';
import { Link } from 'react-router-dom';

const News = (props) => {
    const [news, setNews] = useState([]);
    const [page, setPage] = useState(0);
    useEffect(() => {
        props.GetNews(page);
    }, [page]);

    useEffect(() => {
        if (props.news) {
            setNews([...news, ...props.news]);
        }
    }, [props.news]);

    return (
        <div className='latest-news'>
            <h3 className='title'>Latest News updates</h3>
            <div className="leftuserbox">
                <ul>
                    {news.map(item =>
                        <li>
                            <Link to={`/feed/post/${item.id}`}>
                                <h4 dangerouslySetInnerHTML={{ __html: item.text.substring(0, 60) + " ...." }}></h4>
                            </Link>
                             <p>{/* <a href='#' className='tag'>Mappes Inc</a> */} <span className='time-span'>{getFormattedDate(new Date(item.added), false, false, true)}</span></p> 
                        </li>
                    )}
                </ul>
                
                {/* <Button className='showmore-btn' onClick={() => setPage(page + 1)}>Show more <SvgIcon name='menu-arrow' viewbox='0 0 23.619 13.503' /></Button> */}
            </div>
            <p className='viewallevent-bottom mb-3'>
                Are you aware of all the news that are taking place around the world?
                <Link to="/news">
                    View all news {">"}
                </Link>
            </p>
        </div>

    )
}



function mapDispatchToProps(dispatch) {
    return { ...bindActionCreators({ ...networkFeedActions }, dispatch) }
}

function mapStateToProps({ networkFeed }) {
    return {
        news: networkFeed.news
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(News);