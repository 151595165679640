import React from 'react';
import { Link } from 'react-router-dom';
import { bindActionCreators } from 'redux';
import * as actions from '../../actions';
import { networkFeedActions, commonActions } from '../../actions';
import { connect } from 'react-redux';

import { Container, Grid} from 'semantic-ui-react';
import MediaQuery from 'react-responsive'
import Navbar from '../common/layout/navbar/Navbar';
import LeftSidebar from '../common/LeftSidebar';
import RightSection from '../common/RightSection';
import './productpage_wip.scss';

// import images
import InviteView from '../common/invite/invite';
import Followers from '../common/followers/Followers';
import ProductDetails from './ProductDetails';
import { replaceSpaceInName } from '../../common/helperMethods';
import axios from 'axios';
import { BASE_URL } from '../../config/config';
import SEO from '../seo/SEO';

class ProductpageWip extends React.Component {
    componentDidMount() {
        this.getData();
        const { id, name } = this.props.match.params;
        const { history } = this.props;
        if (!name) {
            axios.get(`${BASE_URL}/api/v1/product/${id}`, { withCredentials: true }).then(res => {
                if (res.data != undefined) {
                    if (res.data.mergedProductId != undefined) {
                        history.push(`/product/${replaceSpaceInName(res.data.mergedProductName)}/${res.data.mergedProductId}`);
                    } else {
                        console.log('res.data ', res.data )
                        let productName = '';
                        if (res.data.name != undefined) {
                            productName = res.data.name;
                        } else {
                            if (res.data.Item.name != undefined) {
                                productName = res.data.Item.name;
                            }
                        }

                        history.push(`/product/${replaceSpaceInName(productName)}/${id}`)
                    }
                }
            })
        } else {
            axios.get(`${BASE_URL}/api/v1/product/${id}`, { withCredentials: true }).then((res) => {
                if (res.data != undefined) {
                    if (res.data.mergedProductId != undefined) {
                        history.push(`/company/${replaceSpaceInName(res.data.mergedProductName)}/${res.data.mergedProductId}`);
                    }
                }
            })
        }
    }

    componentDidUpdate(prevProps) {
        if (this.props.match.params.name) {
            if (this.props.match.params.id !== prevProps.match.params.id) {
                const sidebar = document.getElementById('sidebar-pushable');
                if (sidebar) {
                    sidebar.scrollTop = 0;
                }
                this.getData();
            }
        }
    }

    getData() {
        var productId = this.props.match.params.id;
        this.props.getProductInfo(productId);
        this.props.getFollowers(productId);
    }
    getCurrentViewContent = () => {
        switch (this.props.match.params.view) {
            case "followers":
                return <Followers followers={this.props.product.followers} auth={this.props.auth} />
            case "invite":
                return <InviteView type="product" id={this.props.match.params.id} />
            default:
                return <ProductDetails {...this.props} />
        }
    }
    render() {
        let productName, description;
        const { id, view, name } = this.props.match.params;
        if (!name) return null;
        if (this.props.product.info) {
            description = (this.props.product.info.Item != undefined && this.props.product.info.Item.description != undefined) ? this.props.product.info.Item.description : '';
            productName = (this.props.product.info.Item != undefined && this.props.product.info.Item.name) ? this.props.product.info.Item.name : '';
        }

        return (
            <>
                <Navbar {...this.props}>
                    <div className="primary-background">
                        <Container className="body-background palm-nudge-sides">
                            <SEO robots="index, follow"
                                canonicalUrl={`/product/${replaceSpaceInName(name)}/${id}`}
                                alternateLink={`/product/${replaceSpaceInName(name)}/${id}`}
                                pageType={'product'}
                                pageContent={this.props.product.info}
                            />
                            <Grid padded stackable>
                                <MediaQuery minWidth={768}>
                                    <Grid.Column className="left-content">
                                        <LeftSidebar />
                                    </Grid.Column>
                                </MediaQuery>
                                <Grid.Column className="main-content-area">

                                    <div className="productpage-wip">
                                        {(view === "followers" || view === "invite") && (
                                            <div>
                                                <Link to={`/product/${replaceSpaceInName(name)}/${id}`} >
                                                    <div className="col-sm-12 back-link cursor-pointer">
                                                        <i className="chevron left icon"></i> Back to {name}
                                                    </div>
                                                </Link>
                                            </div>
                                        )}
                                        <div className="invite-card-body">
                                            {
                                                this.getCurrentViewContent()
                                            }
                                        </div>
                                    </div>
                                </Grid.Column>
                                <RightSection entityType="product" entityId={id} />
                            </Grid>
                        </Container >
                    </div>
                </Navbar>
            </>
        )
    }
}

function mapStateToProps({ singleProduct, auth, networkFeed, common }) {
    return { product: singleProduct, auth, networkFeed, common };
}

function mapDispatchToProps(dispatch) {
    return {
        dispatch,
        ...bindActionCreators({ ...actions, ...networkFeedActions, ...commonActions }, dispatch),
    };
}

export default connect(mapStateToProps, mapDispatchToProps)(ProductpageWip);