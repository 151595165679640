import React, { useState } from 'react';
import NoSuppliers from './NoSuppliers';
import SuppliersList from './SuppliersList';
import AddSuppliers from './AddSuppliers'

function SuppliersView({ productId, history, suppliers }) {
    console.log('SuppliersView history ', history )
    const [showAddSupplier, setShowAddSupplier] = useState(false)
    return (
        <div className="supplierslist-wrapper border-1-radius">
            {suppliers.length > 0
                ? 
                <span>
                    <AddSuppliers productId={productId} history={history} />
                    <SuppliersList history={history} />
                </span>
                : showAddSupplier
                    ? <AddSuppliers productId={productId} history={history} />
                    : <NoSuppliers setShowAddSupplier={setShowAddSupplier} history={history} />
            }
        </div>
    )
}

export default SuppliersView;