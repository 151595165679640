export const FETCH_USER = 'fetch_user'
export const CHAT_USER = 'chat_user'
export const ALL_PRODUCTS = 'all_products'
export const DUMMY = 'dummy'
export const SINGLE_PRODUCT_INFO = 'SINGLE_PRODUCT_INFO'
export const SINGLE_PRODUCT_RAW_MATERIALS = 'SINGLE_PRODUCT_RAW_MATERIALS'
export const SINGLE_PRODUCT_APPLICATION = 'SINGLE_PRODUCT_APPLICATION'
export const SINGLE_PRODUCT_SUPPLIER = 'SINGLE_PRODUCT_SUPPLIER'
export const SINGLE_PRODUCT_ACTIVITY = 'SINGLE_PRODUCT_ACTIVITY'
export const FOLLOWED_PRODUCTS = 'FOLLOWED_PRODUCTS'
export const RECOMMENDED_PRODUCTS = 'RECOMMENDED_PRODUCTS'
export const POTENTIAL_CUSTOMERS = 'POTENTIAL_CUSTOMERS'
export const SINGLE_COMPANY_INFO = 'SINGLE_COMPANY_INFO'
export const NETWORK_USER_FEED = "NETWORK_USER_FEED";
export const POST_STATUS = "POST_STATUS";
export const FEED_UPDATE_STATUS = "FEED_UPDATE_STATUS";
export const NETWORK_FEED_LOADING_STATUS = "NETWORK_FEED_LOADING_STATUS";
export const NETWORK_PRODUCT_FEED = "NETWORK_PRODUCT_FEED";
export const GLOBAL_USER_FEED = "GLOBAL_USER_FEED";
export const UPDATE_USERINFO_COLLECTION = "UPDATE_USERINFO_COLLECTION";
export const USERINFO = "USERINFO";
export const UPLOAD_PROGRESS = "UPLOAD_PROGRESS";
export const NETWORK_USER_LIKED_POSTS = "NETWORK_USER_LIKED_POSTS";
export const SET_POST_COMMENTS = "SET_POST_COMMENTS";
export const POST_LIKES_COUNT = "POST_LIKES_COUNT";
export const ADD_POST_COMMENT_STATUS = "ADD_POST_COMMENT_STATUS";
export const NETWORK_COMPANY_FEED = "NETWORK_COMPANY_FEED";
export const POST_DETAILS = "POST_DETAILS";
export const NETWORK_PRODUCT_FEED_SUMMARY = "NETWORK_PRODUCT_FEED_SUMMARY";
export const NETWORK_COMPANY_FEED_SUMMARY = "NETWORK_COMPANY_FEED_SUMMARY";
export const POST_SHARED = "POST_SHARED";
export const SET_NETWORKFEED_PAGINATION = "SET_NETWORKFEED_PAGINATION";
export const USER_IMAGE_UPLOAD = "USER_IMAGE_UPLOAD";
export const COMPANY_LOGO_UPDATED = "COMPANY_LOGO_UPDATED";
export const RESET_PAGINATION = "RESET_PAGINATION";
export const CREATE_EVENT = "CREATE_EVENT";
export const EVENT_DETAILS = "EVENT_DETAILS";
export const ALL_EVENTS = "ALL_EVENTS";
export const EVENT_LIST = "EVENT_LIST";
export const RSVP_EVENTS = "RSVP_EVENTS";
export const INDUSTRY_EVENT_LIST = "INDUSTRY_EVENT_LIST";
export const POST_TAGGED = "POST_TAGGED";
export const POST_TAGGED_FAILED = "POST_TAGGED_FAILED";
export const EXHIBITORS_COLLECTION = "EXHIBITORS_COLLECTION";
export const SAVE_STATUS = "SAVE_STATUS";
export const GET_STATUS = "GET_STATUS";
export const ATTENDEES = "ATTENDEES";
export const RESET_EVENT = "RESET_EVENT";
export const EMPLOYEES_COLLECTION = "EMPLOYEES_COLLECTION";
export const EVENT_DELETED = "EVENT_DELETED";
export const VOTES = "VOTES";
export const RESET_EXHIBITORS_COLLECTION = "RESET_EXHIBITORS_COLLECTION";
export const RESET_ATTENDEES_COLLECTION = "RESET_ATTENDEES_COLLECTION";
export const SHOW_LOGIN_MODAL = "SHOW_LOGIN_MODAL";
export const NAVIGATE_TO_PRICING = "NAVIGATE_TO_PRICING";
export const USER_PROFILE_DETAILS = "USER_PROFILE_DETAILS";
export const USER_VENDORS_COUNT = "USER_VENDORS_COUNT";
export const USER_CONNECTIONS_COUNT = "USER_CONNECTIONS_COUNT";
export const USER_CUSTOMERS_COUNT = "USER_CUSTOMERS_COUNT";
export const USER_VENDORS = "USER_VENDORS";
export const USER_CONNECTIONS = "USER_CONNECTIONS";
export const USER_CUSTOMERS = "USER_CUSTOMERS";
export const PRODUCT_FOLLOWERS = "PRODUCT_FOLLOWERS";
export const COMPANY_FOLLOWERS = "COMPANY_FOLLOWERS";
export const RESET_PRODUCT_DATA = "RESET_PRODUCT_DATA";
export const USER_FOLLOWED_COMPANIES = "USER_FOLLOWED_COMPANIES";
export const USER_UPDATE_RESPONSE = "USER_UPDATE_RESPONSE";
export const GET_COMPANY_PRODUCTS = "GET_COMPANY_PRODUCTS";
export const SET_COMPANY_EMPLOYEES = "SET_COMPANY_EMPLOYEES";
export const SUBMIT_INQUIRY_SUCCESS = "SUBMIT_INQUIRY_SUCCESS";
export const VIMEO_VIDEO_UPLOAD_START = "VIMEO_VIDEO_UPLOAD_START";
export const VIMEO_VIDEO_UPLOAD_RESUME = "VIMEO_VIDEO_UPLOAD_RESUME";
export const VIMEO_VIDEO_UPLOAD_STOP = "VIMEO_VIDEO_UPLOAD_STOP";
export const OTP_VERIFICATION_COMPLETED ="OTP_VERIFICATION_COMPLETED";
export const SET_BULK_PRODUCT_DETAILS = "SET_BULK_PRODUCT_DETAILS";
export const CREATE_REQUEST_QUOTE = "CREATE_REQUEST_QUOTE";
export const GET_REQUEST_QUOTE = "CREATE_REQUEST_QUOTE";
export const REGENERATE_OTP = "REGENERATE_OTP";
export const RESET_ARTICLE = "RESET_ARTICLE";
export const SET_EVENT_PAGINATION = "SET_EVENT_PAGINATION";
export const USER_SUGGESTED_COMPANY = "USER_SUGGESTED_COMPANY";
export const NEW_COMPANY_ID = 'NEW_COMPANY_ID';
export const HALLBOOTHSAVEFAIL = 'HALLBOOTHSAVEFAIL';
export const HALLBOOTHSAVESUCCESS = 'HALLBOOTHSAVESUCCESS';
export const EXIBITOR_FILTER_PRODUCT_LIST = 'EXIBITOR_FILTER_PRODUCT_LIST';
export const EXIBITORFILTERSUCCESS = 'EXIBITORFILTERSUCCESS';
export const EXIBITORSEARCHSUCCESS = 'EXIBITORSEARCHSUCCESS';
export const NEWSSEARCHSUCCESS = 'NEWSSEARCHSUCCESS';
export const SET_NEWS_PAGINATION = 'SET_NEWS_PAGINATION';
export const NEWS = "NEWS";
export const NEWS_LIST = "NEWS_LIST";
export const NEWSCOMPANYSEARCHSUCCESS = 'NEWSCOMPANYSEARCHSUCCESS';
export const NEWS_PREFERED_COMPANY_LIST = 'NEWS_PREFERED_COMPANY_LIST';
export const NEWSPRODUCTSEARCHSUCCESS = 'NEWSPRODUCTSEARCHSUCCESS';
export const NEWS_PREFERED_PRODUCT_LIST = 'NEWS_PREFERED_PRODUCT_LIST';

