import React, { Component } from "react"; 

 
class SvgSprite extends Component {
  constructor(props) {
    super(props);
    this.state = {
       svg: null,
       isLoaded: false,
       isErrored: false,
      }
    }
  
  
  svgLoader = (url) =>{
    let svgVersion = "0.0.2";
    console.log("SVG Sprite version : " + svgVersion);
    var isLocalStorage = 'localStorage' in window && window['localStorage'] !== null,
        data;
    let that=this;
    if (isLocalStorage && (localStorage.getItem('inlineSVGrev') === svgVersion)) {
      data = localStorage.getItem('inlineSVGdata');
      // setSvg(data)
      // setIsLoaded(true)
      this.setState({ svg: data, isLoaded: true });
    }else{
      fetch(url)
        .then((response) => {
            if(response.ok) {                       
              return response.text();
            }
            throw new Error('Network response was not ok.');
          })
        .then(function(data) { 
              if(data !== undefined){            
                if (isLocalStorage) {
                  localStorage.setItem('inlineSVGdata', data);
                  localStorage.setItem('inlineSVGrev', svgVersion);
                }
                that.setState({ svg: data });
              }
          })
        .catch(that.setState({ isErrored: true }))
        .then(() => that.setState({ isLoaded: true }))
    } 
  }

  componentWillUnmount() {
  }

  componentDidMount() {
    this.svgLoader(this.props.url)
  }

  componentDidUpdate() {
  }
       
  render() {
    return (
      <div className={`svgInline svgInline--${this.state.isLoaded ? 'loaded' : 'loading'} ${this.state.isErrored ? 'svgInline--errored' : ''}`}
        dangerouslySetInnerHTML={{ __html: this.state.svg }}
    />
    );
  }
}
  
export default SvgSprite;
