import axios from 'axios';
import { BASE_URL } from '../../config/config';
import { NEWS_LIST, NEWSSEARCHSUCCESS, SET_NEWS_PAGINATION, NEWSCOMPANYSEARCHSUCCESS, NEWS_PREFERED_COMPANY_LIST, NEWSPRODUCTSEARCHSUCCESS, NEWS_PREFERED_PRODUCT_LIST } from '../../config/types';

 import { setSaveStatus } from '../common/common';
 import { apiStatus } from '../../common/constants';

export const setNewsPagination = (NewsPagination) => async (dispatch) => {
   dispatch({ type: SET_NEWS_PAGINATION, payload: NewsPagination });
}

const updateTotalCountInPagination = (totalCount) => async (dispatch, getState) => {
   const state = getState();
   const { newsPagination } = state.newsList;

   const newsPaginationUpdated = {
      size: newsPagination.size,
      start: newsPagination.start,
      total: totalCount
   }
   dispatch(setNewsPagination(newsPaginationUpdated));
}

const resetNewsagination = () => async (dispatch) => {
   const newsPagination = {
      start: 0,
      size: 30,
   }

   dispatch({ type: SET_NEWS_PAGINATION, payload: newsPagination });
}

export const getNewsList = (products, companies) => async (dispatch, getState) => {
   dispatch({ type: NEWSSEARCHSUCCESS, payload: true });

   /* if (filter || searchString ) {
      dispatch({ type: NEWS_LIST, payload: [] });
      dispatch(updateTotalCountInPagination(0));
   } */
   const state = getState();
    const { size, start } = state.newsList.newsPagination;
   let data_obj = {
      "from": start,
      "size": size,
      "filters": {
         "products": products,
         "companies": companies
      }
   }

   let url = `${BASE_URL}/api/news/list`;
   const res = await axios.post(url, data_obj, { withCredentials: true });
   let newList = (res.data!= undefined) ? res.data['data']['rows'] : [];
   if (newList && newList.length > 0) {
      dispatch(updateTotalCountInPagination(res.data.data.totalRecords));
      dispatch({ type: NEWS_LIST, payload: newList });
      dispatch({ type: NEWSSEARCHSUCCESS, payload: false });

   }
   else {
      dispatch({ type: NEWS_LIST, payload: [] })
      dispatch({ type: NEWSSEARCHSUCCESS, payload: false });

   }
}

/* export const get_prefered_company = () => async (dispatch) => {
   try {
      dispatch({ type: NEWSCOMPANYSEARCHSUCCESS, payload: true });

      const res = await axios.post(`${BASE_URL}/api/v2/preferred/company`, { "category": "news" }, { withCredentials: true });
      let ListData = [];
      if (res.data!= undefined) {
         ListData = res.data.data;
      }
      dispatch({ type: NEWSCOMPANYSEARCHSUCCESS, payload: false });
      dispatch({ type: NEWS_PREFERED_COMPANY_LIST, payload: ListData });
   } catch (err) {
      dispatch({ type: NEWSCOMPANYSEARCHSUCCESS, payload: false });
   }
};

export const get_prefered_product = () => async (dispatch) => {
   try {
      dispatch({ type: NEWSPRODUCTSEARCHSUCCESS, payload: true });

      const res = await axios.post(`${BASE_URL}/api/v2/preferred/product`, { "category": "news" }, { withCredentials: true });
      let ListData = [];
      if (res.data != undefined) {
         ListData = res.data.data;
      }
      
      dispatch({ type: NEWSPRODUCTSEARCHSUCCESS, payload: false });
      dispatch({ type: NEWS_PREFERED_PRODUCT_LIST, payload: ListData });
   } catch (err) {
      dispatch({ type: NEWSPRODUCTSEARCHSUCCESS, payload: false });
   }
}; */

export const get_prefered_product_company = () => async (dispatch) => {
   try {
      dispatch({ type: NEWSPRODUCTSEARCHSUCCESS, payload: true });

      const res = await axios.post(`${BASE_URL}/api/v2/preferred/product/company`, { "category": "news" }, { withCredentials: true });
      let ListPData = [];
      let ListCData = [];
      if (res.data != undefined) {
         ListPData = res.data.data['most_tagged_products'];
         ListCData = res.data.data['most_tagged_companies'];
      }

      dispatch({ type: NEWSPRODUCTSEARCHSUCCESS, payload: false });
      dispatch({ type: NEWS_PREFERED_PRODUCT_LIST, payload: ListPData });
      dispatch({ type: NEWS_PREFERED_COMPANY_LIST, payload: ListCData });

   } catch (err) {
      dispatch({ type: NEWSPRODUCTSEARCHSUCCESS, payload: false });
   }
}; 