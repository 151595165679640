import React, { Component } from 'react';
import { Accordion, Dropdown, Search, Label, Menu } from 'semantic-ui-react'
import SvgIcon from '../../svg-icon/svg-icon';
import { BASE_URL, FRESH_CHAT_TOKEN } from '../../../../config/config';
import axios from 'axios';
import './navbar.scss';
import SignInView from '../../../authentication/signin/signin';
import { Link } from 'react-router-dom';
import Newlogo from '../../../../assets/images/mappes.io-logo.png';
import MediaQuery from 'react-responsive';
import { replaceSpaceInName } from '../../../../common/helperMethods';
/* import { Freshchat } from 'reactjs-freshchat';
import 'reactjs-freshchat/dist/index.css' */
import { isDesktop, isMobile } from 'react-device-detect';
import BottomMenuBar from './bottomMenuBar';
import MobileSideBar from './MobileSideBar';
import GlobalSearch from './gloabalSearch';

const options = [
    { key: 1, text: 'By Company', value: 'company' },
    { key: 2, text: 'By Product', value: 'product' },
];

const resultRenderer = result => {
    if (result && result._source && result._source.id)
        return (
            <div key={result._source.id}>
                {result._index === 'products' && <Label content={result._source.name} />}
                {result._index !== 'products' && result._source.country == undefined && <Label content={result._source.name} />}
                {result._index !== 'products' && result._source.country != undefined && <Label content={result._source.name + ' (' + result._source.country + ')'} />}
                {result._index === 'products' && <Label style={{ float: 'right' }} content={'Product'} />}
                {result._index !== 'products' && <Label style={{ float: 'right' }} content={'Company'} />}
            </div>
        );
};

class NavbarPreLogin extends Component {
    constructor(props) {
        super(props);
        this.state = {
            searchCategory: 'product',
            set_open: false,
            isActiveSerach: false,
            current_filter: '',

        };
    }

    resetComponent = () => this.setState({ isLoading: false, results: [], value: '' });

    componentDidMount() {
        console.log('this.props.auth ', this.props.auth)
        this.resetComponent()
        setTimeout(() => {
            this.setState({ set_open: true })
        }, 4000);
    }

    handleClick = (e, titleProps) => {
        const { index } = titleProps
        const { activeIndex } = this.state
        const newIndex = activeIndex === index ? -1 : index

        this.setState({ activeIndex: newIndex })
    }



    render() {
        const { isLoading, value, results, hidden, hiddenUser, activeIndex, isActiveSerach, current_filter } = this.state;

        const productData = [{
            key: 1,
            name: "Optical Fiber",
            link: "/product/Single-Mode-Optical-Fiber/SkW9l-VnJRsG"
        },
        {
            key: 2,
            name: "PhotoVoltaic",
            link: "/product/Solar-cells/ryh4YXLfWP6DX"
        },
        {
            key: 3,
            name: "UV/EB Curing",
            link: "/product/Uv-Curing-Systems/B1lAQ48Mbwavm"
        },
        {
            key: 4,
            name: "Lithium-Ion",
            link: "/product/Lithium-ion-battery-Cell/SyG_WFgFX"
        },
        {
            key: 5,
            name: "Additive Manufacturing",
            link: "/product/3D-Printer/rkxIVP6OrI"
        },
        {
            key: 6,
            name: "Carbon Fiber",
            link: "/product/Carbon-Fibers/S1aD-N21Ciz"
        },
        {
            key: 7,
            name: "Semiconductor",
            link: "/product/Integrated-Circuit-ic-Or-Chip/rkdtOZ8MZPavm"
        },
        {
            key: 8,
            name: "Green Hydrogen",
            link: "/product/Green-Hydrogen/S1mF5ohWK"
        }
        ]
        return (
            <React.Fragment>
                {/* <span>
                    {this.state.set_open && isDesktop ? (
                        <span><Freshchat token={FRESH_CHAT_TOKEN} open={true} /> </span>
                    ) : ''}
                    {isMobile ? (
                        <span><Freshchat token={FRESH_CHAT_TOKEN} open={false} /> </span>
                    ) : ''}

                </span> */}
                <div className="mainheader">
                    <div className="left-menu">
                        <div className="leftmenu-inner">
                            <div className="menu-icon">
                                <SvgIcon name="menu-icon" width="20" viewbox="0 0 20.656 18" />
                            </div>
                            <div className="menu-list">
                                <Accordion>
                                    <Link to='/'>
                                        <Accordion.Title >
                                            Home
                                        </Accordion.Title>
                                    </Link>
                                    <a href='https://blog.mappes.io'>
                                        <Accordion.Title>
                                            Blog
                                        </Accordion.Title>
                                    </a>
                                    <Accordion.Title index={0} onClick={this.handleClick} active={activeIndex === 0}>
                                        Communities
                                        <SvgIcon className="icon dropdown" name="menu-arrow" viewbox="0 0 23.619 13.503" />
                                    </Accordion.Title>
                                    <Accordion.Content active={activeIndex === 0}>
                                        {productData.map((product, i) => (
                                            <Link to={product.link}>
                                                <Accordion.Title >
                                                    {product.name}
                                                </Accordion.Title>
                                            </Link>
                                        ))}

                                    </Accordion.Content>
                                    <Link to='/aboutus'>
                                        <Accordion.Title >
                                            About
                                        </Accordion.Title>
                                    </Link>
                                    {/* <Link to='/pricing'>
                                        <Accordion.Title>
                                            Pricing
                                        </Accordion.Title>
                                    </Link> */}
                                </Accordion>
                            </div>
                        </div>
                    </div>
                    <div className='prelogged-centerheader'>
                        <div className="center-logo">
                            <div className="lg-device">
                                <Link to='/' Title='A Manufacturing Community'>
                                    <SvgIcon name="logo" viewbox="0 0 907 93" />
                                    {/* <img alt={Newlogo} src={Newlogo} /> */}
                                </Link>
                            </div>
                            <div className="small-device">
                                <Link to='/'>
                                    <SvgIcon name="logo" viewbox="0 0 907 93" />
                                </Link>
                            </div>
                        </div>
                        <div className="searchHolder landingSearchBarWrapper h-100">
                            <GlobalSearch {...this.props} />
                        </div>
                        {window.location.pathname !== '/' && window.location.pathname !== '/aboutus' ? (
                            <BottomMenuBar auth={false} history={this.props.history} />
                        ) : null}
                    </div>
                    <div className="right-action">
                        {/* <Link to="/signup" className="btn btn-joinnow mr-3">Join Now</Link> */}
                        <MediaQuery minWidth={767}>
                            <Link to="/otp-login" className="btn btn-signin">Login in / Sign up</Link>
                        </MediaQuery>
                        <MediaQuery maxWidth={767}>
                            <Menu.Item className="navbar-profile-pic">
                                <MobileSideBar {...this.props} />
                            </Menu.Item>
                        </MediaQuery>
                        {/* <SignInView location={this.props.location} showModal={this.props.common.showModal} showLoginModal={this.props.showLoginModal} /> */}
                    </div>
                </div>
                {this.props.children}
                {/*  {this.props.common.showModal
                    &&
                   
                } */}
            </React.Fragment>
        );
    }
}



export default NavbarPreLogin;
