import React from 'react'
import { Grid, Button, Divider, Card, Image, Icon, Popup, Transition, Feed, Dimmer, Loader } from 'semantic-ui-react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import LayoutArticleEditor from '../../common/layout/LayoutArticleEditor';
import { getUserInfo, networkFeedActions, commonActions } from '../../../actions';
import { SvgIcon } from '../../common';
import './index.scss';
import SEO from '../../seo/SEO';
import ArticleDetails from './article_view_details';
import { replaceSpaceInName } from '../../../common/helperMethods';


class ArticlesView extends React.Component {
    constructor(props) {
        super(props)
        this.state = {
            articleText: '',
            imageUrl: '',
            videoPlayerLink: '',
        }
    }

    componentDidMount(prevProps) {
        if (this.props.match.params.postId) {
            this.getPostDetails()
        }
    }


    getPostDetails() {
        const { postId } = this.props.match.params;
        const { getPostDetails, getLikedPosts, auth } = this.props;
        getPostDetails(postId);

        auth && getLikedPosts(auth.id);
    }

    render() {
        const { auth, userInfoCollection, networkFeedUsers, removePost, entityId, likedPosts, feedType, postDetails, history } = this.props;
        if (!postDetails) return (
            <span>
                <Dimmer active>
                    <Loader indeterminate>Please Wait.!</Loader>
                </Dimmer>
            </span>
        )
        return (
            <>
                <LayoutArticleEditor>
                    <Grid.Column width={16}>
                        <SEO robots="index, follow"
                            canonicalUrl={`/article/${replaceSpaceInName(postDetails.Item.title)}/${postDetails.Item.id}`}
                            pageType={'article'}
                            pageContent={postDetails.Item}
                        />
                        <div className='article-details'>
                            <ArticleDetails
                                article={postDetails.Item}
                                auth={auth}
                                userInfoCollection={userInfoCollection}
                                networkFeedUsers={networkFeedUsers}
                                removePost={removePost}
                                feedType={feedType}
                                entityId={entityId}
                                likedPosts={likedPosts}
                                history={history} />
                        </div>
                    </Grid.Column>
                </LayoutArticleEditor>
            </>
        )
    }
}


const mapStateToProps = (state) => {
    return { ...state.networkFeed, ...state.user, ...state.likedPosts, auth: state.auth }
}
const mapDispatchToProps = dispatch => {
    return {
        ...bindActionCreators(networkFeedActions, dispatch)
    }
}
export default connect(mapStateToProps, mapDispatchToProps)(ArticlesView);