import React, { Component } from 'react';
import { Grid, Button, Container, List, TextArea, Form } from 'semantic-ui-react'
import MediaQuery from 'react-responsive'
import Navbar from '../common/layout/navbar/Navbar';
import { SvgIcon } from '../common';
import LeftSidebar from '../common/LeftSidebar';
import RightSection from '../common/RightSection';
import "./select-mail.scss";

class SelectMailMessage extends Component {
    constructor(props) {
        super(props);
        this.state = { file: null };
        this.onChangeFile = this.onChangeUploaifFile.bind(this);
        this.resetFileFile = this.resetFileUploadFile.bind(this);
    }
    onChangeUploaifFile(event) {  this.setState({ file: event.target.files[0].name }); }
    resetFileUploadFile(event) { event.preventDefault(); this.setState({ file: null }); }

    render() {
        return ( 
            <Navbar {...this.props}>
            <div className="primary-background">
                <Container className="body-background palm-nudge-sides">
                    <Grid padded stackable>
                        <MediaQuery minWidth={768}>
                            <Grid.Column className="left-content">
                                <LeftSidebar />
                            </Grid.Column>
                        </MediaQuery>
                        <Grid.Column className="main-content-area">
                            <Form>
                                <div className="main-content pD-b-50">
                                    <div className="main-content_inner tocontact-wrapper">
                                        <Grid padded className="background-white">
                                            <Grid.Row className="extra-padding-sides pt-2 pb-2">
                                                <Grid padded className="full-width">
                                                    <Grid.Column className="pb-2">
                                                        <span className="product-name-mail">Single Mode Optical Fiber</span>
                                                    </Grid.Column>
                                                </Grid>
                                            </Grid.Row>
                                        </Grid>
                                        <div id="abc" padded className="background-white px-2">
                                            <Grid className="px-3">
                                                <Grid.Row className="mt-2">
                                                    <Grid.Column width={3}>
                                                        To Contacts
                                                    </Grid.Column>
                                                    <Grid.Column width={13}>
                                                        <div className="to-contactlist">
                                                            <List horizontal>
                                                                <List.Item href='#'><div className="clst-inner">Sterlite Technologies Ltd  <SvgIcon name="close" viewbox="0 0 23.126 23.126" /></div></List.Item>
                                                                <List.Item href='#'><div className="clst-inner">Aksh Optifibre Limited <SvgIcon name="close" viewbox="0 0 23.126 23.126" /></div></List.Item>
                                                                <List.Item href='#'><div className="clst-inner">Vindhya Telelinks Ltd <SvgIcon name="close" viewbox="0 0 23.126 23.126" /></div></List.Item>
                                                                <List.Item href='#'><div className="clst-inner">Brila Cable Limited <SvgIcon name="close" viewbox="0 0 23.126 23.126" /></div></List.Item>
                                                                <List.Item href='#'><div className="clst-inner">Paramount Wires And Cables Ltd <SvgIcon name="close" viewbox="0 0 23.126 23.126" /></div></List.Item>
                                                                <List.Item href='#'><div className="clst-inner">Aksh Optifibre Limited <SvgIcon name="close" viewbox="0 0 23.126 23.126" /></div></List.Item>
                                                                <List.Item href='#'><div className="clst-inner">Himachal Futuristic Communications Ltd <SvgIcon name="close" viewbox="0 0 23.126 23.126" /></div></List.Item>
                                                                <List.Item href='#'><div className="clst-inner">Paramount Wires And Cables Ltd <SvgIcon name="close" viewbox="0 0 23.126 23.126" /></div></List.Item>
                                                            </List>
                                                            <div className="expand-full">Expand for full list</div>
                                                        </div>
                                                    </Grid.Column>
                                                </Grid.Row>
                                                <div className="w-100">
                                                    <label>Subject Line</label>
                                                    <div className="ui input large w-100 form-input mb-3">
                                                        <input type="text" placeholder="" name="subjectline" />
                                                    </div>
                                                </div>
                                                <div className="w-100">
                                                    <label>Message</label>
                                                    <div className="ui input large w-100 form-input mb-3">
                                                        <TextArea rows="6" placeholder="" />
                                                    </div>
                                                </div>
                                                <div className="col-sm-5">
                                                    <label>Mobile Phone Number (Optional)</label>
                                                    <div className="ui input large w-100 form-input mb-3">
                                                        <input type="text" placeholder="" name="mobilenumber" />
                                                    </div>
                                                </div>
                                                <div className="w-100">
                                                    <label>Upload attachment Documents</label>
                                                    <div className="ui input large w-100 mb-1">
                                                        <div className="uploadWrapper">
                                                            {this.state.file && (
                                                                <div className="remove-icon" onClick={this.resetFileFile}><SvgIcon name="close" viewbox="0 0 23.126 23.126" /></div>
                                                            )}
                                                            <div className="uploaded-inner">
                                                                <div className="upload-btn">
                                                                    <div className="upload-btn-inner">
                                                                        <SvgIcon name="upload" viewbox="0 0 512.056 512.056" />
                                                                        <p>Drag and Drop file here or click</p>
                                                                        <input type="file" onChange={this.onChangeFile} />
                                                                    </div>
                                                                </div>
                                                                {this.state.file && (
                                                                    <div className="upload-preview">
                                                                        {this.state.file}
                                                                    </div>
                                                                )}
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <i>Attachment can be PDF, Word Doc, or Excel (maximum size 5mb)</i>
                                                </div>
                                                <div className="mt-4">
                                                    <Button className="px-5" primary>Request Quote</Button>
                                                </div>
                                            </Grid>
                                        </div>
                                    </div>
                                </div>
                            </Form>
                        </Grid.Column>
                        <RightSection entityType="company" />
                    </Grid>
                </Container>
            </div>
            </Navbar>
        )
    }

}

 
export default SelectMailMessage;