import React, { useEffect, useState} from 'react';
import { Button, Modal, Form, Message, Select, Dropdown } from 'semantic-ui-react';
import { apiStatus } from '../../common/constants';
import { commonActions, companyActions, productActions } from '../../actions';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { countryList } from '../../common/countries';
import { SvgIcon } from '../common';

const EditCompany = (props) => {
    const [open, setOpen] = useState(false)
    const [name, setName] = useState("")
    const [address, setAddress] = useState("")
    const [url, setUrl] = useState("")
    const [country, setCountry] = useState("")
    const [description, setDescription] = useState("")
    const [name_error, setNameError] = useState("")
    const [country_error, setCountryError] = useState("")

    const { company, updateCompanyDetails, saveStatus } = props;

    useEffect(() => {
        const { description, name, country, address, url } = company;
        setName(name)
        setAddress(address)
        setUrl(url)
        setCountry(country)
        setDescription(description)
        setNameError(name_error)
        setCountryError(country_error)

    }, [company])

    useEffect(() => {
        if (saveStatus === apiStatus.COMPLETED) {
            handleClose(false)
        }
    }, [saveStatus])


    const updateDetails = () => {
        let has_errors = false;
        const companyDetails = {
            name,
            description,
            url,
            address,
            country
        }

        if (companyDetails.name == "" || companyDetails.name == null || companyDetails.name == undefined) {
            has_errors = true;
        }


        if (companyDetails.country == "" || companyDetails.country == null || companyDetails.country == undefined) {
            has_errors = true;
        }

        if (has_errors == false) {
            updateCompanyDetails(company.id, companyDetails)
        }

    }

    const handleClose = () => {
        props.setSaveStatus(apiStatus.UNDEFINED);
        setOpen(false);
    }

    const handleValidation = (field, value) => {
        if (value == '' || value == undefined || value == null) {
            if (field == 'name') {
                setNameError('Name is required')
            }

            if (field == 'country') {
                setCountryError('Country is required')
            }
        }
    }

    const formatInput = (event) => {
        //   setNameError('')
        const attribute = event.target.getAttribute('name')
        setName(event.target.value.trim())
        handleValidation('name', event.target.value.trim())
    }

    const nameInput = (e) => {
        setNameError('')
        setName(e.target.value)
        handleValidation('name', e.target.value.trim())
    }

    const countryInput = (e, { value }) => {
        setCountryError('')
        setCountry(value.trim())
        handleValidation('country', value.trim())
    }

    return (
        <Modal
            className="createa-supplier"
            onClose={() => setOpen(false)}
            onOpen={() => setOpen(true)}
            open={open}
            size="small"
            trigger={
                // <Button id="company-info-edit" className="edit-btn" basic primary size="mini">Edit info</Button>
                <Dropdown.Item icon={<SvgIcon name="edit" viewbox="0 0 31.5 31.5" />} text='Edit Info' />
            }
        >
            <Modal.Content>
                <Modal.Description>
                    <div className="mb-3"><b>Edit Company</b></div>
                    <Form>
                        <Form.Field>
                            <label>Name</label>
                            <input name="name" placeholder='Company Name' onBlur={formatInput} value={name} onChange={nameInput} />
                            <span className='text-danger'> {name_error ? name_error : ''}</span>

                        </Form.Field>
                        <Form.Field>
                            <label>Address</label>
                            <input name="address" placeholder='Company Address' value={address} onChange={(e) => setAddress(e.target.value)} />
                        </Form.Field>
                        <Form.Field>
                            <label>Url</label>
                            <input name="url" placeholder='Company Url' value={url} onChange={(e) => setUrl(e.target.value)} />
                        </Form.Field>
                        <Form.Field>
                            <label>Country</label>
                            <Select
                                search
                                name="country"
                                placeholder='Select your country'
                                options={countryList}
                                onChange={countryInput}
                                value={country}
                            />
                            <span className='text-danger'> {country_error ? country_error : ""}</span>

                        </Form.Field>
                        <Form.Field>
                            <label> Description</label>
                            <textarea name="description" rows="8" placeholder='Tell us about the company' value={description} onChange={(e) => setDescription(e.target.value)} />
                        </Form.Field>
                        {
                            saveStatus === apiStatus.ALREADY_EXISTS ?
                                <span>
                                    <div className="text-left mt-2 mb-2">
                                        <Message className="reduce-msg-padding" compact visible error content={`Company Already Exists `} />
                                    </div>
                                </span>
                                :
                                <span></span>
                        }
                        <div className="text-right mt-5">
                            <Button type='submit' onClick={handleClose}>Cancel</Button>
                            <Button className="ml-1" type='submit' primary onClick={updateDetails} loading={saveStatus === apiStatus.IN_PROGRESS}>Save</Button>
                        </div>
                    </Form>
                </Modal.Description>
            </Modal.Content>
        </Modal>
    )
}

const mapStateToProps = ({ common }) => {
    return {
        saveStatus: common.saveStatus
    };
}

const mapDispatchToProps = (dispatch) => {
    return {
        dispatch,
        ...bindActionCreators({ ...companyActions, productActions, ...commonActions }, dispatch),
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(EditCompany);
