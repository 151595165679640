import React from 'react';
import { BASE_URL } from '../../../config/config'
import axios from 'axios';
import { Search, List, Button, Label } from 'semantic-ui-react'
import { SvgIcon } from '../../common';

class TagProductAndCompany extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            results: [],
            taggedItems: [],
            isLoading: false,
            displayName: '',
            image: ''
        }
    }

    resultRenderer = (result) => <Label key={result._source.id} content={result._source.name} />

    handleResultSelect = (e, { result }) => {
        const { searchType, setTaggedCompanies, setTaggedProducts } = this.props;
        let newRaw = this.state.taggedItems;
        newRaw.push({
            id: result._source.id,
            name: result._source.name
        })
        this.setState({
            taggedItems: newRaw,
            value: "",
            description: ""
        })

        if (searchType === "product") {
            setTaggedProducts(newRaw.map(item => item.id))
        }
        else {
            setTaggedCompanies(newRaw.map(item => item.id))
        }
    }

    handleSearchChange = (e, { value }) => {
        const { searchType } = this.props;
        this.setState({ value })
        if (value.length > 4) {
            this.setState({ isLoading: true })
            axios.get(`${BASE_URL}/api/v1/${searchType}/search/${value}`, { withCredentials: true })
                .then(response => {
                    this.setState({
                        isLoading: false,
                        results: response.data
                    })
                })
        }
    }

    renderSearchResults() {
        return this.state.taggedItems.map((item, index) => {
            return (
                <List.Item key={index}>
                        {item.name}
                        <Button className="tagremove-btn" onClick={this.removeItem.bind(this, item.id, item.name)}>
                            <SvgIcon name="close" viewbox="0 0 23.126 23.126" />
                        </Button>
                </List.Item>
            )
        })
    }

    removeItem(id, name) {
        const { searchType, setTaggedCompanies, setTaggedProducts } = this.props;
        let taggedItemsTemp;

        if (id) {
            taggedItemsTemp = this.state.taggedItems.filter(e => e.id !== id)
        }
        else {
            taggedItemsTemp = this.state.taggedItems.filter(e => e.name !== name)
        }

        this.setState({
            taggedItems: taggedItemsTemp
        })

        if (searchType === "product") {
            setTaggedProducts(taggedItemsTemp.map(item => item.id))
        }
        else {
            setTaggedCompanies(taggedItemsTemp.map(item => item.id))
        }
    }

    render() {
        const { value, results, isLoading } = this.state;

        return (
            <span>
                <Search
                    fluid
                    loading={isLoading}
                    onResultSelect={this.handleResultSelect}
                    onSearchChange={this.handleSearchChange}
                    results={results}
                    value={value}
                    resultRenderer={this.resultRenderer}
                />
                <List className='tags-selected-list' animated divided verticalAlign='middle'>
                    {this.renderSearchResults()}
                </List>
            </span>
        )
    }
}

export default TagProductAndCompany;