import axios from 'axios'
import {
    USER_IMAGE_UPLOAD, USER_PROFILE_DETAILS, USER_VENDORS_COUNT, USER_CONNECTIONS_COUNT, USER_CUSTOMERS_COUNT, USER_CONNECTIONS, USER_VENDORS, USER_CUSTOMERS, USER_FOLLOWED_COMPANIES, USER_UPDATE_RESPONSE, USER_SUGGESTED_COMPANY
} from '../../config/types'
import { BASE_URL } from '../../config/config'
import { getUserInfo } from '../index'
import { fetchUser } from '../auth/auth';
import { setSaveStatus } from '../common/common';
import { apiStatus } from '../../common/constants';

export const uploadUserImage = (file) => async (dispatch, getState) => {
    try {

        dispatch({ type: USER_IMAGE_UPLOAD, payload: "uploading" })
        const bodyFormData = new FormData();
        bodyFormData.append("userImage", file);
        let res = await axios.post(`${BASE_URL}/api/v1/user/image`, bodyFormData, {
            headers: {
                'content-type': 'multipart/form-data'
            },
            withCredentials: true
        })
        const userId = getState().auth.id;
        dispatch(getUserInfo(userId));
        dispatch(fetchUser());
        dispatch({ type: USER_IMAGE_UPLOAD, payload: "uploaded" });
    } catch (err) {
        console.log(err);
    }
};

export const getUserProfileDetails = () => async (dispatch) => {
    try {

        const res = await axios.get(`${BASE_URL}/api/v2/me`, { withCredentials: true });
        dispatch({ type: USER_PROFILE_DETAILS, payload: res.data })
        dispatch(setSaveStatus(apiStatus.UNDEFINED));
    } catch (err) {
        console.log(err);
    }

}
export const getVendorsCount = () => async dispatch => {
    try {

        const res = await axios.get(`${BASE_URL}/api/v2/me/vendors/count`, { withCredentials: true })
        dispatch({ type: USER_VENDORS_COUNT, payload: res.data.count })
    } catch (err) {
        console.log(err);
    }
}

export const getConnectionsCount = () => async dispatch => {
    try {

        const res = await axios.get(`${BASE_URL}/api/v2/me/connections/count`, { withCredentials: true })
        dispatch({ type: USER_CONNECTIONS_COUNT, payload: res.data.count })
    } catch (err) {
        console.log(err);
    }
}

export const getCustomersCount = () => async dispatch => {
    try {
        const res = await axios.get(`${BASE_URL}/api/v2/me/customers/count`, { withCredentials: true })
        dispatch({ type: USER_CUSTOMERS_COUNT, payload: res.data.count })

    } catch (err) {
        console.log(err);
    }
}

export const getVendors = () => async dispatch => {
    try {

        const res = await axios.get(`${BASE_URL}/api/v2/me/vendors`, { withCredentials: true })
        var vendors = [];
        if (res.data) {
            vendors = res.data.items.sort((a, b) => b.company_contacts - a.company_contacts)
        }
        dispatch({ type: USER_VENDORS, payload: vendors });
    } catch (err) {
        console.log(err);
    }
}

export const getConnections = () => async dispatch => {
    try {

        const res = await axios.get(`${BASE_URL}/api/v2/me/connections`, { withCredentials: true })
        dispatch({ type: USER_CONNECTIONS, payload: res.data.items })
    } catch (err) {
        console.log(err);
    }
}

export const getCustomers = () => async dispatch => {
    try {

        const res = await axios.get(`${BASE_URL}/api/v2/me/customers`, { withCredentials: true })
        var customers = [];
        if (res.data) {
            customers = res.data.items.sort((a, b) => b.customer_contacts - a.customer_contacts)
        }
        dispatch({ type: USER_CUSTOMERS, payload: customers })
    } catch (err) {
        console.log(err);
    }
}

export const getFollowedCompanies = () => async dispatch => {
    try {

        const res = await axios.get(`${BASE_URL}/api/v1/me/followedCompanies`, { withCredentials: true })
        dispatch({ type: USER_FOLLOWED_COMPANIES, payload: res.data })
    } catch (err) {
        console.log(err);
    }
}

export const getSuggestedCompany = (email_id) => async (dispatch) => {
    try {

        const res = await axios.post(`${BASE_URL}/api/v2/company/suggestion`, { email_id }, { withCredentials: true });
        dispatch({ type: USER_SUGGESTED_COMPANY, payload: res.data.data });
    } catch (err) {
        console.log(err);
    }
}

export const updateUserProfile = (userProfileDetails) => async (dispatch) => {
    try {
        dispatch(setSaveStatus(apiStatus.IN_PROGRESS));
        const res = await axios.put(`${BASE_URL}/api/v2/me`, { ...userProfileDetails }, { withCredentials: true });
        dispatch(setSaveStatus(apiStatus.COMPLETED));
        dispatch({ type: USER_UPDATE_RESPONSE, payload: res })
        setTimeout(() => {
            dispatch(setSaveStatus(apiStatus.UNDEFINED));
        }, 300);

    } catch (err) {
        console.log('err', err)
        console.log('err 1 ', err.response)

        setTimeout(() => {
            dispatch(setSaveStatus(apiStatus.UNDEFINED));
            dispatch({ type: USER_UPDATE_RESPONSE, payload: err.response.data.message })

        }, 100);
    }
    //return res;
}

export const deactivateAccount = (callback) => async dispatch => {
    try {
        const res = await axios.post(`${BASE_URL}/api/v1/user/deactivateAccount`, {}, { withCredentials: true })
        if (typeof (callback) === "function") {
            callback();
        }
        dispatch({ type: USER_CONNECTIONS, payload: res.data.items })
    }
    catch (error) {
        console.log(error);
        callback();
    }
}