import { combineReducers } from 'redux'
// import { reducer as formReducer} from 'redux-form'

import authReducer from './auth/authReducer'
import chatReducer from './auth/chatReducer'
import productReducer from './product/productReducer'
import companyReducer from './company/company'
import singleProductReducer from './product/singleProductReducer'
import userReducer from './user/userReducer'
import networkFeedReducer from './networkFeed/networkFeed';
import eventsReducer from './events/event';
import RequestQuote from './requestquote/requestquote';
import commonReducer from './common/common';
import registrationReducer from './registration/registrationReducer';
import newsListReducer from './newslist/newsList';

export default combineReducers({
    auth: authReducer,
    product: productReducer,
    singleProduct: singleProductReducer,
  //  form: formReducer,
    user: userReducer,
    chatUser: chatReducer,
    singleCompany: companyReducer,
    networkFeed: networkFeedReducer,
    events: eventsReducer,
    newsList: newsListReducer,
    reqyestQuote: RequestQuote,
    common: commonReducer,
    registration: registrationReducer
})
