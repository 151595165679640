import React from 'react';
import { Button } from 'semantic-ui-react';

// import NoSuppliers from '../../assets/images/no-suppliers.svg';
import NoSuppliersSvg from '../../../assets/images/no-suppliers.svg';

function NoSuppliers({ setShowAddSupplier }) {
    return (
        <React.Fragment>
            <div className="no-suppliers">
                <img alt={NoSuppliersSvg} src={NoSuppliersSvg} />
                <b>So far, no suppliers have been found</b>
                <p className="mb-4">To grow the network, please add suppliers of the product</p>
                <Button primary onClick={() => setShowAddSupplier(true)}>Add Suppliers</Button>
            </div>
        </React.Fragment>
    )
}

export default NoSuppliers;