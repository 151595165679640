import React from 'react';
import * as actions from '../../../actions';
import { connect } from 'react-redux';
import { Grid, Header, Button, DropdownMenu, TextArea, Form, Feed, Modal, Dropdown, DropdownItem, Image, Transition, Progress } from 'semantic-ui-react';
import CustomRickEditor from './NewsRichEditor';
import FileUploadControl from '../../common/FileUploadControl';
import Previewer from './Previewer';
import MediaQuery from 'react-responsive'
import { SvgIcon } from '../../common';

import './feed-new.scss';

// import fileImg1 from '../../../assets/images/post-img1.jpg';
// import fileImg2 from '../../../assets/images/post-img2.jpg';
// import fileImg3 from '../../../assets/images/post-img3.jpg';

const Name = props => {
    return props.auth ? <div className="profile-name">{props.auth.displayName}</div> : <span className="hide-menu">Loading...</span>;
};

class Popup_PostEditor extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            text: '',
            postMediaType: '',
            postMediaUrl: '',
            filaname: '',
            filevalue: '',
            fileReadCompleted: false,
            showFileUploadControl: false
        };
        this.handleVisibility = this.handleVisibility.bind(this);
        this.handleFileUpload = this.handleFileUpload.bind(this);
        this.setWrapperRef = this.setWrapperRef.bind(this);
        this.setPostText = this.setPostText.bind(this);
        this.assemblePost = this.assemblePost.bind(this);
        this.previewPostMedia = this.previewPostMedia.bind(this);
        this.fileInput = React.createRef();
    }

    componentWillReceiveProps(newProps) {
        if ((newProps.postStatus !== this.props.postStatus && newProps.postStatus === 'created') || newProps.feedType !== this.props.feedType) {
            this.setState({ text: '' });
        }

        if (newProps.postStatus !== this.props.postStatus && newProps.postStatus === "created") {
            this.setState({ showFileUploadControl: false })
        }
    }

    handleVisibility(e) {
        this.setState({ showFileUploadControl: !this.state.showFileUploadControl });
        this.setState({ label: e.target.name === "pdfOrPpt" ? "Upload your pdf" : "Upload your photo or video" });
    }

    setWrapperRef = node => {
        this.wrapperRef = node;
    };

    trim_blank_tag(value) {

        /*   var regex = /<[\S]+><\/[\S]+>/;
          let result = value.replace(regex, ""); */
        let result = value;
        return result;
    }

    setPostText(event) {
        let postText = event.target.value.trim();
        postText = this.trim_blank_tag(postText);
        this.setState({ text: postText });
        let isYouTubeVideo = postText.indexOf('https://www.youtube.com') > -1;
        if (isYouTubeVideo) {
            this.props.previewPostMedia('youtube', postText);
        } else {
            //hide Previewer
            this.props.previewPostMedia('', '');
        }
        this.props.assemblePost('text', postText);
    }

    triggerInputFile = () => {
        this.fileInput.click()
    }

    handleFileUpload(event) {
        let file = event.target.files[0];
        if (file) {

            let fileType = file.type;
            let name;
            console.log("fileType ", fileType);
            if (fileType.indexOf("image") > -1) name = "image";
            else if (fileType.indexOf("video") > -1) name = "video";
            else if (fileType.indexOf("pdf") > -1) name = "pdf";
            else if (fileType.indexOf("powerpoint") > -1) name = "ppt";
            else if (fileType.indexOf("presentation") > -1) name = "ppt";

            let reader = new FileReader();
            this.props.assemblePost(name, file);
            this.setState({
                filaname: name,
                filevalue: file
            });
            let self = this;
            reader.onload = function (e) {
                self.props.previewPostMedia(name, e.target.result);
                self.previewPostMedia(name, e.target.result);

                self.setState({ fileReadCompleted: true, showFileUploadControl: false })
            };
            reader.readAsDataURL(file);
        }

    }

    changeSelectedFile = () => {
        //show file upload control
        this.setState({ showFileUploadControl: true, fileReadCompleted: false, });

        //hide previewer
        this.props.previewPostMedia('', '');
        this.previewPostMedia('', '');
    }

    previewPostMedia(postMediaType, postMediaUrl) {
        this.setState({
            postMediaType: postMediaType,
            postMediaUrl: postMediaUrl
        })
    }

    assemblePost(name, value) {
        this.setState({ post: this.defaultPostState })

        const post = this.state.post;
        post[name] = value;
        this.setState({ post })
    }

    popup_tagged_products = (value) => {
        this.props.tagged_products(value);
    }

    popup_tagged_company = (value) => {
        this.props.tagged_company(value);
    }

    handleEditorText = (value) => {
        // console.log('handle value ', value)
        // console.log('handle trim value ', this.trim_blank_tag(value) )
        this.setState({ text: this.trim_blank_tag(value) });
        let isYouTubeVideo = value.indexOf('https://www.youtube.com') > -1;
        if (isYouTubeVideo) {
            this.props.previewPostMedia('youtube', value);
        } else {
            //hide Previewer
            this.props.previewPostMedia('', '');
        }
        this.props.assemblePost('text', this.trim_blank_tag(value));
    }

    clearInput = () => {
        this.setState({ text: '' });
        this.child.clearInput();
    }

    componentDidMount() {
        this.props.onRef(this);
    }

    componentWillUnmount() {
        this.props.onRef(undefined);
    }

    handleOpen = (status) => {
        this.setState({ popupOpen: status });
        if (status == true) {
            this.setState({ showFileUploadControl: false, fileReadCompleted: false, });
            this.previewPostMedia('', '');
            this.setState({
                filaname: '',
                filevalue: ''
            });
        }

    }

    submitPost() {
        if (this.state.text != '' || this.state.postMediaType != '' || this.state.postMediaUrl != '') {
            this.props.assemblePost(this.state.filaname, this.state.filevalue);
            this.props.previewPostMedia(this.state.postMediaType, this.state.postMediaUrl);
            setTimeout(() => {
                this.props.submitPost();
                this.handleOpen(false);

            }, 20);
        }

    }

    renderProfileImage() {
        const { auth, userProfileInfo } = this.props;
        var userInitials = auth.displayName ? auth.displayName.split(" ").map((n) => n[0]).join("").substring(0, 2) : '';
        if (userProfileInfo) {
            if (userProfileInfo.image && userProfileInfo.linkedinImage) {
                return (
                    <React.Fragment>
                        <Image
                            className="avatar-48"
                            avatar
                            src={userProfileInfo.image}
                            srcChanged={false}
                            onError={e => {
                                if (!e.target.srcChanged) {
                                    e.target.src = userProfileInfo.linkedinImage;
                                }
                                e.target.srcChanged = true;
                            }}
                        />
                    </React.Fragment>
                );
            } else if (userProfileInfo.image) {
                return (
                    <React.Fragment>
                        <Image className="avatar-42" avatar src={userProfileInfo.image} />
                    </React.Fragment>
                );
            } else if (userProfileInfo.linkedinImage) {
                return (
                    <React.Fragment>
                        <Image className="avatar-42" avatar src={userProfileInfo.linkedinImage} />
                    </React.Fragment>
                );
            }
        }
        return <div className='art-header-img'> <Feed.Label> {userInitials} </Feed.Label> </div>;
    }

    openEvent = (status) => {
        console.log('in here event')
        this.props.history.push(`/addevent`)
    }

    openArticle = (status) => {
        const { feedType, entityId } = this.props;
        var new_route = `/add-article`;
        if (feedType == 'product' || feedType == 'company') {
            new_route = new_route + '/' + feedType + '/' + entityId
        }

        this.props.history.push(new_route)
    }

    render() {
        // const { userProfileInfo, displayName } = this.props.auth;
        return (
            <Grid.Column width={16} >
                <div className='addarticle-top'>
                    <div className='upper-section'>

                        <div className="user-avtar-image mr-2">
                            {this.renderProfileImage()}
                        </div>

                        <MediaQuery minWidth={768}>
                            <Modal
                                className="add-post-modal-new"
                                onClose={() => this.handleOpen(false)}
                                onOpen={() => this.handleOpen(true)}
                                open={this.state.popupOpen}
                                style={{ maxWidth: '660px' }}
                                size='small'
                                trigger={<TextArea id="share-post" className="readonly" placeholder='Share articles, news, updates about your product or company' readonly="" />}
                            >
                                <Header>Create post <SvgIcon onClick={() => this.handleOpen(false)} className='close-icon' name='close-alt' viewbox='0 0 15 15.001' /></Header>
                                <Modal.Content>
                                    <Modal.Description>
                                        <Form>
                                            <div className='modal-upper'>
                                                <div className="d-flex mb-4 align-items-center">
                                                    <div className="user-avtar-image mr-2">
                                                        {this.renderProfileImage()}
                                                    </div>
                                                    <div className="profile-name"> <Name auth={this.props.auth} /></div>
                                                </div>
                                                <Form.Field>
                                                    <CustomRickEditor value={this.state.text} onEditorChange={this.handleEditorText} onRef={ref => (this.child = ref)} gettagged_products={this.popup_tagged_products} gettagged_company={this.popup_tagged_company} auth={this.props.auth} stripPastedStyles={true} toolbar={false} article={false} />
                                                </Form.Field>
                                                <p className='bottom-text'>Use # to tag products, and use @ to tag companies</p>
                                                <div className='fileupdated-view'>
                                                    {this.state.fileReadCompleted &&
                                                        <Button icon="delete" className="remove-btn" circular size="tiny" floated='right' onClick={this.changeSelectedFile}>
                                                            <SvgIcon name='close-alt' viewbox='0 0 15 15.001' />
                                                        </Button>
                                                    }

                                                    {this.state.showFileUploadControl &&
                                                        <Transition.Group animation={'slide down'} duration={300} >
                                                            <div className="mT-5 mB-20">
                                                                <label>{this.state.label}</label>
                                                                <FileUploadControl handleFileUpload={this.handleFileUpload} />
                                                            </div>
                                                        </Transition.Group>
                                                    }

                                                    <div className='imgviewer-uploaded'>
                                                        <Previewer type={this.state.postMediaType} url={this.state.postMediaUrl} postStatus={this.state.postStatus} />
                                                    </div>
                                                </div>
                                            </div>

                                            <div className="footer">
                                                {/* <Button className="ml-1" type='submit' onClick={() => this.handleOpen(false)}>Cancel</Button> */}
                                                <div className='left-footer'>
                                                    {/* <div className='smile-icon'>
                                                        <Button>
                                                            <SvgIcon name='smile-icon' viewbox='0 0 22 22' />
                                                        </Button>
                                                    </div> */}
                                                    <div>

                                                        {/* <Button><SvgIcon name='link-icon' viewbox='0 0 15.258 15.245' /></Button> */}
                                                        <Button className="file_upload_op" name="pdfOrPpt" onClick={this.handleVisibility} ><SvgIcon name='new-pdf-icon' viewbox='0 0 25 25' /></Button>
                                                        <Button className="file_upload_op" name="imageOrVideo" onClick={this.handleVisibility} ><SvgIcon name='addphoto-icon' viewbox='0 0 22 22' /></Button>
                                                        <Button className="file_upload_op" name="imageOrVideo" onClick={this.handleVisibility} ><SvgIcon name='addvideo-icon' viewbox='0 0 24 21' /></Button>
                                                    </div>
                                                </div>

                                                <Button id="submit-post" className="ml-1 post-btn" type='submit' primary onClick={() => this.submitPost()} >Post</Button>
                                            </div>
                                        </Form>
                                    </Modal.Description>
                                </Modal.Content>
                            </Modal>
                        </MediaQuery>

                        <MediaQuery maxWidth={767}>
                            <Modal
                                dimmer='inverted'
                                className="addpost-mobile"
                                onClose={() => this.handleOpen(false)}
                                onOpen={() => this.handleOpen(true)}
                                open={this.state.popupOpen}
                                size='small'
                                trigger={<TextArea id="share-post" className="readonly" placeholder='Share articles, news, updates, etc.' readonly="" />}
                            >
                                <Header className='modal-header'>
                                    <div className='header-left'>
                                        <SvgIcon onClick={() => this.handleOpen(false)} className='close-icon' name='close-alt' viewbox='0 0 15 15.001' /> Create a post
                                    </div>
                                    <div className='header-right'>
                                        <Dropdown floating icon={<SvgIcon className='headercamera-icon' name='camera-icon-alt' viewbox="0 0 16 14" />}>
                                            <DropdownMenu>
                                                <DropdownItem icon={<SvgIcon name='photo-icon' viewbox="0 0 18 18" />} text='Choose Photo or Video' onClick={this.triggerInputFile} />
                                                <DropdownItem icon={<SvgIcon name='new-pdf-icon' viewbox="0 0 25 25" />} text='Attach document' onClick={this.triggerInputFile} />
                                                <DropdownItem icon={<SvgIcon name='camera-icon-alt' viewbox="0 0 16 14" />} text='Take Photo or Video' onClick={this.triggerInputFile} />
                                            </DropdownMenu>
                                        </Dropdown>
                                        <div style={{ display: "none" }}>
                                            <input type='file' className='file-input' id="myfile" onChange={this.handleFileUpload} ref={ref => (this.fileInput = ref)} />
                                        </div>
                                        {/* add 'active' class for blue color */}
                                        <Button id="submit-post" className={"ml-1 post-btn " +  (this.state.text != "" || this.state.postMediaType != "" || this.state.postMediaUrl != "" ? "active" : "")} type='submit' onClick={() => this.submitPost()} >Post</Button>
                                    </div>
                                </Header>
                                <Modal.Content>
                                    <Form>
                                        <div className='modal-upper'>
                                            <Form.Field>
                                                <CustomRickEditor value={this.state.text} onEditorChange={this.handleEditorText} onRef={ref => (this.child = ref)} gettagged_products={this.popup_tagged_products} gettagged_company={this.popup_tagged_company} auth={this.props.auth} stripPastedStyles={true} toolbar={false} article={false} />
                                            </Form.Field>
                                            <p className='bottom-text'>
                                                Use <span>#</span> to tag products, and use <span>@</span> to tag companies
                                            </p>
                                            <div className='files-list'>
                                                <div className='filelist-item'>
                                                    <button className='close-btn'><SvgIcon name='close-alt' viewbox="0 0 15 15.001" onClick={this.changeSelectedFile} /></button>
                                                    {/*  <img src={fileImg1} alt='' /> */}
                                                    <Previewer type={this.state.postMediaType} url={this.state.postMediaUrl} postStatus={this.state.postStatus} />
                                                </div>
                                                {/* <div className='uploading-row'>
                                                        Uploading...
                                                        <Progress size='tiny' percent={45} color='blue' />
                                                    </div> */}

                                                {/* <div>
                                                    <div className='filelist-item'>
                                                        <button className='close-btn'><SvgIcon name='close-alt' viewbox="0 0 15 15.001" /></button>
                                                        <img src={fileImg2} alt='' />
                                                    </div>
                                                </div> */}
                                                {/* <div>
                                                    <div className='filelist-item'>
                                                        <button className='close-btn'><SvgIcon name='close-alt' viewbox="0 0 15 15.001" /></button>
                                                        <img src={fileImg3} alt='' />
                                                    </div>
                                                </div> */}
                                            </div>
                                        </div>
                                    </Form>
                                </Modal.Content>
                            </Modal>
                        </MediaQuery>

                    </div>
                    <div className='bottom-section'>
                        <Button onClick={() => this.openEvent(true)} ><SvgIcon name='calendar-icon' viewbox='0 0 17 18.667' /> Create <MediaQuery minWidth={767}> an </MediaQuery> event</Button>
                        <Button onClick={() => this.openArticle(true)}><SvgIcon name='file-alt' viewbox='0 0 15.333 18.667' /> Write <MediaQuery minWidth={767}> an </MediaQuery> article</Button>
                        <MediaQuery minWidth={767}>
                            <Modal
                                className="add-post-modal-new"
                                onClose={() => this.handleOpen(false)}
                                onOpen={() => this.handleOpen(true)}
                                open={this.state.popupOpen}
                                style={{ maxWidth: '660px' }}
                                size='small'
                                trigger={<Button className="create-post-button" id="create-post-button"><SvgIcon name='photo-icon' viewbox='0 0 18 18' /> Create Post</Button>}
                            >
                                <Header>Create post <SvgIcon onClick={() => this.handleOpen(false)} className='close-icon' name='close-alt' viewbox='0 0 15 15.001' /></Header>
                                <Modal.Content>
                                    <Modal.Description>
                                        <Form>
                                            <div className='modal-upper'>
                                                <div className="d-flex mb-4 align-items-center">
                                                    <div className="user-avtar-image mr-2">
                                                        {this.renderProfileImage()}
                                                    </div>
                                                    <div className="profile-name"> <Name auth={this.props.auth} /></div>
                                                </div>
                                                <Form.Field>
                                                    <CustomRickEditor value={this.state.text} onEditorChange={this.handleEditorText} onRef={ref => (this.child = ref)} gettagged_products={this.popup_tagged_products} gettagged_company={this.popup_tagged_company} auth={this.props.auth} stripPastedStyles={true} toolbar={false} article={false} />
                                                </Form.Field>
                                                <p className='bottom-text'>Use # to tag products, and use @ to tag companies</p>
                                                <div className='fileupdated-view'>
                                                    {this.state.fileReadCompleted &&
                                                        <Button icon="delete" className="remove-btn" circular size="tiny" floated='right' onClick={this.changeSelectedFile}>
                                                            <SvgIcon name='close-alt' viewbox='0 0 15 15.001' />
                                                        </Button>
                                                    }

                                                    {this.state.showFileUploadControl &&
                                                        <Transition.Group animation={'slide down'} duration={300} >
                                                            <div className="mT-5 mB-20">
                                                                <label>{this.state.label}</label>
                                                                <FileUploadControl handleFileUpload={this.handleFileUpload} />
                                                            </div>
                                                        </Transition.Group>
                                                    }

                                                    <div className='imgviewer-uploaded'>
                                                        <Previewer type={this.state.postMediaType} url={this.state.postMediaUrl} postStatus={this.state.postStatus} />
                                                    </div>
                                                </div>
                                            </div>

                                            <div className="footer">
                                                {/* <Button className="ml-1" type='submit' onClick={() => this.handleOpen(false)}>Cancel</Button> */}
                                                <div className='left-footer'>
                                                    {/* <div className='smile-icon'>
                                                        <Button>
                                                            <SvgIcon name='smile-icon' viewbox='0 0 22 22' />
                                                        </Button>
                                                    </div> */}
                                                    <div>

                                                        {/* <Button><SvgIcon name='link-icon' viewbox='0 0 15.258 15.245' /></Button> */}
                                                        <Button className="file_upload_op" name="pdfOrPpt" onClick={this.handleVisibility} ><SvgIcon name='new-pdf-icon' viewbox='0 0 25 25' /></Button>
                                                        <Button className="file_upload_op" name="imageOrVideo" onClick={this.handleVisibility} ><SvgIcon name='addphoto-icon' viewbox='0 0 22 22' /></Button>
                                                        <Button className="file_upload_op" name="imageOrVideo" onClick={this.handleVisibility} ><SvgIcon name='addvideo-icon' viewbox='0 0 24 21' /></Button>
                                                    </div>
                                                </div>

                                                <Button id="submit-post" className="ml-1 post-btn" type='submit' primary onClick={() => this.submitPost()} >Post</Button>
                                            </div>
                                        </Form>
                                    </Modal.Description>
                                </Modal.Content>
                            </Modal>
                        </MediaQuery>
                        <MediaQuery maxWidth={767}>
                            <Modal
                                dimmer='inverted'
                                className="addpost-mobile"
                                onClose={() => this.handleOpen(false)}
                                onOpen={() => this.handleOpen(true)}
                                open={this.state.popupOpen}
                                size='small'
                                trigger={<Button className="create-post-button" id="create-post-button"><SvgIcon name='photo-icon' viewbox='0 0 18 18' /> Create Post</Button>}
                            >
                                <Header className='modal-header'>
                                    <div className='header-left'>
                                        <SvgIcon onClick={() => this.handleOpen(false)} className='close-icon' name='close-alt' viewbox='0 0 15 15.001' /> Create a post
                                    </div>
                                    <div className='header-right'>
                                        <Dropdown floating icon={<SvgIcon className='headercamera-icon' name='camera-icon-alt' viewbox="0 0 16 14" />}>
                                            <DropdownMenu>
                                                <DropdownItem icon={<SvgIcon name='photo-icon' viewbox="0 0 18 18" />} text='Choose Photo or Video' onClick={this.triggerInputFile} />
                                                <DropdownItem icon={<SvgIcon name='new-pdf-icon' viewbox="0 0 25 25" />} text='Attach document' onClick={this.triggerInputFile} />
                                                <DropdownItem icon={<SvgIcon name='camera-icon-alt' viewbox="0 0 16 14" />} text='Take Photo or Video' onClick={this.triggerInputFile} />
                                            </DropdownMenu>
                                        </Dropdown>
                                        <div style={{ display: "none" }}>
                                            <input type='file' className='file-input' id="myfile" onChange={this.handleFileUpload} ref={ref => (this.fileInput = ref)} />
                                        </div>
                                        {/* add 'active' class for blue color */}
                                        <Button id="submit-post" className={"ml-1 post-btn " +  (this.state.text != "" || this.state.postMediaType != "" || this.state.postMediaUrl != "" ? "active" : "")} type='submit' onClick={() => this.submitPost()}>Post</Button>
                                    </div>
                                </Header>
                                <Modal.Content>
                                    <Form>
                                        <div className='modal-upper'>
                                            <Form.Field>
                                                <CustomRickEditor value={this.state.text} onEditorChange={this.handleEditorText} onRef={ref => (this.child = ref)} gettagged_products={this.popup_tagged_products} gettagged_company={this.popup_tagged_company} auth={this.props.auth} stripPastedStyles={true} toolbar={false} article={false} />
                                            </Form.Field>
                                            <p className='bottom-text'>
                                                Use <span>#</span> to tag products, and use <span>@</span> to tag companies
                                            </p>
                                            <div className='files-list onlytwo-item'>
                                                <div className='filelist-item'>
                                                    <button className='close-btn'><SvgIcon name='close-alt' viewbox="0 0 15 15.001" onClick={this.changeSelectedFile} /></button>
                                                    {/*  <img src={fileImg1} alt='' /> */}
                                                    <Previewer type={this.state.postMediaType} url={this.state.postMediaUrl} postStatus={this.state.postStatus} />
                                                </div>
                                                {/* <div className='uploading-row'>
                                                        Uploading...
                                                        <Progress size='tiny' percent={45} color='blue' />
                                                    </div> */}

                                                {/* <div>
                                                    <div className='filelist-item'>
                                                        <button className='close-btn'><SvgIcon name='close-alt' viewbox="0 0 15 15.001" /></button>
                                                        <img src={fileImg2} alt='' />
                                                    </div>
                                                </div> */}
                                                {/* <div>
                                                    <div className='filelist-item'>
                                                        <button className='close-btn'><SvgIcon name='close-alt' viewbox="0 0 15 15.001" /></button>
                                                        <img src={fileImg3} alt='' />
                                                    </div>
                                                </div> */}
                                            </div>
                                        </div>
                                    </Form>
                                </Modal.Content>
                            </Modal>
                        </MediaQuery>
                    </div>
                </div>
                {/* <div className="feeds-top-section ">
                    <div className="user-avatar">
                        {this.renderProfileImage()}
                    </div>
                    <div className="post-add">
                         {<Form>
                            <Modal
                                className="createa-supplier add-post-modal"
                                onClose={() => this.handleOpen(false)}
                                onOpen={() => this.handleOpen(true)}
                                open={this.state.popupOpen}
                                size="mini"
                                trigger={<TextArea rows="2" placeholder='Share article, news, updates about your product or company' readOnly={true} />}
                            >
                                <Modal.Content>
                                    <Modal.Description>
                                        <div className="mb-4"><b>Begin your post</b></div>
                                        <Form>
                                            <div className="d-flex mb-4 align-items-center">
                                                <div className="user-avtar-image mr-2">
                                                    {this.renderProfileImage()}

                                                </div>
                                                <div>
                                                    <div className="profile-name mb-1"> <Name auth={this.props.auth} /></div>

                                                </div>
                                            </div>
                                            <Form.Field>
                                                <CustomRickEditor value={this.state.text} onEditorChange={this.handleEditorText} onRef={ref => (this.child = ref)} gettagged_products={this.popup_tagged_products} gettagged_company={this.popup_tagged_company} auth={this.props.auth} toolbar={false} article={false} />
                                            </Form.Field>

                                            <Row className="pb-4 post-elements-row">
                                                <Col>
                                                    <a size='mini' basic className="text-black file_upload_op" name="imageOrVideo" onClick={this.handleVisibility}>
                                                        <SvgIcon name='photo-icon' viewbox='0 0 17 15.3' />
                                                        Add a photo
                                                    </a>
                                                </Col>
                                                <Col>
                                                    <a size='mini' basic className="mr-10 text-black file_upload_op" name="imageOrVideo" onClick={this.handleVisibility}>
                                                        <SvgIcon name='video-icon' viewbox='0 0 18 12' />
                                                        Add a video
                                                    </a>
                                                </Col>
                                                <Col>
                                                    <a size='mini' basic className="text-black file_upload_op" name="pdfOrPpt" onClick={this.handleVisibility} >
                                                        <SvgIcon name='document-icon' viewbox='0 0 10.286 13.813' />
                                                        Add a document
                                                    </a>
                                                </Col>
                                            </Row>
                                            <div className='fileupdated-view'>
                                                {this.state.fileReadCompleted &&
                                                    <Button icon="delete" className="remove-btn" circular size="tiny" floated='right' onClick={this.changeSelectedFile} />
                                                }

                                                {this.state.showFileUploadControl &&
                                                    <Transition.Group animation={'slide down'} duration={300} >
                                                        <div className="mT-5 mB-20">
                                                            <label>{this.state.label}</label>
                                                            <FileUploadControl handleFileUpload={this.handleFileUpload} />
                                                        </div>
                                                    </Transition.Group>
                                                }

                                                <div className='imgviewer-uploaded'>
                                                    <Previewer type={this.state.postMediaType} url={this.state.postMediaUrl} postStatus={this.state.postStatus} />
                                                </div>
                                            </div>
                                            <div className="text-right mt-5">
                                                <Button className="ml-1" type='submit' onClick={() => this.handleOpen(false)}>Cancel</Button>
                                                <Button className="ml-1" type='submit' primary onClick={() => this.submitPost()} >Post</Button> 
                                            </div>
                                        </Form>
                                    </Modal.Description>
                                </Modal.Content>
                            </Modal>
                        </Form>}  
                    </div>
                    <Button primary loading={this.props.postStatus === 'creating'}>Post</Button>
                </div> */}
            </Grid.Column>
        );
    }
}

function mapStateToProps({ auth, user }) {
    return {
        auth,
        userId: user.id,
        followedProducts: user && user.followedProducts,
        userProfileInfo: user.userInfoCollection.find(item => item.id === auth.id),
    };
}

export default connect(
    mapStateToProps,
    actions,
)(Popup_PostEditor);
