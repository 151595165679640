import { NEWS_LIST, SET_NEWS_PAGINATION, NEWS_PREFERED_COMPANY_LIST, NEWS_PREFERED_PRODUCT_LIST } from '../../config/types'

export default function (state = {
   newsPagination: {
      start: 1,
      size: 30
   },
}, action) {
   switch (action.type) {
      case NEWS_LIST:
         return { ...state, news_list_data: updateCollection(state, action) }
      case SET_NEWS_PAGINATION:
         return { ...state, newsPagination: action.payload }
      case NEWS_PREFERED_COMPANY_LIST:
         return { ...state, new_prefered_comp_list_data: action.payload }
      case NEWS_PREFERED_PRODUCT_LIST:
         return { ...state, new_prefered_prod_list_data: action.payload }
      default:
         return state;
   }
}



const updateCollection = (state, action) => {
   const newList = state.news_list_data ? [...state.news_list_data] : [];
   if (state.newsPagination.start === 0 || state.newsPagination.start === 1 || newList.length === 0) {
      return action.payload;
   }
   else {
      newList.push(...action.payload);
      return newList;
   }
}