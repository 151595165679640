import React from 'react'
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { Grid, Button, Dimmer, Loader, Message } from 'semantic-ui-react';
import { EditorState, ContentState, convertFromHTML, Modifier } from 'draft-js';
import { BASE_URL } from '../../../config/config';
import axios from 'axios';
// import { Editor } from 'react-draft-wysiwyg';
//import LayoutArticleEditor from '../../../common/layout/LayoutArticleEditor';
import LayoutArticleEditor from '../../common/layout/LayoutArticleEditor';
import './ArticlesEditor.scss';
import { SvgIcon } from '../../common';
import RichEditor from './RichEditor';
import { commonActions, networkFeedActions } from '../../../actions';
import { apiStatus, emailRegex } from '../../../common/constants';
// import { setSaveStatus } from '../../../actions/common/common';
import { replaceSpaceInName } from '../../../common/helperMethods';

class ArticlesEditor extends React.Component {
    constructor(props) {
        super(props)
        this.state = {
            show_loader: false,
            isFormValid: true,
            post: {
                "data_filled": false,
                "userId": this.props.auth.id,
                "type": 'article',
                "text": '',
                "title": '',
                'image': '',
                'filaname': '',
                'filevalue': '',
                "taggedProduct": [],
                "taggedCompanies": [],
                "visibility": "public",
                "entity_name": "",
            },
            /* editorState: EditorState.createWithContent(
                ContentState.createFromBlockArray(
                    convertFromHTML('<h1>6 crucial steps in semiconductor manufacturing</h1>' +
                        '<p>By now youll have heard word on the street: a new iPhone 13 is here. But its under the hood of this iPhone – and other digital devices – where things really get interesting. Thats where top-of-the-line chips like Apples A15 Bionic system-on-a-chip are making new, innovative technology possible. So how are these chips made and what are the most important steps?</p>')
                )
            ), */
        }

        this.useTaggedProduct = this.useTaggedProduct.bind(this);
        this.useTaggedCompany = this.useTaggedCompany.bind(this);
        this.assemblePost = this.assemblePost.bind(this);
        this.setArticle = this.setArticle.bind(this);

    }

    componentDidMount() {
        const { auth } = this.props;
        if (this.props.match.params.id !== undefined && auth) {
            this.getPostDetails()
        }
   
        if (this.props.match.params.feedType == 'company' || this.props.match.params.feedType == 'product') {
            let entityId = this.props.match.params.entityId
            axios.get(`${BASE_URL}/api/v1/${this.props.match.params.feedType}/${entityId}`, { withCredentials: true })
                .then(response => {
                    if (response.data.Item != undefined) {
                        this.setState({
                            post: {
                                ...this.state.post, entity_name: response.data.Item.name
                            }
                        });
                    }
                })
        }
        
         

    }

    componentWillUnmount() {
        this.props.reset()
    }

    componentDidUpdate(previousProps) {
        const { auth } = this.props;

        if (previousProps.postDetails == undefined || (this.state.post.data_filled == false && this.props.match.params.id !== undefined)) {
            this.setArticle();
        }
    }

    componentWillReceiveProps(newProps) {
        
        if (this.props.postStatus === 'created') {
            this.props.setPostStatus(undefined)
            setTimeout(() => {
                const { feedType, entityId } = this.props.match.params;
                if (feedType == 'company' || feedType == 'product'){
                    var entity_name = replaceSpaceInName(this.state.post.entity_name);
                    this.props.history.push(`/${feedType}/${entity_name}/${entityId}`)
                } else {
                    this.props.history.push(`/networkFeed/global`)
                    
                }
            }, 300);
        }
    }

    getPostDetails() {
        const { getPostDetails, auth } = this.props;
        if (auth) {
            this.setState({ show_loader: true })
            const { id } = this.props.match.params;
            getPostDetails(id);
        }
    }

    setArticle = () => {
        const { postDetails } = this.props;
        if (postDetails != undefined && postDetails.Item != undefined) {
            let article = postDetails.Item;
            let mediaType = ''; 
            let media_link = '';
            let media_name = '';
            if (article.media != undefined) {
                if ((Object.keys(article.media)).length > 0) {
                    mediaType = Object.keys(article.media)[0]
                    media_link = article.media[mediaType]['data'];
                    media_name = article.media[mediaType]['name'];
                    this.setState({ showFileUploadControl: false, fileReadCompleted: true, });
                } else {
                    this.setState({ showFileUploadControl: false, fileReadCompleted: false, });
                    this.previewPostMedia('', '');
                    this.setState({
                        filaname: '',
                        filevalue: ''
                    });
                }
            }

            var tagged_companies = (article.tagged_companies !== undefined && article.tagged_companies.length > 0) ? article.tagged_companies : [];
            var tagged_product = (article.tagged_product !== undefined && article.tagged_product.length > 0) ? article.tagged_product : [];

            this.setState({
                post: {
                    "data_filled": true,
                    "type": 'article',
                    "postMediaType": mediaType,
                    "postMediaUrl": media_link,
                    "filaname": media_name,
                    "title": article.title,
                    "text": article.text,
                    "userId": article.user.user_id,
                    "visibility": "public",
                    "taggedProduct": tagged_product,
                    "taggedCompanies": tagged_companies,
                    "remove_media": false,
                    "entity_name": this.state.post.entity_name,
                },
                "postMediaType": mediaType,
                "postMediaUrl": media_link,
                "filaname": media_name,
                "show_loader": false,
            })

        }
    }

    stripHtml = (html_string) => {
        if (html_string != undefined) {
            html_string = html_string.replace(/<[^>]*>?/gm, '');
        }
        return html_string;
    }

    onEditorStateChange = (editorState) => {
        this.setState({
            editorState,
        });
    };


    assemblePost = (name, value) => {
        const post = this.state.post;
        post[name] = value;
        this.setState({ post })
    }

    useTaggedProduct(value) {
        if (value.length > 0) {
            const post = this.state.post;
            let taggedProducts = [];
            for (let item of value) {
                taggedProducts.push(item.key);
            }
            post.taggedProduct = taggedProducts;
            //    this.state.post.taggedProduct = value;
        }
    }

    useTaggedCompany(value) {
        if (value.length > 0) {
            const post = this.state.post;
            let taggedCompanies = [];
            for (let item of value) {
                taggedCompanies.push(item.key);
            }
            post.taggedCompanies = taggedCompanies;

        }
    }

    previewPostMedia = (postMediaType, postMediaUrl) => {
        this.setState({
            postMediaType,
            postMediaUrl,
        })

    }

    getUnique(array) {
        var unique_arr = array.filter(function (item, pos) {
            return array.indexOf(item) == pos;
        });
        return unique_arr;
    }

    createArticle = () => {
        const { id, feedType, entityId } = this.props.match.params;
        const { auth, createNewPost, updateFeedPostDetail } = this.props;
        const { post } = this.state
        
        let post_text = (post.text != '') ? this.stripHtml(post.text) : ''
        if (post_text.trim() != '' && post.title.trim() != '') {

            this.setState({ isFormValid: true, show_loader: true })
            if (this.state.postMediaType == '') {
                this.assemblePost('image', '');
            }

            if (feedType === "product") {
                const post = this.state.post;
                const taggedProducts = this.state.post.taggedProduct.slice();

                taggedProducts.push(entityId);

                post.taggedProduct = this.getUnique(taggedProducts);

                this.setState({ post })
            }

            if (feedType === "company") {
                const post = this.state.post;
                const taggedCompanies = this.state.post.taggedCompanies.slice();

                taggedCompanies.push(entityId)
                post.taggedCompanies = this.getUnique(taggedCompanies);

                this.setState({ post })
            }


            if (id != undefined && id != null && id != '') {
                updateFeedPostDetail(this.state.post, feedType, id, auth);
            } else {

                createNewPost(this.state.post, feedType, auth.id, auth);
            }
            setTimeout(() => {
                this.setState({
                    post: {
                        "userId": this.props.auth.id,
                        "type": 'article',
                        "text": '',
                        "title": '',
                        'image': '',
                        'filaname': '',
                        'filevalue': '',
                        "taggedProduct": [],
                        "taggedCompanies": [],
                        "visibility": "public",
                        "entity_name": this.state.post.entity_name,
                    },
                    "show_loader": false
                })
            }, 500);


        } else {
            this.setState({ isFormValid: false })

        }
    }

    handleInputChange = (key, value) => {
        this.setState({
            post: { ...this.state.post, [key]: value }
        })
    }

    render() {
        const { auth, postStatus, postDetails } = this.props;
        return (
            <>
                {this.state.show_loader && <Dimmer active>
                    <Loader indeterminate>Please Wait.!</Loader>
                </Dimmer>}
                <LayoutArticleEditor {...this.props}>
                    <Grid.Column width={16}>
                        <div className='article-editor'>
                            <div className="heading-main">
                                <h2>Write article{/*  <span>Auto saved in Drafts</span> */}</h2>
                                <Button primary
                                    loading={this.props.common.saveStatus === apiStatus.IN_PROGRESS}
                                    onClick={this.createArticle}
                                >Publish article
                                </Button>
                            </div>
                            <div className='editor-card'>

                                <RichEditor
                                    onRef={ref => (this.child = ref)}
                                    editorState={this.state.editorState}
                                    onChange={this.onChange}
                                    /* handleBeforeInput={this._handleBeforeInput}
                                    handlePastedText={this._handlePastedText} */
                                    /* stripPastedStyles={true} */
                                    assemblePost={this.assemblePost}
                                    previewPostMedia={this.previewPostMedia}
                                    handleInputChange={this.handleInputChange}
                                    auth={auth}
                                    postStatus={postStatus}
                                    postDetails={this.state}
                                    feedType={'global'}
                                    tagged_products={this.useTaggedProduct}
                                    tagged_company={this.useTaggedCompany}
                                    history={this.props.history}
                                    id={this.props.match.params.id}
                                />


                                {!this.state.isFormValid ?
                                    <span>
                                        <div className="text-left mt-2 mb-2">
                                            <Message className="reduce-msg-padding" compact visible error content={`You have form errors, Title and Body both are required. `} />
                                        </div>
                                    </span> : <span></span>
                                }

                            </div>
                            <div className='action-btns pull-right mt-4'>
                                {/* <Button primary basic>Preview</Button> */}
                                {this.props.common.saveStatus === apiStatus.IN_PROGRESS ? (
                                    <Dimmer active>
                                        <Loader indeterminate>Saving</Loader>
                                    </Dimmer>

                                ) : ""}
                            </div>
                        </div>
                    </Grid.Column>
                </LayoutArticleEditor>
            </>
        )
    }
}

function mapStateToProps({ auth, common, networkFeed, state }) {
    return {
        auth: auth,
        state: state,
        common: common,
        postDetails: networkFeed.postDetails,
        postStatus: networkFeed.postStatus,
        uploadProgress: networkFeed.uploadProgress
    };
}

function mapDispatchToProps(dispatch) {
    return {
        reset: () => dispatch({ type: "RESET_ARTICLE" }),
        ...bindActionCreators({ ...commonActions, ...networkFeedActions }, dispatch)
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(ArticlesEditor);

