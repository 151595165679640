import React, { useEffect, useState } from 'react';
import './company_page.scss';
import CoverPic from "../../assets/images/cover-pic.jpg";
import NoCompanyImage from '../../assets/images/camera_icon.svg';
import { connect } from 'react-redux';
import { Link, Redirect } from "react-router-dom";
import { getCompanyInfo, updateCompanyDetails, uploadCompanyLogo } from '../../actions/company/company';
import EditCompany from './EditCompany';
import FollowButton from '../productpage_wip/FollowButton';
import { getFollowedCompanies } from '../../actions/user/user';
import { Button, List, Popup } from 'semantic-ui-react';
import { BottomPopup } from '../common/layout/navbar/BottomPopup';
import { showLoginModal, submitInquiry } from '../../actions/common/common';
import { SvgIcon } from '../common';
import InviteModalCompany from '../common/invite/inviteModalCompany';
import SharePost from '../networkFeed/SharePost';
import ShowMoreLess from '../networkFeed/showMoreLess';
import MappesDropdown from '../common/MappesDropdown';



let companyId = null;

function CompanyInfo(props) {
    const { company, id, auth, updateCompanyDetails, saveStatus, isCompanyUser, requestQuote, showLoginModal, history } = props;
    const [open, setOpen] = useState(false)
    const [route, setRoute] = useState('')
    const [userName, setUserName] = useState(auth && auth.displayName);
    const [sharePostPopup, setShareOpen] = useState(false)

    useEffect(() => {
        props.fetchCompanyData(id)
        if (auth) {
            props.fetchFollowedCompanies()
        }
        companyId = id;
    }, [id, auth])

    if (!company) {
        return 'Loading......'
    }


    const handleRequestQuote = (data) => {
        requestQuote({
            ...data,
            company_id: id
        })
    }


    const uploadLogo = (event) => {
        const file = event.target.files[0];

        if (file) {
            const reader = new FileReader();
            reader.readAsDataURL(file);
            props.uploadCompanyLogoNew(companyId, file);
        }
    };

    const UploadCompanyLogoClick = (event) => {
        document.querySelector('input#update-company--logo').click()
    }

    const handleInviteMore = () => {
        if (!auth) {
            showLoginModal(true);
        } else {
            history.push(`${id}/invite`);
        }
    }

    const handleWebClick = (url) => {
        var link = url;
        if (link.indexOf("http://") == -1 && link.indexOf("https://") == -1) {
            link = "http://" + link;
        }

        window.open(link, '_blank');
    }

    const handleRFQ = () => {
        history.push(`/request-quote/company/` + company.id);
    }

    const toggleshareCompanyPopup = (e) => {
        if (!auth) {
            showLoginModal(true);
        } else {
            setShareOpen(!sharePostPopup)
        }
        if (e !== undefined) {
            e.stopPropagation();
        }
    }

    const hideShareCompany = () => {
        setShareOpen(false);
    }


    const { description, name, logo, followingUsers, url, country } = company;
    const followers = followingUsers ? Object.keys(followingUsers).length : 0;


    if (route === 'invite') {
        return <Redirect to={`${id}/${route}`} />
    }
    return (
        <div className="product-upper">
            <div className="company-coverpic">
                <img alt={name} title={name} src={CoverPic} />
            </div>
            <div className="product-upper-inner">
                <div className="productupper-left text-center">
                    <div className="productimage-main companyImage">
                        {logo
                            ? <img alt={name} title={name} src={logo} />
                            : <div className='nocompany-image'>
                                <img alt={name} title={name} src={NoCompanyImage} />
                                <p>Upload Image</p>
                            </div>
                        }
                        <div className='uploadphoto-button'>
                            <Button id="company-image-upload" className="" basic primary size="mini" onClick={UploadCompanyLogoClick}>
                                <SvgIcon name='camera-icon' viewbox='0 0 20.822 17.4' />
                            </Button>
                        </div>
                    </div>
                    <span>
                        <div className="d-none">
                            <input id="update-company--logo" type="file" onChange={uploadLogo} />
                        </div>
                    </span>
                </div>
                <div className="productupper-right">
                    <div className="namerow">
                        <div>
                            <h1 alt={name} title={name}>{name}</h1>
                            <ul className="connection-subhead">
                                <li>
                                    <Link id="company-followers" to={`/company/${name}/${id}/followers`}> <span>{followers}</span> Followers
                                    </Link>
                                </li>


                                {(country != undefined && country != null) ? <li> {country}</li> : ''}
                                {
                                    (url != undefined && url != null) ?
                                        <li className="followuser">
                                            <Link id="company-officialSite" onClick={() => handleWebClick(url)} to="#" rel='nofollow'>
                                                {/* {url} */}
                                                View official site
                                            </Link>
                                        </li> : ''
                                }

                                {/*   <li className="followuser">
                                    <Link onClick={() => handleWebClick(url)} to="#">
                                        <SvgIcon name="share" viewbox='-21 0 512 512' />   Share Company
                                    </Link>
                                </li> : '' */}
                            </ul>
                            <div className='d-flex align-items-center'>
                                {/* {!isCompanyUser && <div className='mr-2'><FollowButton id={id} name={name} type="company" /></div>} */}
                                {/* <Button className="invitemore-btn" basic primary size="mini" onClick={handleInviteMore}><SvgIcon name="invitemore-icon" viewbox="0 0 15.059 16.6" /> Invite More</Button> */}
                                {/* <InviteModalCompany /> */}
                            </div>
                            {/* <Popup
                                basic
                                trigger={<span><Button className="invitemore-btn" basic primary size="mini" onClick={()=> toggleshareCompanyPopup()}><SvgIcon  name="share" viewbox='-21 0 512 512' />   Share Company </Button> </span> }
                                on="click"
                                flowing
                                className="sharepost-popup-wrapper"
                                open={sharePostPopup}
                                hideOnScroll={true}
                                position='top right'
                                content={
                                    <SharePost
                                        type='company'
                                        postId={id}
                                        postTitle={name}
                                        closePopup={()=> toggleshareCompanyPopup()}
                                        hideShareCompany={()=> hideShareCompany()}
                                        userName={userName} />
                                }
                            /> */}

                        </div>
                        <div className='d-flex align-items-start'>
                            <MappesDropdown width={190} overlayClass='product-topright-action' buttonLabel={<div className='menu-icon m-0'><SvgIcon name="ellipsis-vertical" viewbox='0 0 10.125 38.875' /></div>}>
                                <List>
                                    <InviteModalCompany type='company' entityId={id} auth={auth} showLoginModal={showLoginModal} />
                                     
                                   <Popup
                                        basic
                                        trigger={<List.Item onClick={() => toggleshareCompanyPopup()}><SvgIcon name="share" viewbox='-21 0 512 512' />Share Company</List.Item>}
                                        on="click"
                                        flowing
                                        className="sharepost-popup-wrapper"
                                        open={sharePostPopup}
                                        hideOnScroll={true}
                                        position='top right'
                                        content={
                                            <SharePost
                                                type='company'
                                                postId={id}
                                                postTitle={name}
                                                closePopup={() => toggleshareCompanyPopup()}
                                                hideShareCompany={() => hideShareCompany()}
                                                userName={userName} />
                                        }
                                    /> 
                                    {/* isCompanyUser && */ auth && <EditCompany company={company} updateCompanyDetails={updateCompanyDetails} saveStatus={saveStatus} />}
                                </List>
                            </MappesDropdown>

                        </div>
                        {/*  <div>
                            {!isCompanyUser && <FollowButton id={id} name={name} type="company" />}
                        </div> */}
                    </div>
                    <div className="actionbtns d-flex justify-content-start">
                        <div><FollowButton id={id} name={name} type="company" /></div>
                        <Button id="product-request-quote" primary size="small" onClick={() => handleRFQ()}>Request Quote</Button> {/* onClick={() => this.setOpen(true)} */}
                    </div>
                    {/*  <div className="detail-text">
                        <p>{description}</p>
                        {auth && <EditCompany company={company} updateCompanyDetails={updateCompanyDetails} saveStatus={saveStatus} />}


                    </div>
                    <div className="text-right mt-auto mb-2 details-view">
                        {false && !isCompanyUser && <Button primary basic size="small" onClick={() => setOpen(true)}>Request Quote</Button>}
                    </div> */}
                </div>
            </div>
            <div className='productupper-dtl'>
                <div className="detail-text">
                    <p>
                        <ShowMoreLess content={description}  ></ShowMoreLess>
                    </p>
                </div>
                <div className="text-right mt-auto mb-2 details-view">
                    {/*  { false && !isCompanyUser &&  <Button primary basic size="small" onClick={() => setOpen(true)}>Request Quote</Button>} */}
                    {false && !isCompanyUser && <Button primary basic size="small" onClick={() => handleRFQ()}>Request Quote</Button>}
                </div>
            </div>
            {false && <BottomPopup open={open} setOpen={setOpen} userDetails={auth} handleRequestQuote={handleRequestQuote} />}
        </div>
    )
}

function mapDispatchToProps(dispatch) {
    return {
        uploadCompanyLogoNew: (companyId, file) => dispatch(uploadCompanyLogo(companyId, file)),

        fetchCompanyData: (companyId) => dispatch(getCompanyInfo(companyId)),
        fetchFollowedCompanies: () => dispatch(getFollowedCompanies()),
        updateCompanyDetails: (id, data) => dispatch(updateCompanyDetails(id, data)),
        requestQuote: (data) => dispatch(submitInquiry(data)),
        showLoginModal: () => dispatch(showLoginModal(true))
    };
}

function mapStateToProps({ singleCompany, auth, common }) {
    let companyData;
    if (singleCompany.info) {
        companyData = singleCompany.info
    }

    if (singleCompany.info != undefined) {
        if (singleCompany.info.Item) {
            companyData = singleCompany.info.Item;
        }
    }

    return {
        company: companyData,
        auth,
        saveStatus: common.saveStatus
    }
}
export default connect(mapStateToProps, mapDispatchToProps)(CompanyInfo)