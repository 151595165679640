import React, { Component } from 'react';
import * as ReactDOM from 'react-dom';
import { Icon, Dropdown, TextArea, Input, Button, Form } from 'semantic-ui-react';
import * as actions from '../../actions';
import './messagenew.scss';
import { connect } from 'react-redux';
import axios from 'axios';
import { BASE_URL } from '../../config/config';
import ChatRooms from './ChatRooms';
import Conversation from './Conversation';
import LayoutMessage from '../common/layout/LayoutMessage';
import SEO from '../seo/SEO';


const timeout = 1000;

class MessageLayout extends Component {
    constructor(props) {
        super(props);
        this.state = {
            animation: 'fade down',
            chatMessage: '',
            messages: [],
            duration: 700,
            refresh: false,
            ws: null,
            disableSendButton: true,
            currentChatRoom: {}
        };
    }

    componentDidMount() {
        this.connect();
        this.props.fetchUser();
        if (this.state.currentChat) {
            this.getMessages(this.state.currentChat);
        }
    }

    /**
     * @function connect
     * This function establishes the connect with the websocket and also ensures constant reconnection if connection closes
     */
    connect = () => {
        let baseUrlParts = BASE_URL.split('//', 2);
        var ws = new WebSocket(`${baseUrlParts[0].startsWith('https') ? 'wss' : 'ws'}://${baseUrlParts[1]}/ws`);
        //var ws = new WebSocket('wss://devapi.mappes.io/ws');

        let that = this; // cache the this
        var connectInterval;

        // websocket onopen event listener
        ws.onopen = () => {
            console.log('connected websocket main component');

            this.setState({ ws: ws });

            that.timeout = 250; // reset timer to 250 on open of websocket connection
            clearTimeout(connectInterval); // clear Interval on on open of websocket connection
        };

        // websocket onclose event listener
        ws.onclose = e => {
            console.log(
                `Socket is closed. Reconnect will be attempted in ${Math.min(
                    10000 / 1000,
                    (timeout + timeout) / 1000,
                )} second.`,
                e.reason,
            );

            //that.timeout = that.timeout + that.timeout; //increment retry interval
            connectInterval = setTimeout(this.check, Math.min(10000, timeout)); //call check function after timeout
        };

        // websocket onerror event listener
        ws.onerror = err => {
            console.error('Socket encountered error: ', err.message, 'Closing socket');
            ws.close();
        };

        ws.onmessage = event => {
            console.log('websocket message', event.data);
            let chat = JSON.parse(event.data);
            if (chat.sender == this.state.currentChat) {
                this.setState({ messages: [chat, ...this.state.messages] });
                axios.put(
                    `${BASE_URL}/api/v1/chats/${this.state.currentChat}/`,
                    { lastRead: chat.added },
                    { withCredentials: true },
                );
            }
        };
    };

    /**
     * utilited by the @function connect to check if the connection is close, if so attempts to reconnect
     */
    check = () => {
        const { ws } = this.state;
        if (!ws || ws.readyState == WebSocket.CLOSED) this.connect(); //check if websocket instance is closed, if so call `connect` function.
    };

    onChangeChatMessage = (e) => {
        const chatMessage = e.target.value;
        this.setState({ chatMessage, disableSendButton: chatMessage.trim() === "" });
    }

    sendMessageToChatRoom = async () => {
        this.setState({ sendButtonLoader: true, disableSendButton: true })
        let res = await axios.post(
            `${BASE_URL}/api/v1/chats/${this.state.currentChat}/`,
            { message: this.state.chatMessage },
            { withCredentials: true },
        );
        this.setState({ chatMessage: '', sendButtonLoader: false });
        if (res.data) {
            this.setState({ messages: [res.data, ...this.state.messages] });
        }
        this.scrollToBottom();
    };

    scrollToBottom = () => {
        const { messageList } = this.refs;
        if (messageList) {
            const scrollHeight = messageList.scrollHeight;
            const height = messageList.clientHeight;
            const maxScrollTop = scrollHeight - height;
            // ReactDOM.findDOMNode(messageList).scrollTop = maxScrollTop > 0 ? maxScrollTop : 0;
            messageList.current.scrollTop = maxScrollTop > 0 ? maxScrollTop : 0;
        }
    };

    getMessages = async userId => {
        let res = await axios.get(`${BASE_URL}/api/v1/chats/${userId}/`, { withCredentials: true });
        if (res.data && res.data.Items && res.data.Items.length > 0) {
            this.setState({ messages: res.data.Items, LastEvaluatedKey: res.data.LastEvaluatedKey });
            if (!this.state.currentChatRoom.lastRead || res.data.Items[0].added > this.state.currentChatRoom.lastRead) {
                axios.put(
                    `${BASE_URL}/api/v1/chats/${this.state.currentChat}/`,
                    { lastRead: res.data.Items[0].added },
                    { withCredentials: true },
                );
            }
        } else {
            this.setState({ messages: [], LastEvaluatedKey: null });
        }

        this.scrollToBottom();
    };

    localeCompare(str1, str2) {
        if (!str1) {
            return '0'.localeCompare(str2);
        }
        return str1.localeCompare(str2);
    }

    showConversation = (room) => {
        this.setState({ open: true, currentChat: room[0], currentChatRoom: room[1] });
    }

    render() {
        let chatListToogle = ['chat-listbar'];
        if (this.state.addClass) {
            chatListToogle.push('hideChatlist');
        }
        return (
              <>
                <SEO robots="noindex, nofollow"
                    canonicalUrl={`/message`}
                />
            <LayoutMessage {...this.props}>
                <div className="chat-wrapper">
                    <div className={chatListToogle.join(' ')}>
                        <div className="chat-list-left">
                            <div className="chat-list-heads">Messaging</div>
                            <div className="search-chat-user">
                                <Input iconPosition="left" icon placeholder="Search messages">
                                    <Icon name="search" />
                                    <input />
                                </Input>
                            </div>
                            <ChatRooms location={this.props.location} showConversation={this.showConversation} />
                        </div>
                    </div>
                    {
                        this.state.currentChatRoom.name &&
                        <div className="chatbot-right">
                            <div className="chatheader-user">
                                <div className="chatheader-left">
                                    <div className="chat-backbutton" >
                                        <i className="icon-chevron-left"></i> Back
                                    </div>
                                    <h4>{this.state.currentChatRoom.name}</h4>
                                </div>
                                {/* <Dropdown
                                    floated="right"
                                    direction="right"
                                    trigger={<Icon className="color-light-blue" name={'ellipsis horizontal'} />}>
                                    <Dropdown.Menu direction="right" style={{ borderRadius: '10px' }}>
                                        <Dropdown.Item className="dropDownThreeDots" text="Delete" />
                                        <Dropdown.Item className="dropDownThreeDots" text="Block" />
                                    </Dropdown.Menu>
                                </Dropdown> */}
                            </div>
                            <div className="chats-wrapper">
                                <div className="msg-list">
                                    <div className="conversation">
                                        <Conversation currentChat={this.state.currentChat} currentChatRoom={this.state.currentChatRoom} messages={this.state.messages} getMessages={this.getMessages} />
                                    </div>
                                </div>
                                <div className="chatbottom-wrapper">
                                    <Form className="text-area">
                                        <div className="chat-input">
                                            <TextArea
                                                onChange={this.onChangeChatMessage}
                                                value={this.state.chatMessage}
                                                rows={2}
                                                placeholder="Type your message"
                                            />
                                        </div>
                                        <div className="chat-control p-3">

                                            <Button onClick={this.sendMessageToChatRoom} primary size="mini" loading={this.state.sendButtonLoader} disabled={this.state.disableSendButton}>
                                                Send
                                            </Button>
                                        </div>
                                    </Form>
                                </div>
                            </div>
                        </div>
                    }
                </div>
            </LayoutMessage>
            </>
        );
    }
}

function mapStateToProps({ auth, user, chatUser }) {
    return { auth, user, chatUser };
}

export default connect(mapStateToProps, actions)(MessageLayout);
