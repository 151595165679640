import React, { useEffect } from 'react';
import { Container, Grid, Tab, Loader } from 'semantic-ui-react';
import Navbar from '../common/layout/navbar/Navbar';
import LeftSidebar from '../common/LeftSidebar';
import RightSection from '../common/RightSection';
import './company_page.scss';
import ProductsTab from './products-tab';
import PeopleTabView from './people-tab';
import CompanyInfo from './CompanyInfo';
import InviteView from '../common/invite/invite';
import { connect } from 'react-redux';
import { getCompanyProducts, getEmployees, getCompanyFollowers, addProducts } from '../../actions/company/company';
import NetworkFeedLayout from '../networkFeed/NetworkFeedLayout';
import { getCompanyFeedUnauthenticated } from '../../actions/networkFeed/networkFeed';
import Followers from '../common/followers/Followers';
import { replaceSpaceInName } from '../../common/helperMethods';
import axios from 'axios';
import { BASE_URL } from '../../config/config';
import SEO from '../seo/SEO';
import {
    Route,
    NavLink,
    Switch,
    Link,
    withRouter
} from "react-router-dom";
import MediaQuery from 'react-responsive'

const CompanyPage = (props) => {
    const { view, id, name } = props.match.params;
    const { company, fetchCompanyProducts, getEmployees, getCompanyFollowers, products, employees, auth, history, getCompanyFeedUnauthenticated, addProducts } = props;
    const isCompanyUser = auth.company_id === id;
    const getPanes = () => [
        {
            menuItem: { as: NavLink, content: 'Activity Feed', id: "company-activityFeed-tab", to: `/company/${name}/${id}`, exact: true, key: "feed" },
            pane: (
                <Route
                    path={`/company/${name}/${id}`}
                    exact
                    render={() => (
                        <Tab.Pane attached={false}>
                            <NetworkFeedLayout entityId={id} feedType="company" history={history} />
                        </Tab.Pane>
                    )}
                />
            )
        },
        {
            menuItem: { as: NavLink, content: `Products/Services (${products ? products.Count : 0})`, id: "company-products-tab", to: `/company/${name}/${id}/products`, exact: true, key: "products" },
            pane: (
                <Route
                    path={`/company/${name}/${id}/products`}
                    exact
                    render={() => (
                        <Tab.Pane attached={false}>
                            <ProductsTab
                                id={id}
                                company={company}
                                products={products}
                                isCompanyUser={isCompanyUser}
                                addProducts={addProducts}
                                auth={auth}
                            />
                        </Tab.Pane>
                    )}
                />
            )
        },
        {
            menuItem: { as: NavLink, content: `People(${employees ? employees.Count : 0})`, id: "company-people-tab", to: `/company/${name}/${id}/people`, exact: true, key: "people" },
            pane: (
                <Route
                    path={`/company/${name}/${id}/people`}
                    exact
                    render={() => (
                        <Tab.Pane attached={false}>
                            <PeopleTabView
                                companyId={id}
                                employees={employees}
                                auth={auth}
                            />
                        </Tab.Pane>
                    )}
                />
            )
        },
        // {
        //     menuItem: 'Visualise Network',
        //     render: () => <Tab.Pane attached={false}>Visualise Network</Tab.Pane>,
        // },
    ]

    useEffect(() => {
        if (!name) {
            axios.get(`${BASE_URL}/api/v1/company/${id}`, { withCredentials: true }).then((res) => {
                if (res.data != undefined) {
                    if (res.data.mergedCompanyId != undefined) {
                        history.push(`/company/${replaceSpaceInName(res.data.mergedCompanyName)}/${res.data.mergedCompanyId}`);
                    } else {
                        if (res.data.Item != undefined) {
                            const companyName = res.data.Item.name;
                            history.push(`/company/${replaceSpaceInName(companyName)}/${id}`);
                        } else if (res.data.name != undefined) {
                            const companyName = res.data.name;
                            history.push(`/company/${replaceSpaceInName(companyName)}/${id}`);
                        }

                    }
                }
            })
        } else {
            axios.get(`${BASE_URL}/api/v1/company/${id}`, { withCredentials: true }).then((res) => {
                if (res.data != undefined) {
                    if (res.data.mergedCompanyId != undefined) {
                        history.push(`/company/${replaceSpaceInName(res.data.mergedCompanyName)}/${res.data.mergedCompanyId}`);
                    }
                }
            })
        }
    }, [])

    useEffect(() => {
        if (!name) return;
        fetchCompanyProducts(id);
        getEmployees(id);
        getCompanyFeedUnauthenticated(id);
        getCompanyFollowers(id);
    }, [id])

    const getView = () => {
        const panes = getPanes();

        switch (view) {
            case "followers":
                return <span>
                    <div>
                        <Link to={`/company/${replaceSpaceInName(name)}/${id}`} >
                            <div className="col-sm-12 back-link cursor-pointer">
                                <i className="chevron left icon"></i> Back to {name}
                            </div>
                        </Link>
                    </div>
                    <Followers followers={props.followers} auth={props.auth} />
                </span>
            case "invite":
                return <span>
                    <div>
                        <Link to={`/company/${replaceSpaceInName(name)}/${id}`} >
                            <div className="col-sm-12 back-link cursor-pointer">
                                <i className="chevron left icon"></i> Back to {name}
                            </div>
                        </Link>
                    </div>
                    <InviteView type="company" id={id} />
                </span>

            default:
                return <span>
                    <CompanyInfo id={id} isCompanyUser={isCompanyUser} history={history} {...props} />
                    <div className="product-bottom">
                        <div className="product-bottom-inner">
                            <Switch>
                                <Tab className="product-tabes"
                                    menu={{ secondary: true, pointing: true }}
                                    panes={panes}
                                    renderActiveOnly={false}
                                    activeIndex={-1}
                                />
                            </Switch>
                        </div>
                    </div>
                </span>
        }
    }

    return (
        <Navbar {...props}>
            <div className="primary-background">
                <Container className="body-background palm-nudge-sides">
                    {company ? (<SEO robots="index, follow"
                        canonicalUrl={`/company/${replaceSpaceInName(name)}/${id}`}
                        alternateLink={`/company/${replaceSpaceInName(name)}/${id}`}
                        pageType={'company'}
                        pageContent={company}
                    />) : (
                        <SEO robots="index, follow" pageType={'company'}
                            canonicalUrl={`/company/${replaceSpaceInName(name)}/${id}`}
                            alternateLink={`/company/${replaceSpaceInName(name)}/${id}`}
                        />
                    )}

                    <Grid padded stackable>
                        <MediaQuery minWidth={768}>
                            <Grid.Column className="left-content">
                                <LeftSidebar />
                            </Grid.Column>
                        </MediaQuery>
                        <Grid.Column className="main-content-area">
                            <div className="productpage-wip">
                                {name ? getView() : <Loader active />}
                            </div>
                        </Grid.Column>
                        <RightSection entityType="company" entityId={id} />
                    </Grid>
                </Container >
            </div>
        </Navbar>
    )
}

function mapDispatchToProps(dispatch) {
    return {
        fetchCompanyProducts: (companyId) => dispatch(getCompanyProducts(companyId)),
        getEmployees: (companyId) => dispatch(getEmployees(companyId)),
        getCompanyFollowers: (companyId) => dispatch(getCompanyFollowers(companyId)),
        getCompanyFeedUnauthenticated: (companyId) => dispatch(getCompanyFeedUnauthenticated(companyId)),
        addProducts: (companyId, companyName, products) => dispatch(addProducts(companyId, companyName, products))
    };
}

function mapStateToProps({ singleCompany, auth }) {
    let companyData;
    if (singleCompany.info) {
        companyData = singleCompany.info
    }

    if (singleCompany.info != undefined) {
        if (singleCompany.info.Item) {
            companyData = singleCompany.info.Item;
        }
    }

    return {
        // Change this one to add null check
        company: companyData,
        products: singleCompany.products,
        employees: singleCompany.employees,
        followers: singleCompany.followers,
        auth,
    }
}
export default connect(mapStateToProps, mapDispatchToProps)(withRouter(CompanyPage))
